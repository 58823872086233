import React, { Component, Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { DeleteWorkByUSER_ID, ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { REFRESHTYPE, WORKNAME, WORKPOLICY } from "../../utility/work";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Label from "../../common/Label";
import { GoNoEntry } from "react-icons/go";

import "./MobileMaincontainer.css";
import MobileStoreInfo from "../../components/MobileStoreInfo";
import Swipe from "../../common/Swipe";
import SlickSliderComponent from "../../common/Swipe";
import { useSleep } from "../../utility/common";
import { FILTERNAME } from "../../utility/fitler";

import { FiTerminal } from "react-icons/fi";
import MobileServiceFilter from "../../modal/MobileServiceFilterPopup/MobileServiceFilter";
import MobilePriceFilter from "../../modal/MobilePriceFilterPopup/MobilePriceFilter";
import MobilePeriodFilter from "../../modal/MobilePeriodFilterPopup/MobilePeriodFilter";
import MobileDistanceFilter from "../../modal/MobileDistanceFilterPopup/MobileDistanceFilter";
import MobileProcessFilter from "../../modal/MobileProcessFilterPopup/MobileProcessFilter";
import ResultLabel from "../../common/ResultLabel";
import LottieAnimation from "../../common/LottieAnimation";
import Empty from "../../components/Empty";
import MobileSuccessPopup from "../../modal/MobileSuccessPopup/MobileSuccessPopup";
import { RESET } from "../../store/menu/MenuSlice";
import { ReadRoom } from "../../service/RoomService";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import LazyImage from "../../common/LasyImage";
import { GoPlus } from "react-icons/go";

import MobileSeekBanner from "../../components/MobileSeekBanner";
import MobileRegistBanner from "../../components/MobileRegistBanner";
import ButtonEx from "../../common/ButtonEx";
import { ROOMPOLICY, ROOMSIZE } from "../../utility/room";

const Container = styled.div`
  padding:50px 0px 0px 0px;
  width: ${({width}) => width}px;
  margin : 0 auto;
  height: calc(100vh - 50px);
  scrollbar-width: none; // 스크롤바 안보이게 하기
  overflow-x: hidden; /* X축 스크롤을 숨깁니다. */


`
const SubContainer = styled.div`
  margin: 0 auto;
  background: #f3f3f3;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;



`


const style = {
  display: "flex"
};


const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  width: 22%;
  border-radius: 15px;
  margin-bottom:20px;



`


const BoxImg = styled.div`
  background: ${({bgcolor}) => bgcolor};
  border-radius: 35px;
  border: ${({clickstatus}) => clickstatus == true ? ('1px solid #ff7e19') :('') };
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.01), 
  0 2px 4px rgba(0,0,0,0.01), 
  0 4px 8px rgba(0,0,0,0.01), 
  0 8px 16px rgba(0,0,0,0.01),
  0 16px 32px rgba(0,0,0,0.01), 
  0 32px 64px rgba(0,0,0,0.01);
  height:35px;
`


const FilterBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: ${({clickstatus}) => clickstatus == true ? ('#FF7125') :('#fff') };
  border:  ${({clickstatus}) => clickstatus == true ? (null) :('1px solid #C3C3C3') };
  margin-right: 3px;
  border-radius: 4px;
  padding: 0px 15px;
  height:30px;
  flex: 0 0 auto; /* 아이템의 기본 크기 유지 */

`
const FilterBoxText = styled.div`
color: ${({clickstatus}) => clickstatus == true ? ('#FFF') :('#131313') };
font-size:14px;
margin-left:5px;
font-weight:600;

`

const StickyPos = styled.div`
  position: sticky;
  top:0px;

`

const Bannerstyle={
  width: '100%',
  borderRadius: '10px',
  margin: '20px 0px',
}

const Inputstyle ={

  background: '#FFF',
  width: '75%',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 20px 0px 20px',
  height : '40px',
  border : "1px solid #FF7125",
  position :"absolute"

}
const Searchstyle={
  position: "absolute",
  left: '15px'
}

const InputLine = styled.div`
  width: 95%;
  background: rgb(249, 249, 249);
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`
const SearchElementStyle ={

  height: '80px',
  background: "white",
  width: '100%', 
  marginBottom: '10px',
}
const RegistButton = styled.div`
  height: 45px;
  width: 45%;
  border-radius: 100px;
  background: #FF7e19;
  color: #fff;
  margin: 20px auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family : Pretendard-SemiBold;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`




/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


// const WorkItems=[
//   {name : WORKNAME.HOMECLEAN, img:imageDB.house, img2:imageDB.housegray},
//   {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business, img2:imageDB.businessgray},
//   {name :WORKNAME.MOVECLEAN, img:imageDB.move, img2:imageDB.movegray},
//   {name :WORKNAME.FOODPREPARE, img:imageDB.cook, img2:imageDB.cookgray},
//   {name :WORKNAME.ERRAND, img:imageDB.help, img2:imageDB.helpgray},
//   {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool, img2:imageDB.gooutschoolgray},
//   {name :WORKNAME.BABYCARE, img:imageDB.babycare, img2:imageDB.babycaregray},
//   {name :WORKNAME.LESSON, img:imageDB.lesson, img2:imageDB.lessongray},
//   {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare, img2:imageDB.patientcaregray},
//   {name :WORKNAME.CARRYLOAD, img:imageDB.carry, img2:imageDB.carrygray},
//   {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital, img2:imageDB.hospitalgray},
//   {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe, img2:imageDB.recipegray},
//   {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent, img2:imageDB.schooleventgray},
//   {name :WORKNAME.SHOPPING, img:imageDB.shopping, img2:imageDB.shoppinggray},
//   {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital, img2:imageDB.doghospitalgray},
//   {name :WORKNAME.GODOGWALK, img:imageDB.dog, img2:imageDB.doggray},
// ]

const WorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house, bgcolor:"#fff1c6"},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business, bgcolor:"#fff1c6"},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move, bgcolor:"#fff1c6"},


  {name :WORKNAME.ERRAND, img:imageDB.help, bgcolor:"#c6e2ff"},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry, bgcolor:"#c6e2ff"},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe, bgcolor:"#c6e2ff"},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook, bgcolor:"#c6e2ff"},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping, bgcolor:"#c6e2ff"},

  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool, bgcolor:"#d5dee8"},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare, bgcolor:"#d5dee8"},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent, bgcolor:"#d5dee8"},
  {name :WORKNAME.LESSON, img:imageDB.lesson, bgcolor:"#d5dee8"},

  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare, bgcolor:"#efc3c3"},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital, bgcolor:"#efc3c3"},



  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital, bgcolor:"#f9f9f9"},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog, bgcolor:"#f9f9f9"},
]

const FilterItems=[
  {name : FILTERNAME.INIT, img:imageDB.house, img2:imageDB.house},
  {name : FILTERNAME.SERVICE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.PRICE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.DISTNACE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.PROCESS, img:imageDB.house, img2:imageDB.house},
]

const BannerItems =[
  {bgcolor :"#2c8ed5", image:imageDB.logo2, maintext:"급하게 처리해야 하는 집안일", subtext1:"3단계 10초만에 일감요청", subtext2:"", buttontype: 1},
  {bgcolor :"#519c90", image:imageDB.board, maintext:"모든 거래는 계약서로", subtext1:"공식화된 계약서에", subtext2:"정식서명으로 계약 완성", buttontype: 2},
  {bgcolor :"#ec8260", image:imageDB.ai, maintext:"인공지능이 탑재된 홍여사", subtext1:"집안일에 필요한 모든것", subtext2:"홍여사 AI가 알려드립니다", buttontype: 3},
  {bgcolor :"#4181a1", image:imageDB.convenience, maintext:"홍여사 세상으로 접속", subtext1:"일상생활을 공유", subtext2:"관광지도 캠핑장정보 의약품정보", buttontype: 4},
  {bgcolor :"#78be21", image:imageDB.event, maintext:"이벤트에 참여해서", subtext1:"포인트를 차곡차곡 적립", buttontype: 5},
  {bgcolor :"#2c8ed5", image:imageDB.roomsize1,maintext:"공간대여의 핵심",subtext1:"에어비앤비 처럼 공간을 공유", buttontype:6},

]

const Lineup = styled.div`
  border-top-left-radius: 30px;
  z-index: 10;
  margin-top: -40px;
  background: #fff;
  border-top-right-radius: 30px;

`

const Guide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Pretendard-SemiBold;
  width: 100%;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 10px;
  font-size:18px;

`

const GuideView = styled.div`
  display: flex;
  width:90%;
  margin : 20px auto;
  flex-direction:column;


`
const GuideSub = styled.div`

  line-height: 1.5;
  font-size: 16px;
  letter-spacing: -0.32px;
`


/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const MobileMaincontainer =({containerStyle}) =>  {

  const reduxdispatch = useDispatch();
  const {value} = useSelector((state)=> state.menu);
  const { dispatch, user } = useContext(UserContext);


  
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();


  const [workitems, setWorkitems] = useState([]);
  const [displayitems, setDisplayitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [menu, setMenu] = useState('');

  const [showNewDiv, setShowNewDiv] = useState(true);

  const [search, setSearch] = useState('');
  const recordRef = useRef(null);
  const elementRef = useRef(null);
  const [width, setWidth] = useState(0);

  const [totalset, setTotalset] = useState(0);

  const [servicepopup, setServicepopup] = useState(false);
  const [pricepopup, setPricepoupup] = useState(false);
  const [periodpopup, setPeriodpopup] = useState(false);
  const [distancepopup, setDistancepopup] = useState(false);
  const [processpopup, setProcesspopup] = useState(false);

  const [servicefilter, setServicefilter] = useState([]);
  const [pricefilter, setPricefilter] = useState([]);
  const [periodfilter, setPeriodfilter] = useState([]);
  const [distancefilter, setDistancefilter] = useState([]);
  const [processfilter, setProcessfilter] = useState([]);

  const [refresh, setRefresh] = useState(1);
  const [init, setInit] = useState(false);
  const inputRef = useRef(null);



  useLayoutEffect(() => {
    setWidth(elementRef.current.offsetWidth);
    setRefresh((refresh) => refresh +1);

  }, []);



  useEffect(() => {
    if (document.activeElement.tagName === 'INPUT') {
      setTimeout(() => {
          document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300); // 키보드가 완전히 나타날 때까지 대기
    }

  }, []);


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   return () => {};
  // }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setShowNewDiv(showNewDiv);
    setMenu(menu);
    setTotalset(totalset);
    setDisplayitems(displayitems);

    setServicefilter(servicefilter);
    setPricefilter(pricefilter);
    setPeriodfilter(periodfilter);
    setDistancefilter(distancefilter);
    setProcessfilter(processfilter);

    setServicepopup(servicepopup);
    setPricepoupup(pricepopup);
    setPeriodpopup(periodpopup);
    setDistancepopup(distancepopup);
    setProcesspopup(processpopup);
    setInit(init);

  },[refresh])




  /**
   * 팝업 노출여부를 확인 한다(hongpopup1, hongpopup2, hongpopup3 를 localstorage에서 가져온다
   * ! 홍여사 요청 업무를 초기 로딩시에 구해온 데이타로 세팅 한다
   * ! 현재 페이지에서 리플레시 햇을때 서버에서 데이타를 구해 올수 있어야 한다 서비스 사용 : ReadWork()
   * 
   */
  // useEffect(()=>{
  //   const now = moment();
  //   async function FetchLocation(){
  //     navigator.geolocation.getCurrentPosition(
  //       (pos) => {
  //         const { latitude, longitude } = pos.coords;

  //         // Geocoder를 사용하여 좌표를 주소로 변환
  //         const geocoder = new kakao.maps.services.Geocoder();
  //         geocoder.coord2Address(longitude, latitude, (result, status) => {
  //           if (status === kakao.maps.services.Status.OK) {
  //             const address = result[0].address;

              
  //             console.log("TCL: FetchLocation -> ", address);
            
  //             user.address_name = address.address_name;
             

  //             geocoder.addressSearch(address.address_name, (result, status) => {
  //               if (status === window.kakao.maps.services.Status.OK) {
  //                   const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);

  //                   user.latitude = result[0].y;
  //                   user.longitude = result[0].x;
  //                   dispatch(user);
              
  //               }
  //             });

  //             dispatch(user);
  //             console.log("TCL: FetchLocation -> ", user );
            

         
  //           }else{
      
  //           }
  //         });
   
  //       },
  //       (err) => {
  //         console.error(err);
  //       }
  //     );
  //   };
  // }, [])



  const _handlebasicmenuclick = async(checkmenu) =>{


    if(menu == checkmenu){
      setMenu("");
   
    }else{
      setMenu(checkmenu);
    }

    let totalset = 0;

    if(checkmenu == WORKNAME.HOMECLEAN){
      setTotalset(WORKPOLICY.HOMECLEAN);
      totalset = WORKPOLICY.HOMECLEAN;
    }else if(checkmenu == WORKNAME.BUSINESSCLEAN){
      setTotalset(WORKPOLICY.BUSINESSCLEAN);
      totalset = WORKPOLICY.BUSINESSCLEAN;
    }else if(checkmenu == WORKNAME.MOVECLEAN){
      setTotalset(WORKPOLICY.MOVECLEAN);
      totalset = WORKPOLICY.MOVECLEAN;
    }else if(checkmenu == WORKNAME.FOODPREPARE){
      setTotalset(WORKPOLICY.FOODPREPARE);
      totalset = WORKPOLICY.FOODPREPARE;
    }else if(checkmenu == WORKNAME.GOOUTSCHOOL){
      setTotalset(WORKPOLICY.GOOUTSCHOOL);
      totalset = WORKPOLICY.GOOUTSCHOOL;
    }else if(checkmenu == WORKNAME.BABYCARE){
      setTotalset(WORKPOLICY.BABYCARE);
      totalset = WORKPOLICY.BABYCARE;
    }else if(checkmenu == WORKNAME.LESSON){
      setTotalset(WORKPOLICY.LESSON);
      totalset = WORKPOLICY.LESSON;
    }else if(checkmenu == WORKNAME.PATIENTCARE){
      setTotalset(WORKPOLICY.PATIENTCARE);
      totalset = WORKPOLICY.PATIENTCARE;
    }else if(checkmenu == WORKNAME.GOHOSPITAL){
      setTotalset(WORKPOLICY.GOHOSPITAL);
      totalset = WORKPOLICY.GOHOSPITAL;
    }else if(checkmenu == WORKNAME.RECIPETRANSMIT){
      setTotalset(WORKPOLICY.RECIPETRANSMIT);
      totalset = WORKPOLICY.RECIPETRANSMIT;
    }else if(checkmenu == WORKNAME.GOSCHOOLEVENT){
      setTotalset(WORKPOLICY.GOSCHOOLEVENT);
      totalset = WORKPOLICY.GOSCHOOLEVENT;
    }else if(checkmenu == WORKNAME.GODOGHOSPITAL){
      setTotalset(WORKPOLICY.GODOGHOSPITAL);
      totalset = WORKPOLICY.GODOGHOSPITAL;
    }else if(checkmenu == WORKNAME.GODOGWALK){
      setTotalset(WORKPOLICY.GODOGWALK);
      totalset = WORKPOLICY.GODOGWALK;
    }else if(checkmenu == WORKNAME.CARRYLOAD){
      setTotalset(WORKPOLICY.CARRYLOAD);
      totalset = WORKPOLICY.CARRYLOAD;
    }else if(checkmenu == WORKNAME.ERRAND){
      setTotalset(WORKPOLICY.ERRAND);
      totalset = WORKPOLICY.ERRAND;
    }else if(checkmenu == WORKNAME.SHOPPING){
      setTotalset(WORKPOLICY.SHOPPING);
      totalset = WORKPOLICY.SHOPPING;
    }
    setRefresh((refresh) => refresh +1);

    navigate("/Mobileregist",{state :{WORKTYPE :checkmenu, WORKTOTAL : totalset}});

  }



  
  const _handleworkselect = () =>{
    if(menu ==''){
      alert("일감 형태를 선택해주세여");
      return;
    }
    navigate("/Mobileregist",{state :{WORKTYPE :menu, WORKTOTAL : totalset}});
  }


  const _handleRoomRegist = async() =>{

    navigate("/Mobileregist",{state :{WORKTYPE :ROOMSIZE.FOURSIZE, WORKTOTAL : ROOMPOLICY.ROOM}});

    setRefresh((refresh) => refresh +1);

  }


  return (
    <>
      <div ref={elementRef}>
        <Container  style={containerStyle} width={width}  >
        <Column style={{marginBottom:5}}>


            <Column style={{width:"100%", margin: "0px auto 0px"}}>
            <SlickSliderComponent width={width} items={BannerItems} />
            </Column>


            <Lineup>
              <Column style={{width:"88%",margin: "30px auto 20px"}} >   
                <BetweenRow style={{flexWrap:"wrap", width:"100"}}>
                  {
                    WorkItems.map((data, index)=>(
                      <Box onClick={()=>{_handlebasicmenuclick(data.name)}} >
                
                        <BoxImg bgcolor ={data.bgcolor} clickstatus={menu == data.name}><img src={data.img} style={{width:35, height:35}}/></BoxImg>

                        <div style={{ fontSize:14, color:"#131313", fontFamily:"Pretendard-SemiBold", marginTop:"5px"}}>{data.name}</div>
                      </Box>
                    ))
                  }
                </BetweenRow>
                {/* <RegistButton onClick={_handleworkselect}><GoPlus size={24}/> 등록</RegistButton> */}


              </Column>
            </Lineup>

            <MobileRegistBanner main={'홍여사로 등록해보세요'} backgroundcolor={"#f8e0be"} sub1={'등록되는 일감에 지원하고 돈벌어보세요'} 
             image={imageDB.logo}  imagewidth={'60px'} />

      
            <GuideView>
              <Guide>짐을 맡아주고 돈을 벌어보세요</Guide>
              <GuideSub>집에 공간이 비어 있다면 공간을 등록해보고 수수료 받으세요</GuideSub>

              <Row>
                  <img src={imageDB.roomsize1} style={{width:130}}/>
        
                  <Row>
                    <ButtonEx text={'짐 맡아주기 신청'} width={'100'} containerStyle={{marginBottom:30, fontSize:14, margin:"40px auto",
                    padding:"0px 25px",
                    height:"34px"}}
                    onPress={_handleRoomRegist} bgcolor={'#f1c359'} color={'#131313'} />
                  </Row>
         
              </Row>
            </GuideView>
   
     
      

        </Column>

        <MobileStoreInfo height={200} containerStyle={{marginBottom:50}} />
        </Container>

      </div>


    </>


  );

}

export default MobileMaincontainer;

