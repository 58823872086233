import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';

import { UserContext } from "../../context/User";

import MobileSearchLayout from "../../screen/Layout/Layout/MobileSearchLayout";
import MobileAICategoryCreatecontainer from "../../container/main/MobileAICategoryCreatecontainer";
import MobileAICategoryListcontainer from "../../container/main/MobileAICategoryListcontainer";


const Container = styled.div`

`
const style = {
  display: "flex"
  
};

const MobileAICategoryListpage =() =>  {
  const location = useLocation();

  return (

    <MobileSearchLayout name={location.state.category} >
        <MobileAICategoryListcontainer name={location.state.category} />
    </MobileSearchLayout>
  );

}

export default MobileAICategoryListpage;

