import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import LazyImage from "../common/LasyImage";
import { FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";
import { ensureHttps } from "../utility/common";




const Container = styled.div`
    width:90%;
    margin : 20px auto;
`
const style = {
  display: "flex"
};

const LableIconLayer = styled.div`
  background: rgb(237, 237, 237);
  width: 60px;
  border-radius: 5px;
  height: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
  color :#00ff00;
  color: #2c84ff;
  font-size: 12px;
  font-family:Pretendard-Bold;
`
const RecipeContent = styled.div`
    font-size:14px;
    padding-left:10px;
    width:80%;
`



const Recipe =({containerStyle, item}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

  }, [refresh])

  useEffect(()=>{
      async function FetchData(){
       
      }
      FetchData();
  }, [])
  
  function EraseName1(recipecontent){
    let oneposition = recipecontent.indexOf('1.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
 
  function EraseName2(recipecontent){
    let oneposition = recipecontent.indexOf('2.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
  function EraseName3(recipecontent){
    let oneposition = recipecontent.indexOf('3.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
  function EraseName4(recipecontent){
    let oneposition = recipecontent.indexOf('4.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
  function EraseName5(recipecontent){
    let oneposition = recipecontent.indexOf('5.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
  function EraseName6(recipecontent){
    let oneposition = recipecontent.indexOf('6.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }

  function EraseName7(recipecontent){
    let oneposition = recipecontent.indexOf('7.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }

  function EraseName8(recipecontent){
    let oneposition = recipecontent.indexOf('8.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }

  function EraseName9(recipecontent){
    let oneposition = recipecontent.indexOf('9.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }


  function EraseName10(recipecontent){
    let oneposition = recipecontent.indexOf('10.');
    console.log("TCL: EraseName -> oneposition", oneposition)

    
    if(oneposition != -1){
      return recipecontent.substring(oneposition +2 ,recipecontent.length);

    }else{
      return recipecontent;
    }
  }
  return (

    <Container style={containerStyle}>
        {
          item.MANUAL01 != '' &&  <FlexstartRow style={{margin:"10px 0px"}}>
            <LableIconLayer>
                <div>STEP 1</div>
            </LableIconLayer>
            <RecipeContent>{EraseName1(item.MANUAL01)}</RecipeContent>
            </FlexstartRow>
        }
        {
          item.MANUAL_IMG01 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG01)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }

        {
          item.MANUAL02 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 2</div>
          </LableIconLayer>
          <RecipeContent>{EraseName2(item.MANUAL02)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG02 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG02)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }
      
        {
          item.MANUAL03 != '' &&   <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 3</div>
          </LableIconLayer>
          <RecipeContent>{EraseName3(item.MANUAL03)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG03 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG03)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }  

        {
          item.MANUAL04 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 4</div>
          </LableIconLayer>
          <RecipeContent>{EraseName4(item.MANUAL04)}</RecipeContent>
          </FlexstartRow>
        }

        {
          item.MANUAL_IMG04 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG04)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }

        {
          item.MANUAL05 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 5</div>
          </LableIconLayer>
          <RecipeContent>{EraseName5(item.MANUAL05)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG05 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG05)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }        
        {
          item.MANUAL06 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 6</div>
          </LableIconLayer>
          <RecipeContent>{EraseName6(item.MANUAL06)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG06 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG06)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL07 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 7</div>
          </LableIconLayer>
          <RecipeContent>{EraseName7(item.MANUAL07)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG07 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG07)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 

        {
          item.MANUAL08 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 8</div>
          </LableIconLayer>
          <RecipeContent>{EraseName8(item.MANUAL08)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG08 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG08)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL09 != '' &&  <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 9</div>
          </LableIconLayer>
          <RecipeContent>{EraseName9(item.MANUAL09)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG09 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG09)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL10 != '' && <FlexstartRow style={{margin:"20px 0px"}}>
          <LableIconLayer>
              <div>STEP 10</div>
          </LableIconLayer>
          <RecipeContent>{EraseName10(item.MANUAL10)}</RecipeContent>
          </FlexstartRow>
        }
        {
          item.MANUAL_IMG10 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG10)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL11 != '' &&  <div>{item.MANUAL11}</div>
        }
        {
          item.MANUAL_IMG11 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG11)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }

        {
          item.MANUAL12 != '' &&  <div>{item.MANUAL12}</div>
        }
        {
          item.MANUAL_IMG12 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG12)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }
      
        {
          item.MANUAL13 != '' &&  <div>{item.MANUAL13}</div>
        }
        {
          item.MANUAL_IMG13 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG13)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }  

        {
          item.MANUAL14 != '' &&  <div>{item.MANUAL14}</div>
        }
        {
          item.MANUAL_IMG14 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG14)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }

        {
          item.MANUAL15 != '' &&  <div>{item.MANUAL15}</div>
        }
        {
          item.MANUAL_IMG15 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG15)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        }        
        {
          item.MANUAL16 != '' &&  <div>{item.MANUAL16}</div>
        }
        {
          item.MANUAL_IMG16 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG16)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL17 != '' &&  <div>{item.MANUAL17}</div>
        }
        {
          item.MANUAL_IMG17 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG17)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 

        {
          item.MANUAL18 != '' &&  <div>{item.MANUAL18}</div>
        }
        {
          item.MANUAL_IMG18 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG18)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 

        {
          item.MANUAL19 != '' &&  <div>{item.MANUAL19}</div>
        }
        {
          item.MANUAL_IMG19 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG19)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 
        {
          item.MANUAL20 != '' &&  <div>{item.MANUAL20}</div>
        }
        {
          item.MANUAL_IMG20 != '' && <LazyImage src={ensureHttps(item.MANUAL_IMG20)} containerStyle={{width:'100%', backgroundColor: '#e0e0e0' }}/>

        } 

      
    </Container>
  );

}

export default Recipe;

