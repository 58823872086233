import { Table } from "@mui/material";
import { setLogLevel } from "firebase/firestore";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import { Column } from "../common/Column";
import LottieAnimation from "../common/LottieAnimation";
import { Row } from "../common/Row";
import { DataContext } from "../context/Data";
import { UserContext } from "../context/User";
import { LoadingCommunityStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { ReadTourRegion } from "../service/LifeService";
import { sleep, useSleep } from "../utility/common";
import { imageDB } from "../utility/imageData";
import "./mobile.css";
import "./table.css";



const Container = styled.div`
    width:100%;


`
const style = {
  display: "flex"
};

const DetailLevel = 4;
const DetailMeter =300;
const BasicLevel =9;



const mapstyle = {
    position: "absolute",
    overflow: "hidden",
    top: '10%',
    width:'100%',
  };


const PopupWorkEx = styled.div`

  position: absolute;
  top:400px;
  width: 100%;
  background: #fff;
  z-index: 5;
  overflow-y: auto;
`

const TableLayout = styled.div`
  overflow-y: scroll;
  scroll-behavior: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-bottom: 100px;
`
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}

const MapExbtn = styled.div`
  position: relative;
  top: 10px;
  left: 88%;
  z-index: 3;
  background: #f9f9f9;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderPopupline = styled.div`

  width:30%;
  background:#E3E3E3;
  height:5px;
`

  
/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const MobileLifeTourRegion =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [overlays, setOverlays] = useState([]);
  const [item, setItem] = useState(null);
  const [currentloading, setCurrentloading] = useState(true);

  const [show, setShow] = useState(true);

  const [expand, setExpand] = useState(false);

  const [curmap, setCurMap] = useState({});

  const [markerex, setMarkerex] = useState(false);

  const [level, setLevel] = useState(0);


    // useLayoutEffect(() => {
    // }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     return () => {};
    // }, []);

    useEffect(()=>{
        setOverlays(overlays);
        setCurrentloading(currentloading);
        setItem(item);
        setExpand(expand);
        setCurMap(curmap);
        setMarkerex(markerex);
        setLevel(level);
    }, [refresh])

    useLayoutEffect(()=>{
        async function FetchData(){

            if(data.tourregionitem.length == 0){
              const tourregionitem = await ReadTourRegion();
              const dataToSaveregion = JSON.parse(tourregionitem);
              data.tourregionitem = dataToSaveregion.response.body.items;
  
              datadispatch(data);
      
            }
            await sleep(1000);
            ListmapDraw(data.tourregionitem)
        }
        FetchData();
    }, [])

 
    function findMapIndex(id, items){
        console.log("TCL: findWorkIndex -> workitems", items)
    
       const FindIndex =  items.findIndex(x=>x.lnmadr  === id)
    
       return FindIndex;
      }

    const _handleControlFromMap = (id, items) =>{
        let FindIndex = findMapIndex(id, items);

        setItem(items[FindIndex]);


        setRefresh((refresh) => refresh+1);
    }




    const _handleExmap = () =>{
      navigate("/Mobilemap" ,{state :{WORK_ID :"", TYPE : ""}});
    }

    const _handlePopupClose = () =>{
      setItem(null);
      setRefresh((refresh) => refresh +1);
    }


    function ListmapDraw(datas){
    var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
    mapOption = { 
            center: new kakao.maps.LatLng(user.latitude, user.longitude), // 지도의 중심좌표
            level: BasicLevel // 지도의 확대 레벨
    };



    var map = new kakao.maps.Map(mapContainer, mapOption);


    var overlaysTmp = [];
    var overlays = [];


    for (var i = 0; i < datas.length; i ++){
        let overlay = {
        POSITION : {},
        NAME : "",
        ITEMS :datas,
        id :"",
        }

   
        let latitude = "";
        let longitude = "";


  

        latitude = datas[i].latitude;
        longitude =  datas[i].longitude;


        overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
        overlay.id = datas[i].lnmadr;
        overlay.NAME = datas[i].trrsrtNm;
        overlay.ITEMS = datas
        overlaysTmp.push(overlay);

    }


    overlaysTmp.forEach(function(overlayData, index) {

      var content = document.createElement('div');
      var customOverlay ={};

      kakao.maps.event.addListener(map, 'zoom_changed', function() {
        // 현재 지도 레벨 가져오기
        var level = map.getLevel();

        console.log('현재 지도 레벨: ' + level);

        if (level > 9) {
            // 레벨이 7보다 크면 오버레이 숨기기
            content.className = 'maptourregionlay';

            content.innerHTML =
            '  <a>' +
            '    <div>' +
            '    <img src="'+ imageDB.gps+'"style="width:32px;"/>' +
            '    </div>' +
            '  </a>' +
            '</div>';
            

            // Custom Overlay 생성
            customOverlay = new kakao.maps.CustomOverlay({
                position: overlayData.POSITION,
                content: content,
                clickable: true // 클릭 가능하도록 설정
            });

            var customData = {
                id: overlayData.id,
                items : overlayData.ITEMS
            };
            customOverlay.customData = customData;
            // Custom Overlay 지도에 추가

            customOverlay.setMap(map);
  
            overlays.push(customOverlay);


        } else {
              // Custom Overlay 내용 생성

              content.className = 'maptourregionlay';

              content.innerHTML =
              '  <a>' +
              '    <div>' +
              '    <img src="'+ imageDB.tour+'"style="width:32px;"/>' +
              '    </div>' +
              '    <div class="title">'+overlayData.NAME +'</div>' +
              '  </a>' +
              '</div>';
              

              // Custom Overlay 생성
              customOverlay = new kakao.maps.CustomOverlay({
                  position: overlayData.POSITION,
                  content: content,
                  clickable: true // 클릭 가능하도록 설정
              });

              var customData = {
                  id: overlayData.id,
                  items : overlayData.ITEMS
              };
              customOverlay.customData = customData;
              // Custom Overlay 지도에 추가

              customOverlay.setMap(map);
              overlays.push(customOverlay);
        }
      });

  
      // 클릭 이벤트 등록 
      // 지도에서 클릭 햇을때는 리스트에서 클릭 했을때와 달리 별도로 circle을 표시할 필요는 없다
      content.addEventListener('click', function(event) {
        _handleControlFromMap(customOverlay.customData.id, customOverlay.customData.items);

      });

      // 확대/축소 레벨 제한 설정
      const minLevel = 1;
      const maxLevel = 10;

      window.kakao.maps.event.addListener(map, 'zoom_changed', () => {
  
          const level = map.getLevel();

          if (level < minLevel) {
              map.setLevel(minLevel);
          } else if (level > maxLevel) {
              map.setLevel(maxLevel);
          }
      });


     });



     map.setLevel(map.getLevel() +1);
     setCurMap(map);


    //오버레이를 변수에 담아둔다
    setOverlays(overlays);

    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);



    }

  /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);

    return (

    <Container style={containerStyle}>
      {
        item != null && <PopupWorkEx>
          
          <div style={{display:"flex", flexDirection:"column",width:"100%" }}>
            <Row style={{height:"30px"}}>
              <HeaderPopupline onClick={_handlePopupClose} />
            </Row>
            <TableLayout>
              <Table class="region-table" style={{height:500, marginBottom:100}}>
                <tbody>
                  <tr>
                    <td>관광지명</td>
                    <td>{item.trrsrtNm }</td>
                  </tr>    
        
                  {
                    item.trrsrtSe != ''&& 
                    <tr>
                    <td>구분</td>
                    <td>{item.trrsrtSe }</td>
                  </tr> 
                  }
                  {
                    item.cnvnncFclty != ''&& 
                    <tr>
                    <td>공공편익</td>
                    <td>{item.cnvnncFclty }</td>
                  </tr> 
                  }

                  {
                    item.stayngInfo != ''&& 
                    <tr>
                    <td>숙박시설</td>
                    <td>{item.stayngInfo }</td>
                  </tr> 
                  }


                  {
                    item.mvmAmsmtFclty != ''&& 
                    <tr>
                    <td>오락시설</td>
                    <td>{item.mvmAmsmtFclty }</td>
                  </tr> 
                  }
                  {
                    item.recrtClturFclty != ''&& 
                    <tr>
                    <td>문화시설</td>
                    <td>{item.recrtClturFclty }</td>
                  </tr> 
                  }
                  {
                    item.hospitalityFclty != ''&& 
                    <tr>
                    <td>접객시설</td>
                    <td>{item.hospitalityFclty }</td>
                  </tr> 
                  }
                  {
                    item.sportFclty != ''&& 
                    <tr>
                    <td>문화시설</td>
                    <td>{item.sportFclty }</td>
                  </tr> 
                  }
                      {
                    item.aceptncCo != ''&& 
                    <tr>
                    <td>문화시설</td>
                    <td>{item.aceptncCo }평</td>
                  </tr> 
                  }
                      {
                    item.prkplceCo != ''&& 
                    <tr>
                    <td>주차가능</td>
                    <td>{item.prkplceCo }대 가능</td>
                  </tr> 
                  }
                      {
                    item.trrsrtIntrcn != ''&& 
                    <tr>
                    <td>소개</td>
                    <td>{item.trrsrtIntrcn }</td>
                  </tr> 
                  }
                      {
                    item.phoneNumber != ''&& 
                    <tr>
                    <td>전화번호</td>
                    <td>{item.phoneNumber }</td>
                  </tr> 
                  }

                  {
                    item.institutionNm != ''&& 
                    <tr>
                    <td>관리기관</td>
                    <td>{item.institutionNm }</td>
                  </tr> 
                  }



                </tbody>
              </Table>
            </TableLayout>
          </div>
        </PopupWorkEx>
      }

      {
        currentloading == true  && <LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'} />
      }

      
      <div style={{position:"absolute", width:"100%"}}>
        <div id="map" className="Map" style={mapstyle}></div>
      </div>
      

    </Container>
    );

}

export default MobileLifeTourRegion;

