import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../screen/css/common.css";
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { Column, FlexstartColumn } from "../common/Column";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../common/Button";
import { FILTERITEMMONEY, FILTERITEMPERIOD, FILTERITMETYPE, LoadingType } from "../utility/screen";

import { WORKNAME } from "../utility/work";
import { ROOMSIZE, ROOMSIZEDISPALY } from "../utility/room";

import { MdLockReset } from "react-icons/md";
import { imageDB } from "../utility/imageData";

import { se } from "date-fns/locale";

import { model } from "../api/config";
import Loading from "../components/Loading";
import { useSleep } from "../utility/common";
import { CreateSearch, DeleteSearchByid, ReadSearch, ReadSearchByid } from "../service/SearchService";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { getFullTime } from "../utility/date";

import TimeAgo from 'react-timeago';

import { FaListCheck } from "react-icons/fa6";



import { GrUploadOption } from "react-icons/gr";
import LottieAnimation from "../common/LottieAnimation";
import TypingText from "../common/TypingText";
import { LoadingAnimationStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { CiSearch } from "react-icons/ci";
import ButtonEx from "../common/ButtonEx";
import { useNavigate } from "react-router-dom";
import MobileCategoryFilter from "../modal/MobileCategoryFilter";
import { CreateCategoryContent } from "../service/CategoryService";
import { UserContext } from "../context/User";
import { FaArrowRight } from "react-icons/fa";
import IconButton from "../common/IconButton";
import { CreateRecipe } from "../service/RecipeService";

const formatter = buildFormatter(koreanStrings); 



const style = {
  position: "absolute",

};

const Popcontent = styled.div`
    height:100%;
    width:100%;
    font-family: 'Pretendard-Regular';
`


const ResultLayer = styled.div`
  width:100%;

`
const ResultContent = {
  width: "80%",
  margin: "0px auto",
  height: "900px",
  padding: "80px 20px 20px 20px",
  fontSize: "16px",
  fontFamily: "Pretendard-SemiBold",
  lineHeight: 2,
  outline: "none",
  resize: "none",
  border: "none",
  backgroundColor: "#fff"
}

const InputContent = {
  width:'80%',
  margin:'30px auto',
  borderRadius: '5px',
  fontFamily: 'Pretendard-Regular',
  flex: '0 0 auto',
  height: '30px',
  border: 'none',
  borderRadius: '10px',
  background:'#f4f4f4',
  paddingLeft:'30px'
}


const SearchIcon = styled.div`
  background: #00000057;
  width: 20%;
  margin: 200px auto;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;


`

const MobileResultContent = {
  width: '90%',
  height: '580px',
  padding: '0px 20px 0px 20px',
  fontSize: '16px',
  fontFamily: 'Pretendard-Regular',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",

}

const InfoLayer = styled.div`

  background: #f9f9f9;
  width: 90%;
  margin: 0 auto;


`
const InfoLayerContent = styled.div`


  padding: 20px;
  font-size: 14px;
  line-height:2;

`
const listTag ={
  listStyleType: 'disc',
  listStylePosition: 'inside',
}



const MobileAISearch = ({ search, search_id }) =>{
  const { dispatch, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [currentloading, setCurrentloading] = useState(false);
  const [result, setResult] = useState(false);
  const [searchresult, setSearchresult] = useState('');
  const [searchitems, setSearchitems] = useState([]);
  const [search_comment, setSearch_comment] = useState('');

  const [refresh, setRefresh] = useState(-1);
  const [memoenable, setMemoenable] = useState(false);

  const [research, setResearch] = useState('');
  const [storepopup, setStorepopup] = useState(false);
  const inputRef = useRef(null);


  const _handleMemo = () =>{
    setMemoenable(true);
    setRefresh((refresh) => refresh +1 );
  }

  useEffect(() => {
    const handleResize = () => {
      if (document.activeElement === inputRef.current) {
          setTimeout(() => {
              inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 300);
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
        window.removeEventListener('resize', handleResize);
    };

  }, []);



  useEffect(() => {
    setCurrentloading(currentloading);
    setSearchitems(searchitems);
    setSearchresult(searchresult);
    setMemoenable(memoenable);
    setResult(result);
    setStorepopup(storepopup);
    setSearch_comment(search_comment);
    setResearch(research);

  }, [refresh]);



  /**
   * 데이타를 가져온다
   * 1) 무조건 데이타를 가져 와서 저장 해둔다
   * 2) 검색어가 있다면 zemini에 요청한다
   * 3) 검색 결과를 searchresult 에 저장 해두고 데이타 베이스에 입력한다
   * 4) 검색어가 없다면 처음에 가져온 데이타에서 첫번째 인덱스 값을 보여준다 
   */
  useEffect(() =>{
    //  async function FetchData(){

   
    //   fetch('/recipe.json')
    //       .then(response => response.json())
    //       .then(data => {
    //         // Write each item to Firestore
    //         console.log(data);
    //         data.row.forEach(async item => {
    //           try {
    //             const ITEM = item;
    //             await CreateRecipe({ITEM});
    //             console.log('Data written to Firestore:', item);
    //           }catch (error) {
    //             console.error('Error writing document:', error);
    //           }
    //         });
    //       });

    // }
  
    // FetchData();

  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        AIResearch();
    }
  };


  const AIResearch = async() =>{

    const USER_ID= "01062149756";

    setCurrentloading(true);
    setSearchresult("");
    

    const result = await model.generateContent(research);
    const response = result.response;
    const text = response.text();
    console.log("TCL: FetchData -> text", text)

    setSearchresult(text);

    const SEARCH = research;
    const CONTENT = text;


    setCurrentloading(false);
    setResult(true);
    setRefresh((refresh) => refresh +1);
  }


  const _handleResearch = () =>{
    setSearchresult('');
    setRefresh((refresh) => refresh +1);
  }

  const _handleStorePopup = () =>{
    setStorepopup(true);
    setRefresh((refresh) => refresh +1);
  }
  const MobileCategorySelect = async(data)=>{
    setStorepopup(false);
    // 데이타 베이스에 저장 하고 이동 하자
    const USERS_ID= user.users_id;
    const CONTENT = searchresult;
    const KEYWORD = research;
    const CATEGORY = data;


    const categorycontent = await CreateCategoryContent({USERS_ID,CONTENT, KEYWORD, CATEGORY});
    navigate("/Mobileaicategorycreate");
  }

  
  return (
    <div>


      {
        storepopup == true && <MobileCategoryFilter callback={MobileCategorySelect} />
      }
    
      {
        currentloading == true ? ( <LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}
          />) :(

            <>
            {
              searchresult == '' ? ( <Column style={{width:'100%', margin: '0 auto'}}>
              <Popcontent>
                <Column style={{height:"100%"}}>

                  <ResultLayer>
                    <div style={{height:50, width:'95%', margin:'10px auto 10px', display:"flex", flexDirection:"column"}}> 
                      <Row>
                        <img src={imageDB.teacher} style={{width:"120px", height:"120px"}}/>

                        {/* <video style={{width:"70%"}} autoPlay  muted  loop >
                        <source src={imageDB.introduce3}  type="video/mp4" />
                      </video> */}

                      </Row>

                      <img className ="searchicon" src={imageDB.searchgif} style={{width:20, height:20, position:"relative",
                      top:"65px", left:"20px"}} />


                      <input type={'text'} style={InputContent} value={research} 
                        placeholder={'무엇이든 물어주세요. 답변 해드릴께요'}
                        class="Aiinput"
                        onKeyDown={handleKeyDown} 
                        onChange={(e) => {
                            setResearch(e.target.value);
                            setRefresh((refresh) => refresh +1);
                          }}/>

                      
                      <InfoLayer>
                        <InfoLayerContent>
                         
                          <div>이런 질문 등을 해보세요</div>
                          <ul>
                            <li style={listTag}>짜장면을 맛있게 끊이는 방법</li>
                            <li style={listTag}>아이성장에 도움이 되는 음식</li>
                            <li style={listTag}>조선왕조 500년의 역사 정리</li>
                          </ul>
                          <div>지식창고 만들기 버튼을 눌러 카테고리를 만들어보세요</div>
                          
                        </InfoLayerContent>
                      
       
                      </InfoLayer>
                
                    </div>
    
                  </ResultLayer>
                
                </Column>
      
              </Popcontent>
            </Column>):( 
            
            <div>
              <textarea 
                 value ={searchresult}
                  style={MobileResultContent}></textarea>

                <BetweenRow>
                    <ButtonEx text={'다시 검색해보기'} width={'45'} 
                    onPress={_handleResearch} bgcolor={'#00000057'} color={'#fff'} />
                    <ButtonEx text={'보관하기'} width={'45'} 
                    onPress={_handleStorePopup} bgcolor={'#ff7e19'} color={'#fff'} />
                </BetweenRow>
           
              
            </div>
            
            )
            }
            
            </>
        )
      }

    </div>
  );
};

export default MobileAISearch;