export const LIFEMENU={
    TOUR : '국내관광',
    PERFORMANCE : "영화공연",
    FOOD :"식품정보",
    CONVENIENCE : "생활편의",
    MEDICAL : "의료정보",
    WEATHER : "날씨환경",
    BOARD :"나의냉장고",
    RECIPE :"요리레시피",
    AI :"홍여사 AI",
    PICTURE :"관광지사진",
    WORK :"가사분담",
    RULLET :"룰렛이벤트",
    GAME :"도전홍여사",
    ATTENDANCE : "출석체크"
}

export const TOURISTMENU ={
    TOURREGION : '관광정보',
    TOURFESTIVAL : '문화축제',
    TOURCOUNTRY : '문화유적',
    TOURPICTURE: '관광지사진',
}
export const PERFORMANCEMENU ={
    PERFORMANCEEVENT : '공연행사',
    PERFORMANCECINEMA : '공공시설',

}

export const FAMILYMENU ={
    FAMILYABANDON : '유기동물 정보',
    FAMILYLOST : '분실물 정보',
}
export const CONVENIENCEMENU ={
    CONVENIENCECAMPING : '전국캠핑',
}
export const BOARDMENU ={
    BOARD : '나의냉장고',
}

export const MEDICALMENU ={
    MEDICALMEDICINE : '의약품정보',
    MEDICALHOSPITAL : '의료기관정보',
    FOODINFOMATION: '건강식품',

}

