
export const WORKNAME={
    ALLWORK : '전체보기',
    HOMECLEAN : '집 청소',
    BUSINESSCLEAN : '사무실청소',
    MOVECLEAN : '이사청소',
    FOODPREPARE : '식사준비',
    ERRAND : '도와주기',
    GOOUTSCHOOL : '등원하원',
    BABYCARE : '아이돌봄',
    LESSON : '아이레슨',
    PATIENTCARE : '간병하기',
    CARRYLOAD : '집안수리',
    GOHOSPITAL : '병원가기',
    RECIPETRANSMIT : '요리비법',
    GOSCHOOLEVENT : '학교행사',
    SHOPPING : '장봐주기',
    GODOGHOSPITAL : '애견돌봄',
    GODOGWALK : '애견산책',
    ROOM :"공간대여"

}

export const REFRESHTYPE= "REFRESH";
export const RESETTYPE= "RESET";

export const WORKPOLICY ={
    HOMECLEAN :3,
    BUSINESSCLEAN :3,
    MOVECLEAN:3,
    FOODPREPARE: 3,
    ERRAND: 3,
    GOOUTSCHOOL: 3,
    BABYCARE :3,
    LESSON: 3,
    PATIENTCARE :3,
    CARRYLOAD : 3,
    GOHOSPITAL :3,
    RECIPETRANSMIT : 3,
    GOSCHOOLEVENT: 3,
    SHOPPING: 3,
    GODOGHOSPITAL: 3,
    GODOGWALK : 3,
}




export const WORK_INFO= {
    WORKTYPE :"",
    REQUESTCONTENT :"",
    WORKKEYWORD:[],
    REGION:"",
    DATE:"",
    PRICE:"",
    LATITUDE:"",
    LONGITUE:""

}

export const REQUESTINFO={
    HOMECLEANMONEY :"금액",
    HOMECLEANTARGET : "집청소대상",
    HOMECLEANPOSITION : "집청소범위",
    HOMECLEANTIME : "집청소시간",




    MOVECLEANTARGET:'이사청소대상',
    MOVECLEANPOSITION:'이사청소범위',
    MOVECLEANTIME:'이사청소시간',
    PERIOD : "주기",
    DATE : "일자",
    MONEY : "금액",
    TARGET : "대상",
    TARGETPOSITION : "대상",
    TARGETPREPARE :"식사준비장소",
    PREPAREOPTION :"식사준비옵션",
    PREPARECONTENT : "식사준비내용",
    PREPARETIME :"식사준비시간",
    PREPARECASE :"식사준비종류",
    GOOUTSCHOOL:'등원하원대상',
    GOOUTSCHOOLTIME :'등하원도움시간',
    HELP: "홍여사성별과연령대",

    TARGETAREA : "범위",
    TARGETTIME: '시간',
    TARGETMONEY:'금액',
    TIMEMONEY : "시간과 금액",
    TARGETAREA : "대상평수",
    TIME : "시간",
    CLEANINGTIME: "요청시간대",
    CUSTOMERGENDER : "고객님성별",

   //주기적실시여부 집청소일자 집청소대상 집청소범위 집청소시간 금액 원하는성별 원하는연령대 지역 집청소요구사항
    HOMECLEANCYCLE: '정기여부',
    HOMECLEANNDATE : "집청소일자",
    HOMECLEANTARGET :"집청소대상",
    HOMECLEANRANGE: "집청소범위",
    HOMECLEANTIME : "집청소시간",
    HOMECLEANMONEY : "금액",
    HELPGENDER : "원하는성별",
    HELPAGE : "원하는연령대",
    CUSTOMERREGION :"지역",
    COMMENT :"요구사항",
    REQDATE:"요청일자",
    POSITION :"공간장소",


    BUSINESSCLEANCYCLE: '정기여부',
    BUSINESSCLEANNDATE : "사무실청소일자",
    BUSINESSCLEANTARGET :"사무실청소대상",
    BUSINESSCLEANRANGE: "사무실청소범위",
    BUSINESSCLEANTIME : "사무실청소시간",
    BUSINESSCLEANMONEY : "금액",


    MOVECLEANCYCLE: '정기여부',
    MOVECLEANNDATE : "이사청소일자",
    MOVECLEANTARGET :"이사청소대상",
    MOVECLEANRANGE: "이사청소범위",
    MOVECLEANTIME : "이사청소시간",
    MOVECLEANMONEY : "금액",
  


    PREPARECYCLE: '정기여부',
    PREPAREDATE : "식사준비일자",
    PREPARETARGET :"식사준비장소",
    PREPAREOPTION: "식사준비옵션",
    PREPARECONTENT: "식사준비내용",
    PREPARETIME : "식사준비시간",
    PREPAREMONEY : "금액",

    ERRANDCYCLE: '정기여부',
    ERRANDDATE : "도움받을일자",
    ERRANDCONTENT : "도움종류",
    ERRANDMONEY : "금액",



    GOOUTSCHOOLCYCLE: '정기여부',
    GOOUTSCHOOLDATE : "등하원일자",
    GOOUTSCHOOLMONEY : "금액",
    GOOUTSCHOOLTARGET : "등하원대상",

    BABYCARECYCLE: '정기여부',
    BABYCAREDATE : "아이돌봄일자",
    BABYCAREMONEY : "금액",
    BABYCARETIME : "아이돌봄시간",
    BABYCARETARGET : "아이돌봄장소",
    BABYCARERANGE : "아이돌봄대상",


    LESSONCYCLE: '정기여부',
    LESSONDATE : "레슨일자",
    
    LESSONMONEY : "금액",
    LESSONTIME : "레슨시간",
    LESSONTARGET : "레슨할 장소",
    LESSONRANGE : "레슨종류",

    PATIENTCARECYCLE: '정기여부',
    PATIENTCAREDATE : "간병일자",
    PATIENTCAREMONEY : "금액",
    PATIENTCARETIME : "간병시간",
    PATIENTCARETARGET : "간병할대상",
    PATIENTCARERANGE : "간병할장소",
    PATIENTCAREGENDER :"간병대상자성별",

    CARRAGELOADCYCLE: '정기여부',
    CARRAGELOADDATE : "집안수리일자",
    CARRAGELOADCONTENT : "집안수리종류",
    CARRAGELOADMONEY : "금액",



    GOHOSPITALDATE : "병원방문일자",
    GOHOSPITALTARGET : "환자연령대",
    GOHOSPITALGENDER :"환자성별",


    RECIPECYCLE: '정기여부',
    RECIPEDATE : "요리비법전수일자",
    RECIPEMONEY : "금액",
    RECIPETIME : "요리비법전수시간",
    RECIPETARGET : "요리비법전수장소",
    RECIPEOPTION : "요리비법전수옵션",



    GOSCHOOLEVENTCYCLE: '정기여부',
    GOSCHOOLEVENTDATE : "학교행사참석일자",
    GOSCHOOLEVENTMONEY : "금액",
    GOSCHOOLEVENTTARGET : "학교행사참석장소",
    GOSCHOOLEVENTCONTENT : "학교행사종류",

    SHOPPINGCYCLE: '정기여부',
    SHOPPINGDATE : "장보는일자",
    SHOPPINGMONEY : "금액",
    SHOPPINGCONTENT : "장볼대상",

    DOGHOSPITALCYCLE: '정기여부',
    DOGHOSPITALDATE : "애견돌봄일자",
    DOGHOSPITALMONEY : "금액",
    DOGHOSPITALCONTENT : "애견돌봄내용",

    GODOGWALKCYCLE: '정기여부',
    GODOGWALKDATE : "애견산책일자",
    GODOGWALKMONEY : "금액",
    GODOGWALKTIME : "애견산책시간",

    ROOM :"공간장소",
    ROOMCHECK :"짐확인"
}



/**
 * ! ① type : initialize(초기값), request(일반요청),
 * ! requestdate(날짜요청), requestregion(지역요청), requestcomplete(완료)
 * ! ② show : 보여줄지 말지 결정 보여줄거면  true
 * ! ③ index : 순번
 * ! ④ info : 내용
 * ! ⑤ selected :  선택했으면  true
 * ! ⑦ selectitems : 선택 할 대상들 배열
 * 
 * ! ① type : response(응답)
 * ! ② show : 보여줄지 말지 결정 보여줄거면  true
 * ! ③ index : 순번
 * ! ④ info : 내용
 * ! ⑤ requesttype : 어떤 대상인지 설정
 * ! ⑥ result : 고객이 선택한 값을 설정한다
 * 
 */
export const Requestcleanmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"청소하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.HOMECLEANNDATE,  result:""},

    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step :2,
      info:"청소 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:true, 
      targetpositionselectitems:[
        {selected : false, key:"아파트", request:"아파트", response:"아파트"},
        {selected : false, key:"빌라", request:"빌라", response:"빌라"},
        {selected : false, key:"단독주택", request:"단독주택", response:"단독주택"},
        {selected : false, key:"오피스텔", request:"오피스텔", response:"오피스텔"},],
      targetareaselectitems:[
        {selected : false, key:"10평미만", request:"10평미만", response:"10평미만"},
        {selected : false, key:"10평대", request:"10평대", response:"10평대"},
        {selected : false, key:"20평대", request:"20평대", response:"20평대"},
        {selected : false, key:"30평대", request:"30평대", response:"30평대"},
        {selected : false, key:"40평대", request:"40평대", response:"40평대"},
        {selected : false, key:"50평대", request:"50평대", response:"50평대"},],

      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},],
      helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
    },

    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.HOMECLEANTARGET,  result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.HOMECLEANRANGE,  result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HOMECLEANMONEY, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPAGE, result:""},
  
   
    {
      type:"requestregion", 
      show:false, 
      index:9,
      step :3,
      info:"집청소가 필요한곳의 지역을 선택해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:10, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},


    {type:"requestcomplete", show: false, index:11, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestbusinesscleanmessages =[

  {
   type:"initialize", 
   show:true, 
   index:0, 
   step :0,
   info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
   selected: false,
   multiselect_enable: false,
   selectitems :[
     {selected : false, key:"",request:"", response:""}
   ]
  },

  {
    type:"requestdate", 
    show:false, 
    index:1,
    step :1,
    info:"사무실 청소하는 시기를 언제로 할까요 ?",
    selected : false,
  },
  
  {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.BUSINESSCLEANNDATE,  result:""},

  {
    type:"requesttarget", 
    show:false, 
    index:3, 
    step :2,
    info:"사무실청소 일감에 관련한 내용을 선택해주세요",
    selected : false,
    multiselect_enable:true, 
    targetpositionselectitems:[
      {selected : false, key:"지식산업센타", request:"지식산업센타", response:"지식산업센타"},
      {selected : false, key:"단독빌딩", request:"단독빌딩", response:"단독빌딩"},
      {selected : false, key:"상가", request:"상가", response:"상가"},
      {selected : false, key:"오피스텔", request:"오피스텔", response:"오피스텔"},],
    targetareaselectitems:[
      {selected : false, key:"30평미만", request:"30평미만", response:"30평미만"},
      {selected : false, key:"30평이상", request:"30평이상", response:"30평이상"},],


  
    moneyselectitems:[
      {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
      {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
      {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
      {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
      {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},],
    helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
    helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
  },


  {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.BUSINESSCLEANTARGET,  result:""},
  {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.BUSINESSCLEANRANGE,  result:""},
  {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.BUSINESSCLEANMONEY, result:""},
  {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPGENDER, result:""},
  {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPAGE, result:""},

  {
    type:"requestregion", 
    show:false, 
    index:9,
    step :3,
    info:"사무실 청소가 필요한곳의 지역을 선택해주세요",
    selected : false,
  
  },
  {type:"response", responseshow : false,  show:false, index:10, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  {type:"requestcomplete", show: false, index:11, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},


]
export const Requestmovecleanmessages =[

  {
   type:"initialize", 
   show:true, 
   index:0, 
   step :0,
   info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
   selected: false,
   multiselect_enable: false,
   selectitems :[
     {selected : false, key:"",request:"", response:""}
   ]
  },


  {
    type:"requestdate", 
    show:true, 
    index:1,
    step :1,
    info:"이사청소하는 시기를 언제로 할까요 ?",
    selected : false,
  },

  {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.MOVECLEANNDATE,  result:""},

  {
    type:"requesttarget", 
    show:false, 
    index:3, 
    step :2,
    info:"이사청소 일감에 관련한 내용을 선택해주세요",
    selected : false,
    multiselect_enable:true, 
    targetpositionselectitems:[
      {selected : false, key:"아파트", request:"아파트", response:"아파트"},
      {selected : false, key:"빌라", request:"빌라", response:"빌라"},
      {selected : false, key:"단독주택", request:"단독주택", response:"단독주택"},
      {selected : false, key:"오피스텔", request:"오피스텔", response:"오피스텔"},],
    targetareaselectitems:[
      {selected : false, key:"10평미만", request:"10평미만", response:"10평미만"},
      {selected : false, key:"10평대", request:"10평대", response:"10평대"},
      {selected : false, key:"20평대", request:"20평대", response:"20평대"},
      {selected : false, key:"30평대", request:"30평대", response:"30평대"},
      {selected : false, key:"40평대", request:"40평대", response:"40평대"},
      {selected : false, key:"50평대", request:"50평대", response:"50평대"},],

  
    moneyselectitems:[
      {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
      {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
      {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
      {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
      {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},],
    helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
    helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
  },

  {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.MOVECLEANTARGET,  result:""},
  {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.MOVECLEANRANGE,  result:""},
  {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HOMECLEANMONEY, result:""},
  {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPGENDER, result:""},
  {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPAGE, result:""},


  {
    type:"requestregion", 
    show:false, 
    index:9,
    step :3,
    info:"이사 청소가 필요한곳의 지역을 선택해주세요",
    selected : false,
  
  },
  {type:"response", responseshow : false,  show:false, index:10, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  {type:"requestcomplete", show: false, index:11, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},


]
export const Requestfoodpreparemessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"식사준비하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.PREPAREDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step :2,
      info:"식사준비 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 

    
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},],
      prepareoptionselectitems:[
          {selected : false, key:"장보기 포함", request:"장보기 포함", response:"장보기 포함"},
          {selected : false, key:"장보기 미포함", request:"장보기 미포함", response:"장보기 미포함"},],
      preparecontentselectitems:[
        {selected : false, key:"찌개+반찬1개", request:"찌개+반찬1개", response:"찌개+반찬1개"},
        {selected : false, key:"찌개+반찬2개", request:"찌개+반찬2개", response:"찌개+반찬2개"},
        {selected : false, key:"찌개+반찬3개", request:"찌개+반찬3개", response:"찌개+반찬3개"},
        {selected : false, key:"찌개+반찬4개", request:"찌개+반찬4개", response:"찌개+반찬4개"}],
      preparecaseselectitems:[
        {selected : false, key:"협의가능", request:"협의가능", response:"협의가능"},
        {selected : false, key:"아침식사", request:"아침식사", response:"아침식사"},
        {selected : false, key:"점심식사", request:"점심식사", response:"점심식사"},
        {selected : false, key:"저녁식사", request:"저녁식사", response:"저녁식사"},],
      helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"20대", request:"20대", response:"20대"},
          {selected : false, key:"30대", request:"30대", response:"30대"},
          {selected : false, key:"40대", request:"40대", response:"40대"},
          {selected : false, key:"50대", request:"50대", response:"50대"},
          {selected : false, key:"60대", request:"60대", response:"60대"},]
      },


    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.PREPAREMONEY, result:""},
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.PREPAREOPTION, result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.PREPARECONTENT, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.PREPARECASE, result:""},
    {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:9,requesttype:REQUESTINFO.HELPAGE, result:""},

   
    {
      type:"requestregion", 
      show:false, 
      index:10,
      step :3,
      info:"식사준비가 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:11, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  
    {type:"requestcomplete", show: false, index:12, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requesterrandmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"도움받는 시기를 언제로 할까요?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.ERRANDDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3,
      step :2, 
      info:"도움받기 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      helpcontentselectitems:[
        {selected : false, key:"쓰레기분리수거", request:"쓰레기분리수거", response:"쓰레기분리수거"},
        {selected : false, key:"가구배치", request:"가구배치", response:"가구배치"},
        {selected : false, key:"줄서기", request:"줄서기", response:"줄서기"},
        {selected : false, key:"택배부치기", request:"택배부치기", response:"택배부치기"},
        {selected : false, key:"중고거래대행", request:"중고거래대행", response:"중고거래대행"},
        {selected : false, key:"티켓업무", request:"티켓업무", response:"티켓업무"},
        {selected : false, key:"관공서업무", request:"관공서업무", response:"관공서업무"},
        {selected : false, key:"하객대행", request:"하객대행", response:"하객대행"},
        {selected : false, key:"은행업무대행", request:"은행업무대행", response:"은행업무대행"},
        {selected : false, key:"차량픽업", request:"차량픽업", response:"차량픽업"},
        {selected : false, key:"이벤트참여", request:"이벤트참여", response:"이벤트참여"},
        {selected : false, key:"현장확인", request:"현장확인", response:"현장확인"},
        {selected : false, key:"기타도움", request:"기타도움", response:"기타도움"},
      ],
      helpgenderselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"20대", request:"20대", response:"20대"},
          {selected : false, key:"30대", request:"30대", response:"30대"},
          {selected : false, key:"40대", request:"40대", response:"40대"},
          {selected : false, key:"50대", request:"50대", response:"50대"},
          {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.ERRANDMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.ERRANDCONTENT,  result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPAGE, result:""},
   
    {
      type:"requestregion", 
      show:false, 
      index:9,
      step :3,
      info:"도움이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:10, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  
    {type:"requestcomplete", show: false, index:11, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestgooutschoolmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"등원하원 도와주는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.GOOUTSCHOOLDATE,  result:""},


    {
        type:"requesttarget", 
        show:false, 
        index:3, 
        step :2,
        info:"등원하원 일감에 관련한 내용을 선택해주세요",
        selected : false,
        multiselect_enable:false, 
        goouttimeselectitems:[
          {selected : false, key:"아침", request:"아침", response:"아침"},
          {selected : false, key:"오후", request:"오후", response:"오후"},
          {selected : false, key:"저녁", request:"저녁", response:"저녁"},
        ],
        goouttargetselectitems:[
          {selected : false, key:"학교", request:"학교", response:"학교"},
          {selected : false, key:"학원", request:"학원", response:"학원"},
          {selected : false, key:"학교/학원", request:"학교/학원", response:"학교/학원"},
        ],
        moneyselectitems:[
          {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
          {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
          {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
          {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
          {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
        ],
        helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
        helpageselectitems:[
            {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
            {selected : false, key:"20대", request:"20대", response:"20대"},
            {selected : false, key:"30대", request:"30대", response:"30대"},
            {selected : false, key:"40대", request:"40대", response:"40대"},
            {selected : false, key:"50대", request:"50대", response:"50대"},
            {selected : false, key:"60대", request:"60대", response:"60대"},]
      
      
          },
    
    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.GOOUTSCHOOLTIME, result:""},
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.GOOUTSCHOOLTARGET, result:""},
    {type:"response", responseshow : true, show:false, index:6, requesttype:REQUESTINFO.GOOUTSCHOOLMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPAGE, result:""},

    {
      type:"requestregion", 
      show:false, 
      index:9,
      step :3,
      info:"도움이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:11, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  

    {type:"requestcomplete", show: false, index:12, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
  
]
export const Requestbabycaremessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"아이 돌봐주는 시기를 언제로 할까요?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.BABYCAREDATE,  result:""},

    {
        type:"requesttarget", 
        show:false, 
        index:3, 
        step :2,
        info:"아이돌봐주는 일감에 관련한 내용을 선택해주세요",
        selected : false,
        multiselect_enable:false, 
        babycaretimeselectitems:[
          {selected : false, key:"오전", request:"오전", response:"오전"},
          {selected : false, key:"오후", request:"오후", response:"오후"},
          {selected : false, key:"저녁", request:"저녁", response:"저녁"},
          {selected : false, key:"하루종일", request:"하루종일", response:"하루종일"},],


      
        moneyselectitems:[
          {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
          {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
          {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
          {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
          {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
        ],
        helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},
        ],
        helpageselectitems:[
            {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
            {selected : false, key:"20대", request:"20대", response:"20대"},
            {selected : false, key:"30대", request:"30대", response:"30대"},
            {selected : false, key:"40대", request:"40대", response:"40대"},
            {selected : false, key:"50대", request:"50대", response:"50대"},
            {selected : false, key:"60대", request:"60대", response:"60대"},
        ]
        
        },
    
    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.TARGETMONEY, result:""},
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.BABYCARETIME, result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPAGE, result:""},
    {
      type:"requestregion", 
      show:false, 
      index:8,
      step :3,
      info:"아이 돌볼곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:9, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:10, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
  
]
export const Requestlessonmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"아이 레슨하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.LESSONDATE,  result:""},


    {
        type:"requesttarget", 
        show:false, 
        index:3, 
        step :2,
        info:"아이레슨 일감에 관련한 내용을 선택해주세요",
        selected : false,
        multiselect_enable:false, 
        lessontimeselectitems:[
          {selected : false, key:"오후", request:"오후", response:"오후"},
          {selected : false, key:"저녁", request:"저녁", response:"저녁"},
         ],
        lessonrangeselectitems:[
        {selected : false, key:"피아노", request:"피아노", response:"피아노"},
        {selected : false, key:"수학", request:"수학", response:"수학"},
        {selected : false, key:"영어", request:"영어", response:"영어"},
        {selected : false, key:"논술", request:"논술", response:"논술"},
        {selected : false, key:"책읽기", request:"책읽기", response:"책읽기"},
        {selected : false, key:"학습지봐주기", request:"학습지봐주기", response:"학습지봐주기"},
        ],
        moneyselectitems:[
          {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
        ]
    },
    
    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.LESSONTIME, result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.LESSONRANGE,  result:""},
    {type:"response", responseshow : true, show:false, index:6, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {
      type:"requestregion", 
      show:false, 
      index:7,
      step :3,
      info:"레슨이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:8, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  
    {type:"requestcomplete", show: false, index:9, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
  
]
export const Requestpatientcaremessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },


    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"간병하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.PATIENTCAREDATE,  result:""},
    {
        type:"requesttarget", 
        show:false, 
        index:3,
        step :2, 
        info:"간병 일감에 관련한 내용을 선택해주세요",
        selected : false,
        multiselect_enable:false, 
        patientcaretimeselectitems:[
          {selected : false, key:"오전", request:"오전", response:"오전"},
          {selected : false, key:"오후", request:"오후", response:"오후"},
          {selected : false, key:"저녁", request:"저녁", response:"저녁"},
          {selected : false, key:"하루종일", request:"하루종일", response:"하루종일"},
         ],
        patientcaretargetselectitems:[
          {selected : false, key:"아이", request:"아이", response:"아이"},
          {selected : false, key:"10대", request:"10대", response:"10대"},
          {selected : false, key:"20대", request:"20대", response:"20대"},
          {selected : false, key:"30대", request:"30대", response:"30대"},
          {selected : false, key:"40대", request:"40대", response:"40대"},
          {selected : false, key:"50대", request:"50대", response:"50대"},
          {selected : false, key:"60대", request:"60대", response:"60대"},
          {selected : false, key:"70대이상", request:"70대이상", response:"70대이상"},
         ],
        patientcarerangeselectitems:[
          {selected : false, key:"집", request:"집", response:"집"},
          {selected : false, key:"병원", request:"병원", response:"병원"},],
        moneyselectitems:[
            {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
            {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
            {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
            {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
            {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
        ],
        patientcaregenderselectitems:[
          {selected : false, key:"남성", request:"남성", response:"남성"},
          {selected : false, key:"여성", request:"여성", response:"여성"},
        ],
        helpgenderselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"여성", request:"여성", response:"여성"},],
        helpageselectitems:[
            {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
            {selected : false, key:"20대", request:"20대", response:"20대"},
            {selected : false, key:"30대", request:"30대", response:"30대"},
            {selected : false, key:"40대", request:"40대", response:"40대"},
            {selected : false, key:"50대", request:"50대", response:"50대"},
            {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    
    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.PATIENTCARETIME, result:""}, 
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.PATIENTCARERANGE,  result:""},
    {type:"response", responseshow : true, show:false, index:6, requesttype:REQUESTINFO.PATIENTCARETARGET,  result:""},
    {type:"response", responseshow : true, show:false, index:7, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:9,requesttype:REQUESTINFO.HELPAGE, result:""},
    {
      type:"requestregion", 
      show:false, 
      index:10,
      step :3,
      info:"도움이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:11, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},

    {type:"requestcomplete", show: false, index:12, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestcarryloadmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

  
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"집안수리 받을 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.CARRAGELOADDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step :2,
      info:"집안수리 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      carryloadcontentselectitems:[
        {selected : false, key:"가구설치조립", request:"가구설치조립", response:"가구설치조립"},
        {selected : false, key:"전등조명교체", request:"전등조명교체", response:"전등조명교체"},
        {selected : false, key:"커튼설치", request:"커튼설치", response:"커튼설치"},
        {selected : false, key:"못박기", request:"못박기", response:"못박기"},
        {selected : false, key:"집수리", request:"집수리", response:"집수리"},
        {selected : false, key:"변기수리", request:"변기수리", response:"변기수리"},
        {selected : false, key:"컴퓨터조립수리", request:"컴퓨터조립수리", response:"컴퓨터조립수리"},
        {selected : false, key:"조명수리교체", request:"조명수리교체", response:"조명수리교체"},
        {selected : false, key:"블라인드설치", request:"블라인드설치", response:"블라인드설치"},
        {selected : false, key:"타일교체", request:"타일교체", response:"타일교체"},
        {selected : false, key:"줄눈시공", request:"줄눈시공", response:"줄눈시공"},
      ],
      helpgenderselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.CARRAGELOADCONTENT,  result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPAGE, result:""},
 
    {
      type:"requestregion", 
      show:false, 
      index:8,
      step :3,
      info:"집안수리 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:9, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:10, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestgohospitalmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step :0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step :1,
      info:"병원가는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.GOHOSPITALDATE,  result:""},

    {
        type:"requesttarget", 
        show:false, 
        index:3, 
        step :2,
        info:"병원같이가기 일감에 관련한 내용을 선택해주세요",
        selected : false,
        multiselect_enable:false, 
        hospitaltargetselectitems:[
        {selected : false, key:"아이", request:"아이", response:"아이"},
        {selected : false, key:"10대", request:"10대", response:"10대"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},
        {selected : false, key:"70대이상", request:"70대이상", response:"70대이상"},
        ],
        moneyselectitems:[
          {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
          {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
          {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
          {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
          {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
        ],
        hospitalgenderselectitems:[
        {selected : false, key:"남성", request:"남성", response:"남성"},
        {selected : false, key:"여성", request:"여성", response:"여성"},
        ],
        helpgenderselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"여성", request:"여성", response:"여성"},
        ],
        helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},
        ]
    },
    
    {type:"response", responseshow : true, show:false, index:4,requesttype:REQUESTINFO.GOHOSPITALTARGET, result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.GOHOSPITALGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:8,requesttype:REQUESTINFO.HELPAGE, result:""},
    {
      type:"requestregion", 
      show:false, 
      index:9,
      step :3,
      info:"도움이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:10, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:11, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestrecipetranmitmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step:0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step:1,
      info:"요리전수받는 시기를 언제로 할까요?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.RECIPEDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step:2,
      info:"요리비법전수 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      recipeoptionselectitems:[
        {selected : false, key:"장보기 포함", request:"장보기 포함", response:"장보기 포함"},
        {selected : false, key:"장보기 미포함", request:"장보기 미포함", response:"장보기 미포함"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.RECIPEOPTION, result:""},
    {
      type:"requestregion", 
      show:false, 
      index:6,
      step:3,
      info:"요리비법 전수도움이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:7, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:8, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestschooleventmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step:0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step:1,
      info:"학교행사에 참석하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.GOSCHOOLEVENTDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step:2,
      info:"학교행사참석 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      schooleventselectitems:[
        {selected : false, key:"녹색어머니", request:"녹색어머니", response:"녹색어머니"},
        {selected : false, key:"공개수업참석", request:"공개수업참석", response:"공개수업참석"},
        {selected : false, key:"운동회참석", request:"운동회참석", response:"운동회참석"},
        {selected : false, key:"보호자요청", request:"보호자요청", response:"보호자요청"},
    ],
      helpgenderselectitems:[
      {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
      {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.TARGETMONEY,  result:""},  
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.GOSCHOOLEVENTCONTENT,  result:""},  
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPAGE, result:""},

    {
      type:"requestregion", 
      show:false, 
      index:8,
      step:3,
      info:"학교행사 참석이 필요한 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:9, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},

    {type:"requestcomplete", show: false, index:10, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestshoppingmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step : 0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step : 1,
      info:"장보는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.SHOPPINGDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step : 2,
      info:"장보는 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      shoppingcontentselectitems:[
        {selected : false, key:"동네마트", request:"동네마트", response:"동네마트"},
        {selected : false, key:"대형마트", request:"대형마트", response:"대형마트"},
        {selected : false, key:"이케아", request:"이케아", response:"이케아"},
        {selected : false, key:"코스트코", request:"코스트코", response:"코스트코"},
      ],
      helpgenderselectitems:[
      {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
      {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"20대", request:"20대", response:"20대"},
        {selected : false, key:"30대", request:"30대", response:"30대"},
        {selected : false, key:"40대", request:"40대", response:"40대"},
        {selected : false, key:"50대", request:"50대", response:"50대"},
        {selected : false, key:"60대", request:"60대", response:"60대"},]
      },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.TARGETMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:5, requesttype:REQUESTINFO.SHOPPINGCONTENT,  result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:7,requesttype:REQUESTINFO.HELPAGE, result:""},

  
    {
      type:"requestregion", 
      show:false, 
      index:8,
      step : 3,
      info:"장보는것이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:9, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
  
    {type:"requestcomplete", show: false, index:10, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestdoghospitalmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step:0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },
    {
      type:"requestdate", 
      show:false, 
      index:1,
      step:1,
      info:"애견돌봄 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.DOGHOSPITALDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step:2,
      info:"애견돌봄 일감에 관련한 내용을 선택해주세요",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      helpgenderselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"20대", request:"20대", response:"20대"},
          {selected : false, key:"30대", request:"30대", response:"30대"},
          {selected : false, key:"40대", request:"40대", response:"40대"},
          {selected : false, key:"50대", request:"50대", response:"50대"},
          {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.TARGETMONEY,  result:""},  
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPAGE, result:""},
 
    {
      type:"requestregion", 
      show:false, 
      index:7,
      step:3,
      info:"애견돌봐줄곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:8, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:9, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]
export const Requestdogwalkmessages =[

    {
     type:"initialize", 
     show:true, 
     index:0, 
     step:0,
     info:"등록이 완료되면 홍여사에게 지원을 받을수 있어요",
     selected: false,
     multiselect_enable: false,
     selectitems :[
       {selected : false, key:"",request:"", response:""}
     ]
    },

    {
      type:"requestdate", 
      show:false, 
      index:1,
      step:1,
      info:"애견산책하는 시기를 언제로 할까요 ?",
      selected : false,
    },
    
    {type:"response", responseshow : true, show:false, index:2, requesttype:REQUESTINFO.GODOGWALKDATE,  result:""},
    {
      type:"requesttarget", 
      show:false, 
      index:3, 
      step:2,
      info:"애견 산책금액을 얼마로 하기를 원하시나여?",
      selected : false,
      multiselect_enable:false, 
      moneyselectitems:[
        {selected : false, key:"3만원대", request:"3만원대", response:"3만원대"},
        {selected : false, key:"4만원대", request:"4만원대", response:"4만원대"},
        {selected : false, key:"5만원대", request:"5만원대", response:"5만원대"},
        {selected : false, key:"6만원대", request:"6만원대", response:"6만원대"},
        {selected : false, key:"협의필요", request:"협의필요", response:"협의필요"},
      ],
      helpgenderselectitems:[
        {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
        {selected : false, key:"여성", request:"여성", response:"여성"},],
      helpageselectitems:[
          {selected : false, key:"상관없음", request:"상관없음", response:"상관없음"},
          {selected : false, key:"20대", request:"20대", response:"20대"},
          {selected : false, key:"30대", request:"30대", response:"30대"},
          {selected : false, key:"40대", request:"40대", response:"40대"},
          {selected : false, key:"50대", request:"50대", response:"50대"},
          {selected : false, key:"60대", request:"60대", response:"60대"},]
    },
    {type:"response", responseshow : true, show:false, index:4, requesttype:REQUESTINFO.GODOGWALKMONEY,  result:""},
    {type:"response", responseshow : true, show:false, index:5,requesttype:REQUESTINFO.HELPGENDER, result:""},
    {type:"response", responseshow : true, show:false, index:6,requesttype:REQUESTINFO.HELPAGE, result:""},
 
    {
      type:"requestregion", 
      show:false, 
      index:7,
      step:3,
      info:"애견산책이 필요한곳의 지역을 클릭해주세요",
      selected : false,
    
    },
    {type:"response", responseshow : false,  show:false, index:8, requesttype:REQUESTINFO.CUSTOMERREGION, result:"", latitude:"", longitude:""},
    {type:"requestcomplete", show: false, index:9, info:"고객님이 작성하신 요구사항은 다음과 같습니다"},

  
]