import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { Row } from "../../common/Row";
import localforage from 'localforage';
import { readuserbydeviceid, updatealluserbydeviceid, Update_usertoken } from "../../service/UserService";


const Container = styled.div`
  height: 100vh;
  display : flex;
  justify-content:center;
  alignItems:center;
  width :100%;


`
const style = {
  display: "flex"
};
const SkipButton = styled.div`
  z-index: 10;
  width: 180px;
  position: absolute;
  height: 50px;
  background: #ff7e19;
  top: 80%;
  left: 87%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 20px;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCSplashcontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);

  console.log("TCL: PCSplashcontainer -> user", user);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{

  },[refresh])

  /**

   */
  useEffect(()=>{


    async function FetchData(){
      //Function 호출
      let userconfig = {};
      localforage.getItem('userconfig')
      .then(async function(value) {
        userconfig = value;
        console.log("TCL: FinalProcess2 -> userconfig", userconfig, value)
        if(value == null){
          navigate("/PCGate");
        }else{

          if(value.nickname='홍여사체험아이디'){
            navigate("/PCGate");  
            return;
          }

          console.log("TCL: _handleStart2 -> ", userconfig);
          const DEVICEID = userconfig.deviceid;
          const userdata = await readuserbydeviceid({DEVICEID});
          const TOKEN = user.token;
          if(TOKEN != ''){
            const usertoken = await Update_usertoken({DEVICEID, TOKEN });
          }
  
          if(userdata == -1){
            setLoading(false);
            setRefresh((refresh) => refresh +1);
            navigate("/PCPhone");
          }else{
  
         
            user.deviceid = userdata.DEVICEID;
            user.phone = userdata.USERINFO.phone
            user.nickname = userdata.USERINFO.nickname;
            user.users_id = userdata.USERS_ID;
            user.userimg = userdata.USERINFO.userimg;
            user.latitude = userdata.USERINFO.latitude;
            user.longitude = userdata.USERINFO.longitude;
            user.address_name = userdata.USERINFO.address_name;
            user.worker =userdata.USERINFO.worker;
    
            user.realname = userdata.USERINFO.realname;
            user.resume = userdata.USERINFO.resume;
   
  
            const latitude = user.latitude;
            const longitude = user.longitude;
  
            const checkdistance = user.radius;
  
            const workitems = await ReadWork({latitude, longitude,checkdistance});
            console.log("TCL: _handleStart -> ",workitems )
         
            const roomitems = await ReadRoom({latitude, longitude,checkdistance});
            console.log("TCL: _handleStart -> roomitems", roomitems)
   
  
            data.workitems = workitems;
            data.roomitems = roomitems;
            datadispatch(data);
  
            dispatch(user); // UserContext 에 address 정보 와 위치 정보, 토큰정보는 StartProcess와 초기 RN과 통신에서 이미 세팅 해둠
  
            const USERINFO = user;
            // 객체 저장
            localforage.setItem('userconfig', USERINFO).then(async function () {
              const DEVICEID = user.deviceid;
              const userupdate = await updatealluserbydeviceid({USERINFO, DEVICEID});
              
            }).catch(function (err) {
              console.error('Error saving userconfig:', err);
            });
            navigate("/PCmain");
          }
    
        }
  
      }).catch(function(err) {
      });
    }
  
    FetchData();


  }, [])




 
  return (
    <Row style={{height:"100vh"}}>
    { loading == true && <LottieAnimation  animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}
      />
    }
    </Row>
  );
}

export default PCSplashcontainer;

