import React, { useEffect, useState } from 'react';


import {motion} from 'framer-motion';




import { PiLockKeyLight } from "react-icons/pi"
import { sleep } from '../utility/common';

function RotateBasicExample({image, open, index}) {
  const [flip, setFlip] = useState(open);

  const toggleFlip = () => {
    setFlip(!flip);
  };

  useEffect(()=>{





  }, [])

  return (
    <div style={{ perspective: '1000px', margin:"3px 5px" }}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: flip ? 180 : 0 }}
        transition={{ duration: 1 }}
        style={{
          width: '70px',
          height: '70px',
          position: 'relative',
          transformStyle: 'preserve-3d', // 3D 회전 활성화
        }}
   
      >
        {/* Front Card */}
        <motion.div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#e2e2e2',
            backfaceVisibility: 'hidden', // 뒷면을 숨김
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: 'black',
            borderRadius: '10px',
          }}
        >
          <PiLockKeyLight size={22}/>
        </motion.div>

        {/* Back Card */}
        <motion.div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#ee903361',
            backfaceVisibility: 'hidden', // 뒷면을 숨김
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: 'black',
            borderRadius: '10px',
            transform: 'rotateY(180deg)', // 180도 회전하여 뒤집힘
          }}
        >
          <img src={image} style={{width:50}}/>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default RotateBasicExample;


