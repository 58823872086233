import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PCGatecontainer from "../../container/PCmain/PCGatecontainer";

import { UserContext } from "../../context/User";
import PCGateLayout from "../../screen/LayoutPC/Layout/PCGateLayout";
import { Helmet } from "react-helmet";



const Container = styled.div`

`
const style = {
  display: "flex"
};

const PCGatepage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);



 
  return (
    <>
    <Helmet>
    <title>구해줘 홍여사</title>
    <meta name="description" content="우리 동네의 홍여사. 일잘하는 동네 일꾼. 홍여사를 만나고 집안일 심부름 도움 받으세요" />
    <link rel="canonical" href="https://honglady.com/" />
    </Helmet>
    <PCGateLayout  height={120}>
    <PCGatecontainer/>
    </PCGateLayout>
  
    </>

  );

}

export default PCGatepage;

