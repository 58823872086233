
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";

import { Column } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourPicture } from "../service/LifeService";
import { TOURISTMENU } from "../utility/life";

import { LoadingCommunityStyle, LoadingLifeStyle, LoadingMainAnimationStyle } from "../screen/css/common";
import LazyImage from "../common/LasyImage";
import PictureBox from "./PictureBox";

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  height: 1500px;
  overflow-y : auto; 
  scrollbar-width: none;
  width:100%;
`
const style = {
  display: "flex"
};

const  SearchLayer = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  width:100%;
  background:#fff;
  position:sticky;
  top:0px;
`

const BoxItem = styled.div`
  padding: 20px;
  border: 1px solid #ededed;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.8;
  width:85%;
  font-family: "Pretendard-Light";


`
const Inputstyle={
  margin :"10px auto",
  border: '2px solid #ff7e10',
  background: '#fff',
  height: '20px',
  width:"77%",

}
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#f3f3f3",
  padding: "10px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '5px',
  color: '#333',
}

const Taglabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:16px;
  margin-right:10px;
`
const Item = styled.div`
  margin: 5px 0px;
`
const LocationText  = styled.div`
  color : #131313;
  font-size:16px;
`
const SearchText = styled.div`
color : #131313;
font-family:'Pretendard-Light';
font-size:14px;
`  

const ButtonLayer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
`

const FilterButton = styled.div`
  background-color: #fff;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  border: 1px solid #999;
  font-family: 'Pretendard-SemiBold';
`

const ItemLayer = styled.div`
  height: 100%;
  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

`

const PCLifeTourPicture =({containerStyle}) =>  {


/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);
  const [searching, setSearching] = useState(true);


  const [displayitems1, setDisplayitems1] = useState([]);
  const [displayitems2, setDisplayitems2] = useState([]);
  const [displayitems3, setDisplayitems3] = useState([]);


  const [popupstatus, setPopupstatus] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setDisplayitems1(displayitems1);
    setDisplayitems2(displayitems2);
    setDisplayitems3(displayitems3);

  }, [refresh])

  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }


  useEffect(()=>{
      async function FetchData(){

        let items1 = [];
        let items2 = [];
        let items3 = [];

        if(data.tourpictureitem.length == 0){
          const tourpictureitem = await ReadTourPicture();
          const dataToSavpicture = JSON.parse(tourpictureitem);
          data.tourpictureitem = dataToSavpicture.response.body.items.item;

          datadispatch(data);

          let totalitems = (shuffleArray(data.tourpictureitem));

          totalitems.map((data, index)=>{
            if(index % 3 == 0){
              items1.push(data);
            }
            else if(index % 3 ==1){
              items2.push(data);       
            }
            else if(index % 3 ==2){
              items3.push(data);
            }
          })

        }else{
          let totalitems = (shuffleArray(data.tourpictureitem));

          totalitems.map((data, index)=>{
            if(index % 3 == 0){
              items1.push(data);
            }
            else if(index % 3 ==1){
              items2.push(data);       
            }
            else if(index % 3 ==2){
              items3.push(data);
            }
          })
        }

        setDisplayitems1(items1);
        setDisplayitems2(items2);
        setDisplayitems3(items3);

        setSearching(false);
    
      
      }

      FetchData();
  }, [])

   /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);

  // const _handleView =(index)=>{
  //   navigate("/PCtourdetailpicture", {state:{index: index, items:displayitems, name:TOURISTMENU.TOURPICTURE}});
  // }

  // const _handleautoplay = (index) =>{
  //   navigate("/Mobiletourautopicture", {state:{index: index, items:displayitems, name:TOURISTMENU.TOURPICTURE}});
  // }
 
  return (

    <Container style={containerStyle}>      
      <Column>
        <Row style={{flexWrap:"wrap", width:"90%", margin: "0 auto"}}>   
        {
          searching == true  && <LottieAnimation containerStyle={LoadingLifeStyle} animationData={imageDB.loading}
          width={"100px"} height={'100px'} />
        }   
  
        <ItemLayer>
        {
          displayitems1.map((data, index)=>(
            <PictureBox data = {data}></PictureBox>
          ))
        }
        </ItemLayer>
        <ItemLayer>
        {
          displayitems2.map((data, index)=>(
            <PictureBox data = {data}></PictureBox>
          ))
        }
        </ItemLayer>
        <ItemLayer>
        {
          displayitems3.map((data, index)=>(
            <PictureBox data = {data}></PictureBox>
          ))
        }
        </ItemLayer>
        </Row>
 
      </Column>
    </Container>
  );

}

export default PCLifeTourPicture;

