import React, { Component, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { REFRESHTYPE, WORKNAME } from "../../utility/work";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Label from "../../common/Label";
import { GoNoEntry } from "react-icons/go";

import "./MobileMaincontainer.css";
import MobileStoreInfo from "../../components/MobileStoreInfo";
import { ROOMPOLICY, ROOMSIZE } from "../../utility/room";
import { ReadRoom } from "../../service/RoomService";
import PCRoomItem from "../../components/PCRoomItem";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { useSleep } from "../../utility/common";
import { FILTERNAME } from "../../utility/fitler";
import SlickSliderComponent from "../../common/Swipe";

import MobileServiceFilter from "../../modal/MobileServiceFilterPopup/MobileServiceFilter";
import MobilePriceFilter from "../../modal/MobilePriceFilterPopup/MobilePriceFilter";
import MobilePeriodFilter from "../../modal/MobilePeriodFilterPopup/MobilePeriodFilter";
import MobileDistanceFilter from "../../modal/MobileDistanceFilterPopup/MobileDistanceFilter";
import MobileProcessFilter from "../../modal/MobileProcessFilterPopup/MobileProcessFilter";
import MobileRoomServiceFilter from "../../modal/MobileRoomServiceFilterPopup/MobileRoomServiceFilter";
import Empty from "../../components/Empty";
import { GoPlus } from "react-icons/go";
import MobileRegistBanner from "../../components/MobileRegistBanner";

const Container = styled.div`
padding:50px 0px 0px 0px;
width: 100%;
margin : 0 auto;
height: calc(100vh - 50px);
scrollbar-width: none; // 스크롤바 안보이게 하기
overflow-x: hidden; /* X축 스크롤을 숨깁니다. */  
`
const SubContainer = styled.div`
  margin: 0 auto;
  background: #f9f9f9;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`

const style = {
  display: "flex"
};


// const Box = styled.div`
//   background : #f9f9f9;
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   flex-direction:column;
//   width: 29%;
//   background: #f9f9f9;
//   margin-right: 10px;
//   margin-bottom: 20px;
//   border: ${({clickstatus}) => clickstatus == true ? ('2px solid #ff0000') :('') };
//   border-radius: 15px;
//   padding : 5px 0px;
//   z-index:2;

// `

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  width: 25%;
  border-radius: 15px;
  padding : 5px 0px;


`
const BoxImg = styled.div`
  border-radius: 30px;
  background: ${({clickstatus}) => clickstatus == true ? ('#34313124') :('#fff') };
  padding: 3px 10px;
  display :flex;
`

const FilterBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: ${({clickstatus}) => clickstatus == true ? ('#FF7125') :('#fff') };
  border:  ${({clickstatus}) => clickstatus == true ? (null) :('1px solid #C3C3C3') };
  margin-top:10px;
  margin-right: 3px;

  border-radius: 4px;
  padding: 0px 15px;
  height:30px;
  flex: 0 0 auto; /* 아이템의 기본 크기 유지 */

`
const FilterBoxText = styled.div`
color: ${({clickstatus}) => clickstatus == true ? ('#FFF') :('#131313') };
font-size:14px;
margin-left:5px;
font-weight:600;

`


const InputLine = styled.div`
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


`

const StickyPos = styled.div`
  position: sticky;
  top:0px;

`


const Inputstyle ={

  background: '#FFF',
  width: '75%',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 20px 0px 20px',
  height : '40px',
  border : "1px solid #FF7125",
  position :"absolute"

}
const Searchstyle={
  position: "absolute",
  left: '15px'
}

const StickyElementStyle ={
  position: "sticky",
  top: "50px",
  height: '80px',
  background: "white",
  width: '100%', 
  marginBottom: '10px',
  zIndex :'10'
}
const SearchElementStyle ={

  height: '80px',
  background: "white",
  width: '100%', 
  marginBottom: '10px',
}
const RoomName = styled.div`
  font-size: 14px;
  color: #1A1A1A;
  font-weight: 500;
  background: ${({clickstatus}) => clickstatus == true ? ('#34313124') :('#fafafa') };
  border: 1px solid #E3E3E3;
  padding: 15px 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  font-family: 'Pretendard-SemiBold';
  height: 40px;
  margin-bottom: 10px;
  flex-direction: column;
  border-radius:10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07);

`
const RegistButton = styled.div`
  height: 45px;
  width: 45%;
  border-radius: 100px;
  background: #FF7e19;
  color: #fff;
  margin: 25px auto 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family:Pretendard-Bold;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
`



/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const RoomItems =[

  {name : ROOMSIZE.ONESIZE, img:imageDB.roomsize1, img2:imageDB.roomsize1},
  {name : ROOMSIZE.TWOSIZE, img:imageDB.roomsize2, img2:imageDB.roomsize2},
  {name : ROOMSIZE.THREESIZE, img:imageDB.roomsize3, img2:imageDB.roomsize3},
  {name : ROOMSIZE.FOURSIZE, img:imageDB.roomsize4, img2:imageDB.roomsize4},
  {name : ROOMSIZE.FIVESIZE, img:imageDB.roomsize4, img2:imageDB.roomsize4},
  {name : ROOMSIZE.SIXSIZE, img:imageDB.roomsize5, img2:imageDB.roomsize5},
  {name : ROOMSIZE.SEVENSIZE, img:imageDB.roomsize5, img2:imageDB.roomsize5},
  {name : ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize5, img2:imageDB.roomsize5},


]

const SmallRoomItems=[
  {name :ROOMSIZE.ONESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.TWOSIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},


]

const Small1RoomItems=[

  {name :ROOMSIZE.THREESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.FOURSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},


]


const LargeRoomItems=[

  {name :ROOMSIZE.FIVESIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},
  {name :ROOMSIZE.SIXSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},


]
const Large1RoomItems=[


  {name :ROOMSIZE.SEVENSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},
  {name :ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},

]


const FilterItems=[
  {name : FILTERNAME.INIT, img:imageDB.house, img2:imageDB.house},
  {name : FILTERNAME.SERVICE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.PRICE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.DISTNACE, img:imageDB.house, img2:imageDB.house},
  {name :FILTERNAME.PROCESS, img:imageDB.house, img2:imageDB.house},
]



const BannerItems =[
  {bgcolor :"#2c8ed5", image:imageDB.roomsize1,maintext:"공간대여의 핵심",subtext1:"에어비앤비 처럼 공간을 공유", buttontype:6},
  {bgcolor :"#ec8260", image:imageDB.roomsize2,maintext:"공간이 남아있다면",subtext1:"짐을 보관해주고", buttontype:7},
]

const LoadingAnimationStyle={
  zIndex: 11,
  position: "absolute",
  top: "40%",
  left: "35%"
}

const Lineup = styled.div`
  border-top-left-radius: 30px;
  z-index: 10;
  margin-top: -40px;
  background: #fff;
  border-top-right-radius: 30px;
  width:100%;


`

const NewBox = styled.div`
  width: 20%;
  height: ${({height}) => height}px;
  border: 1px solid rgb(237, 237, 237);
  margin: 20px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0,0,0,0.03), 
  0 2px 4px rgba(0,0,0,0.03), 
  0 4px 8px rgba(0,0,0,0.03), 
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03), 
  0 32px 64px rgba(0,0,0,0.03);

`

const ButtonSt = styled.div`
height: 44px;
width: 85%;
border-radius: 4px;
background: #f1f1f1;
color: #131313;
display: flex;
justify-content: center;
align-items: center;
border: none;
font-size: 18px;
margin: 0px auto;
font-family: "Pretendard-Regular";
cursor: pointer;
margin-bottom:10px;
cursor: pointer;
transition: transform 0.1s ease, box-shadow 0.1s ease;

&:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
}
`
const BoxHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  width :100%;
  padding: 15px 0px;
`




/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const MobileRoomcontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);
  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus1, setPopupstatus1] = useState(false);
  const [popupstatus2, setPopupstatus2] = useState(false);
  const [popupstatus3, setPopupstatus3] = useState(false);

  const [roomitems, setRoomitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [menu, setMenu] = useState('');

  const [showNewDiv, setShowNewDiv] = useState(false);
  const [width, setWidth] = useState(0);


  const [search, setSearch] = useState('');

  const elementRef = useRef(null);

  const [servicepopup, setServicepopup] = useState(false);
  const [pricepopup, setPricepoupup] = useState(false);
  const [periodpopup, setPeriodpopup] = useState(false);
  const [distancepopup, setDistancepopup] = useState(false);
  const [processpopup, setProcesspopup] = useState(false);

  const [servicefilter, setServicefilter] = useState([]);
  const [pricefilter, setPricefilter] = useState([]);
  const [periodfilter, setPeriodfilter] = useState([]);
  const [distancefilter, setDistancefilter] = useState([]);
  const [processfilter, setProcessfilter] = useState([]);

  const inputRef = useRef(null);
  

  useLayoutEffect(() => {
    setWidth(elementRef.current.offsetWidth -10);
    console.log("TCL: MobileRoomcontainer -> elementRef.current.offsetWidth", elementRef.current.offsetWidth)
  }, []);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setShowNewDiv(showNewDiv);
    setServicefilter(servicefilter);
    setPricefilter(pricefilter);
    setPeriodfilter(periodfilter);
    setDistancefilter(distancefilter);
    setProcessfilter(processfilter);

    setServicepopup(servicepopup);
    setPricepoupup(pricepopup);
    setPeriodpopup(periodpopup);
    setDistancepopup(distancepopup);
    setProcesspopup(processpopup);
  },[refresh])






  
  const _handlebasicmenuclick = async(checkmenu) =>{

    console.log("TCL: _handlemenuclick -> checkmenu", checkmenu);

    if(menu == checkmenu){
      setMenu("");
   
    }else{
      setMenu(checkmenu);
   
    }

    navigate("/Mobileregist",{state :{WORKTYPE :checkmenu, WORKTOTAL : ROOMPOLICY.ROOM}});

    setRefresh((refresh) => refresh +1);


    


  }

  const _handleroomselect= () =>{

    if(menu ==''){
      alert("공간대여 형태를 선택해주세여");
      return;
    }
    navigate("/Mobileregist",{state :{WORKTYPE :menu, WORKTOTAL : ROOMPOLICY.ROOM}});
  }

  
  return (
    <>

    {

      <div ref={elementRef}>
        <Container style={containerStyle}>
      
        <Column style={{marginBottom:5, width:"100%"}}>
        
        <Column style={{width:"100%", margin: "0 auto"}}>
            <SlickSliderComponent width={width}  items={BannerItems} bgcolor={'#78be21'}/>
          </Column>
          <Lineup>
            <Column style={{width:"100%", margin: "30px auto 30px"}}> 
              <BetweenRow style={{flexWrap:"wrap", width:"90%", margin:"0 auto"}}>
                {
                  RoomItems.map((data, index)=>(
                    <Box onClick={()=>{_handlebasicmenuclick(data.name)}} >  

                      <RoomName clickstatus={menu == data.name}>
                        <div style={{fontFamily:"Pretendard-SemiBold"}}>{data.name}</div>
                        <div style={{fontFamily:"Pretendard-SemiBold"}}>공간</div>
             
                      </RoomName>
                  
                    </Box>
                  ))
                }
              


              </BetweenRow>


              {/* <RegistButton onClick={_handleroomselect}><GoPlus size={24}/> 등록</RegistButton> */}

            </Column>

            <MobileRegistBanner main={'짐보관의 신뢰감을 줘보세요'} backgroundcolor={"#f8e0be"} sub1={'짐보관을 위해'} 
              sub2={'안전 봉인지를 보내드려요'} image={imageDB.logo}  imagewidth={'60px'} buttonType="2"  />



          </Lineup>

        </Column>

        <MobileStoreInfo height={200} containerStyle={{marginBottom:50}} />



        </Container>

    
      </div>

    }


    </>


  );

}

export default MobileRoomcontainer;

