import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Row } from "../../common/Row";
import { UserContext } from "../../context/User";
import { imageDB } from "../../utility/imageData";

import { RiArrowRightSLine } from "react-icons/ri";
import { PiBroom } from "react-icons/pi";
import { BiClinic } from "react-icons/bi";
import { VscCloseAll } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { BsClipboard } from "react-icons/bs";
import { VscBell } from "react-icons/vsc";
import { CiBellOn } from "react-icons/ci";
import { GrTransaction } from "react-icons/gr";
import { CiMedicalClipboard } from "react-icons/ci";
import { CiCreditCard1 } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { SlEvent } from "react-icons/sl";
import { CiCircleQuestion } from "react-icons/ci";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { MdOutlinePolicy } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { CONFIGMOVE } from "../../utility/screen";




const Container = styled.div`
  padding-top:55px;
  background-color : #f9f9f9;

`
const BoxItem = styled.div`

  background: #fff;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction : column;
  width: 85%;
  margin: 10px auto;
  border-radius: 10px;
  padding: 10px;
`
const Name = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 18px;
  padding-left:15px;
`
const ProfileConfigBtn = styled.div`
  background: #ff7e19;
  color: #fff;
  padding: 10px;
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  border-radius: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

`
const RegistHong = styled.div`
  border: 1px solid rgb(237, 237, 237);
  height: 70%;
  margin: 10px 0px;
  border-radius: 10px;
`
const RegistLayer = styled.div`
  height: 40px;
  background: #ff7e19;
  margin: 10px;
  border-radius: 10px;
  display:flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
  font-size:18px;
`
const RegistLayerContent = styled.div`
  color: #fff;
  font-family: 'Pretendard-SemiBold';

`
const RegistLayerComplete = styled.div`
  height: 40px;
  background: #256eff17;
  margin: 10px;
  border-radius: 10px;
  display:flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
`
const RegistLayerCompleteContent = styled.div`
  color: #2593ff;
  font-family: 'Pretendard-Bold';
`


const Label = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 18px;
  padding: 20px 10px;
`
const SubLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
`

const SubLabelContent = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 18px;
  padding: 20px 10px;
`


const MobileConfigcontainer =({containerStyle}) =>  {

  const { dispatch, user } = useContext(UserContext);
  console.log("TCL: MobileConfigcontainer -> user", user)
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])


  const _handleAttendance = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.ATTENDANCE, TYPE : ""}});
  }

  const _handleRegiserWork = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.REGISTERWORK, TYPE : ""}});   
  }

  const _handleRegisterRoom = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.REGISTERROOM, TYPE : ""}});   
  }

  const _handleSupportWork = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.SUPPORTWORK, TYPE : ""}});   
  }

  const _handleSupportRoom = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.SUPPORTROOM, TYPE : ""}});   
  }


  const _handleUselaw= () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.LAWPOLICY, TYPE : ""}});
  }

  const _handlePrivacylaw= () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.LAWPRIVACY, TYPE : ""}});
  }

  const _handleGpsLaw= () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.LAWGPS, TYPE : ""}});
  }

  const _handleWorkerInfo = ()=>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.WORKERINFO, TYPE : ""}});


  }

  const _handleProfileConfig = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.PROFILECONFIG, TYPE : ""}});  
  }

  const _handleMapConfig = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.MAPCONFIG, TYPE : ""}});
  }

  const _handleAlarmConfig = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.ALARMCONFIG, TYPE : ""}});
  }

  const _handlehongknow = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.HONGKNOW, TYPE : ""}});
  }
  const _handleroomknow = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.ROOMKNOW, TYPE : ""}});
  }

  const _handlecommunityknow = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.COMMUNITYKNOW, TYPE : ""}});
  }

  const _handleRullet = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.RULLET, TYPE : ""}});  
  }

  const _handleKakao = () =>{
    window.open('https://pf.kakao.com/_JAKqn', '_blank','width=400,height=600,resizable=yes,scrollbars=yes');
  }


 
  return (

    <Container style={containerStyle}>
        <BoxItem>
          <Row style={{justifyContent:"space-between", width:"100%"}}>
            <Row>
              <img src={user.userimg} style={{width:"30px", borderRadius:"30px", height:"30px"}}/>
              <Name>{user.nickname}</Name>
  
            </Row>   
            {/* <ProfileConfigBtn onClick={_handleProfileConfig}>프로필 보기</ProfileConfigBtn>
       */}
          </Row>

          <RegistHong>
            <Row style={{justifyContent:"space-between", width:"90%", padding:"20px 10px 10px"}}>
              {/* <img src={imageDB.logo2} style={{width:"35px", height:"35px"}}/> */}
              <div style={{fontFamily:"Pretendard-Regular", fontSize:16, marginLeft:10., width:250}}>홍여사 일꾼 등록을 하면 모든 일감에 지원할수가 있습니다</div>
            </Row>

            {
              user.worker == false ? (<RegistLayer  onClick={_handleWorkerInfo}>
              <RegistLayerContent>홍여사 일꾼 등록하러 가기
              </RegistLayerContent>
              <RiArrowRightSLine size={20} color={'#ff7125'}/>
              </RegistLayer>):(<RegistLayerComplete>
              <RegistLayerCompleteContent>홍여사 일꾼으로 등록 되었습니다
              </RegistLayerCompleteContent>
              </RegistLayerComplete>)
            }
       
          </RegistHong>
        
        
        </BoxItem>


        <BoxItem>
          <Label>홍여사 활동내역</Label>



            {/* <SubLabel onClick={_handleAttendance}>
              <Row>
                <SlEvent/>
                <SubLabelContent >출석 체크</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
            </SubLabel> */}
            
          
            <SubLabel onClick={_handleRegiserWork}>
              <Row>
                <PiBroom/>
                <SubLabelContent>내가 등록한 일감 </SubLabelContent>
              </Row>     
              <RiArrowRightSLine size={24} style={{paddingRight:5}}/>
            </SubLabel>
            {/* <SubLabel onClick={_handleSupportWork}>
              <Row>
                <PiBroom/>
                <SubLabelContent>내가 지원한 일감 </SubLabelContent>
              </Row>     
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel> */}
            <SubLabel onClick={_handleRegisterRoom}>
              <Row>
                <BiClinic/>
                <SubLabelContent>내가 등록한 공간대여</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={24} style={{paddingRight:5}}/>
            </SubLabel>

            {/* <SubLabel onClick={_handleSupportRoom}>
              <Row>
                <BiClinic/>
                <SubLabelContent>내가 필요한 공간대여</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel>  */}
         
        </BoxItem>

        <BoxItem>
          <Label>나의 설정</Label>
          <SubLabel onClick={_handleMapConfig}>
              <Row>
                <CiBellOn/>
                <SubLabelContent>나의 범위설정 </SubLabelContent>
              </Row>
              <RiArrowRightSLine size={24} style={{paddingRight:5}}/>
            </SubLabel>


            <SubLabel onClick={_handleAlarmConfig}>
              <Row>
                <CiBellOn/>
                <SubLabelContent>실시간 알림설정 </SubLabelContent>
              </Row>
              <RiArrowRightSLine size={24} style={{paddingRight:5}}/>
            </SubLabel>
        </BoxItem>

        <BoxItem>
          <Label>결재 입금관리</Label>

          <SubLabel>
            <Row>
              <CiCreditCard1/>
              <SubLabelContent>결재관리</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel>
            <Row>
              <CiBank/>
              <SubLabelContent>입금관리</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

        </BoxItem>



{/* 
        <BoxItem>
          <Label>홍여사 소식</Label>

          <SubLabel>
            <Row>
              <CiBellOn/>
              <SubLabelContent>홍여사 알림</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel>
            <Row>
              <CiMedicalClipboard/>
              <SubLabelContent>공지 사항</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>
        </BoxItem> */}

        <BoxItem>
           <Label>기타</Label>



           {/* <SubLabel onClick={_handleRullet}>
            <Row>
              <BiClinic/>
              <SubLabelContent>룰렛이벤트</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel> */}


           <SubLabel onClick={_handleKakao}>
            <Row >
              <BiClinic/>
              <SubLabelContent>고객센타</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>

  


          <SubLabel onClick={_handlehongknow}>
            <Row>
              <VscWorkspaceUnknown/>
              <SubLabelContent>홍여사 알아보기</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel onClick={_handleroomknow}>
            <Row>
              <VscWorkspaceUnknown/>
              <SubLabelContent>공간대여 알아보기</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>


          {/* <SubLabel onClick={_handlecommunityknow}>
            <Row>
              <VscWorkspaceUnknown/>
              <SubLabelContent>커뮤니티 알아보기</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel> */}

   
        </BoxItem>

        <BoxItem>
          <Label>약관 및 정책</Label>

          <SubLabel onClick={_handleUselaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>결재 및 환불 규정</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel onClick={_handleUselaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>이용약관</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel onClick={_handlePrivacylaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>개인정보 처리지침</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>


          <SubLabel onClick={_handleGpsLaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>위치정보기반 수집동의 규정</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={24} style={{paddingRight:5}}/>    
          </SubLabel>
     
        </BoxItem>
        <div style={{height:80}}></div>

    </Container>
  );

}

export default MobileConfigcontainer;

