
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



import MobileLadyLicenseAuthcontainer from "../../container/main/MobileLadyLicenseAuthcontainer";
import PCLadyLicenseAuthcontainer from "../../container/PCmain/PCLadyLicenseAuthcontainer";
import MobileCommunityLayout from "../../screen/Layout/Layout/MobileCommunityLayout";
import { MOBILEMAINMENU } from "../../utility/screen";


const PCLadyLicenseAuthpage = () => {

  const location = useLocation();


  return (

      <PCLadyLicenseAuthcontainer USER= {location.state.USER} USERJUMIN = {location.state.USERJUMIN}/>

  );
};

export default PCLadyLicenseAuthpage;
