import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import MobileChatcontainer from "../../container/main/MobileChatcontainer";
import MobileContactWritecontainer from "../../container/main/MobileContactWritecontainer";
import MobileContentcontainer from "../../container/main/MobileContentcontainer";

import PCChatcontainer from "../../container/PCmain/PCChatcontainer";
import PCContactWritecontainer from "../../container/PCmain/PCContactWritecontainer";

import { UserContext } from "../../context/User";

import MobileCommunityLayout from "../../screen/Layout/Layout/MobileCommunityLayout";
import PCChatLayout from "../../screen/LayoutPC/Layout/PCChatLayout";
import PCContactLayout from "../../screen/LayoutPC/Layout/PCContactLayout";

import PCLayout from "../../screen/LayoutPC/Layout/PCLayout";
import { MOBILEMAINMENU, PCMAINMENU } from "../../utility/screen";

const Container = styled.div`

`
const style = {
  display: "flex"
};

const PCContactWritepage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])

 
  return (

    <PCContactLayout name={'용역 계약서'} type={MOBILEMAINMENU.CHATMENU}>
        <PCContactWritecontainer ID ={location.state.ID}
         CHAT_ID ={location.state.CHAT_ID}
         messages={location.state.messages}
         OWNER_ID={location.state.OWNER_ID}
         SUPPORTER_ID = {location.state.SUPPORTER_ID}
         NAME={location.state.NAME}
        
        />
    </PCContactLayout>

  )

}

export default PCContactWritepage;

