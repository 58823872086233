import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Row } from "../../common/Row";
import { UserContext } from "../../context/User";
import { imageDB } from "../../utility/imageData";

import { RiArrowRightSLine } from "react-icons/ri";
import { PiBroom } from "react-icons/pi";
import { BiClinic } from "react-icons/bi";
import { VscCloseAll } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { BsClipboard } from "react-icons/bs";
import { VscBell } from "react-icons/vsc";
import { CiBellOn } from "react-icons/ci";
import { GrTransaction } from "react-icons/gr";
import { CiMedicalClipboard } from "react-icons/ci";
import { CiCreditCard1 } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { SlEvent } from "react-icons/sl";
import { CiCircleQuestion } from "react-icons/ci";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { MdOutlinePolicy } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { CONFIGMOVE } from "../../utility/screen";
import localforage from 'localforage';
import { Column } from "../../common/Column";


import { Helmet } from 'react-helmet';


const Container = styled.div`
  background-color : #f9f9f9;
  width:100%;
  height:100%;
  overflow-y: scroll; 
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, #000000 1px, transparent 1px);
  background-size: 50px 50px;
`
const BoxItem = styled.div`

  background: #fff;
  color: rgb(2 2 2);
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10px auto;
  border-radius: 10px;
  padding: 20px;
`
const Name = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding-left:5px;
`
const ProfileConfigBtn = styled.div`
  background: #f9f9f9;
  padding: 10px;
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
`
const RegistHong = styled.div`
  border: 1px solid rgb(237, 237, 237);
  height: 70%;
  margin: 10px 0px;
  border-radius: 10px;
`
const RegistLayer = styled.div`
  height: 45px;
  background: #ff712517;
  margin: 10px;
  border-radius: 10px;
  display:flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
`
const RegistLayerContent = styled.div`
  color: #ff7125;
  font-weight: 900;
  font-family: 'Pretendard-Bold';
`
const RegistLayerComplete = styled.div`
  height: 45px;
  background: #256eff17;
  margin: 10px;
  border-radius: 10px;
  display:flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
`
const RegistLayerCompleteContent = styled.div`
  color: #2593ff;
  font-weight: 900;
  font-family: 'Pretendard-Bold';
`

const Label = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding: 20px 10px;
`
const SubLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
`

const SubLabelContent = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding: 20px 10px;
`


const PCConfigcontainer =({containerStyle}) =>  {

  const { dispatch, user } = useContext(UserContext);
  console.log("TCL: MobileConfigcontainer -> user", user)
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])


  const _handleEventView = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.EVENTVIEW, TYPE : ""}});
  }


  const _handleUselaw= () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.LAWPOLICY, TYPE : ""}});
  }

  const _handlePrivacylaw= () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.LAWPRIVACY, TYPE : ""}});
  }

  const _handleGpsLaw= () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.LAWGPS, TYPE : ""}});
  }

  const _handleWorkerInfo = ()=>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.WORKERINFO, TYPE : ""}});
  }

  const _handleProfileConfig = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.PROFILECONFIG, TYPE : ""}});  
  }

  const _handleMapConfig = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.MAPCONFIG, TYPE : ""}});
  }

  const _handleAlarmConfig = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.ALARMCONFIG, TYPE : ""}});
  }

  const _handleRegiserWork = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.REGISTERWORK, TYPE : ""}});   
  }

  const _handleRegisterRoom = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.REGISTERROOM, TYPE : ""}});   
  }

  const _handleSupportWork = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.SUPPORTWORK, TYPE : ""}});   
  }

  const _handleSupportRoom = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.SUPPORTROOM, TYPE : ""}});   
  }


  const _handleKakao = () =>{
    window.open('https://pf.kakao.com/_JAKqn', '_blank','width=400,height=600,resizable=yes,scrollbars=yes');
  }
  const _handlelogout = async() =>{
    await localforage.removeItem('userconfig');
    navigate("/");
  }
 
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://honglady.com/PCRoom",
    "name": "구해줘 홍여사 내정보보기",
 
  };

  return (

    <Container style={containerStyle}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <Column style={{width:"30%", margin:"0 auto", background:"#f7f7f7"}}>
        <BoxItem style={{marginTop:40}}>
          <Row style={{justifyContent:"space-between", width:"100%"}}>
            <Row>
              <img src={user.userimg} style={{width:"50px", borderRadius:"50px", height:"50px"}}/>
              <Name>{user.nickname}</Name>
            </Row>   
            <Row>
              <ProfileConfigBtn  onClick={_handleProfileConfig}>프로필 보기</ProfileConfigBtn>
              <ProfileConfigBtn style={{marginLeft:5}} onClick={_handlelogout}>로그아웃</ProfileConfigBtn>
            </Row>
          
      
          </Row>

          <RegistHong>
            <Row style={{justifyContent:"space-between", width:"90%", padding:"20px 10px 10px"}}>
              <img src={imageDB.logo2} style={{width:"35px", height:"35px"}}/>
              <div style={{fontFamily:"Pretendard-Light", fontSize:14, marginLeft:20}}>홍여사 일꾼 등록을 하면 모든 일감에 지원할수가 있습니다</div>
            </Row>
            {
              user.worker == false ? (<RegistLayer  onClick={_handleWorkerInfo}>
              <RegistLayerContent>홍여사 일꾼 등록하러 가기
              </RegistLayerContent>
              <RiArrowRightSLine size={20} color={'#ff7125'}/>
              </RegistLayer>):(<RegistLayerComplete>
              <RegistLayerCompleteContent>홍여사 일꾼으로 등록 되었습니다
              </RegistLayerCompleteContent>
              </RegistLayerComplete>)
            }
          </RegistHong>
        
        
        </BoxItem>
        <BoxItem>
          <Label>홍여사 활동내역</Label>
          
            <SubLabel onClick={_handleRegiserWork}>
              <Row>
                <PiBroom/>
                <SubLabelContent>내가 등록한 일감 관리 </SubLabelContent>
              </Row>     
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel>

            {/* <SubLabel onClick={_handleSupportWork}>
              <Row>
                <PiBroom/>
                <SubLabelContent>내가 지원한 일감 </SubLabelContent>
              </Row>     
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel> */}

            <SubLabel onClick={_handleRegisterRoom}>
              <Row>
                <BiClinic/>
                <SubLabelContent>내가 등록한 공간대여 관리</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel> 


            <SubLabel onClick={_handleRegisterRoom}>
              <Row>
                <BiClinic/>
                <SubLabelContent>내가 쓴 게시물 관리</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel> 

            {/* <SubLabel onClick={_handleSupportRoom}>
              <Row>
                <BiClinic/>
                <SubLabelContent>내가 필요한 공간대여</SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel>  */}
        </BoxItem>
        <BoxItem>
          <Label>나의 설정</Label>
          <SubLabel onClick={_handleMapConfig}>
              <Row>
                <CiBellOn/>
                <SubLabelContent>나의 범위설정 </SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel>


            <SubLabel onClick={_handleAlarmConfig}>
              <Row>
                <CiBellOn/>
                <SubLabelContent>실시간 알림설정 </SubLabelContent>
              </Row>
              <RiArrowRightSLine size={20} style={{paddingRight:5}}/>
            </SubLabel>
        </BoxItem>
        <BoxItem>
          <Label>결재 입금관리</Label>

          <SubLabel>
            <Row>
              <CiCreditCard1/>
              <SubLabelContent>결재관리</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel>
            <Row>
              <CiBank/>
              <SubLabelContent>입금관리</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel>
            <Row>
              <CiBank/>
              <SubLabelContent>포인트관리</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

        </BoxItem>
        <BoxItem>
           <Label>기타</Label>
           <SubLabel onClick={_handleKakao}>
            <Row >
              <BiClinic/>
              <SubLabelContent>고객센타</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>
        </BoxItem>
        <BoxItem>
          <Label>약관 및 정책</Label>

     

          <SubLabel onClick={_handleUselaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>이용약관</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>

          <SubLabel onClick={_handlePrivacylaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>개인정보 처리지침</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>


          <SubLabel onClick={_handleGpsLaw}>
            <Row>
              <MdOutlinePolicy/>
              <SubLabelContent>위치정보기반 수집동의 규정</SubLabelContent>
            </Row>
            <RiArrowRightSLine size={20} style={{paddingRight:5}}/>    
          </SubLabel>
     
        </BoxItem>
        <div style={{height:20}}></div>
      </Column>
    </Container>
  );

}

export default PCConfigcontainer;

