import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { CENTERTYPE, CONFIGMOVE, LAWTYPE } from "../utility/screen";




const TRANSPARENT = 'transparent';

const Container = styled.div`
    background-color : #F2F3F4;
    height: ${({height}) =>height}px;
    padding: 20px 30px;
    margin-bottom:30px;
`

const StoreNameView = styled.div`
   display:flex;
`
const StoreNameText = styled.span`
    font-size: 25px;
    font-family : ${({theme}) =>theme.BOLD};
`

const StoreLineView = styled.div`
    background-color : #EAEBED;
    height :1px;
`
const StoreButtonView = styled.div`
    display:flex;
    flex-direction : row;
    justify-content : flex-start;
    align-items : flex-start;
    margin-top:10px;
    flex-wrap:wrap;
`
const StoreButton = styled.div`
    margin-right: 5px;
`
const StoreButtonText = styled.span`
    font-size: 12px;
    font-family : ${({theme}) =>theme.REGULAR};
`
const StoreBusinessView = styled.div`
    display:flex;
    margin-top:20px;
`
const StoreBusinessText = styled.span`
    font-size: 16px;
    font-family : ${({theme}) =>theme.REGULAR};
`
const StoreBusinessInfoView = styled.div`
    display:flex;
    margin-bottom:10px;
    margin-top:10px;
`
const StoreBusinessInfoText = styled.span`
    font-size: 12px;
    font-family : ${({theme}) =>theme.REGULAR};
    color :#ACACAC;
    text-align: left;
`



const MobileStoreInfo  = ({containerStyle, height})=>{

    const navigation = useNavigate();

    const _handleUSEPolicy =() =>{
        navigation("/MobilePolicy" ,{state :{LAWTYPE :LAWTYPE.USELAW}});
    }
    const _handlePRIVACYPolicy =() =>{
        navigation("/MobilePolicy" ,{state :{LAWTYPE :LAWTYPE.PRIVACYLAW}});
    }

    const _handleGPSPolicy =() =>{
        navigation("/MobilePolicy" ,{state :{LAWTYPE :LAWTYPE.GPSLAW}});
    }

    const _handleKako = () =>{
        window.open('https://pf.kakao.com/_JAKqn', '_blank','width=400,height=600,resizable=yes,scrollbars=yes');
      }
    const _handleHongKnow = () =>{
        navigation("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.HONGKNOW, TYPE : ""}});
    }
    const _handleRoomKnow = () =>{
        navigation("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.ROOMKNOW, TYPE : ""}});
    }

    return(
        <Container style={containerStyle} height={ height}>
            <StoreNameView>
                <StoreNameText>구해줘 홍여사</StoreNameText>
            </StoreNameView>


            <StoreButtonView>
                <StoreButton onClick={_handleUSEPolicy}>
                    <StoreButtonText>이용약관</StoreButtonText>
                </StoreButton>
                <StoreButton onClick={_handlePRIVACYPolicy}>
                    <StoreButtonText>개인정보 처리방침</StoreButtonText>
                </StoreButton>
                <StoreButton onClick={_handleGPSPolicy}>
                    <StoreButtonText>위치정보기반 수집동의 규정</StoreButtonText>
                </StoreButton>
                <StoreButton onClick={_handleKako}>
                    <StoreButtonText>고객센타</StoreButtonText>
                </StoreButton>
                <StoreButton onClick={_handleHongKnow}>
                    <StoreButtonText>구해줘 홍여사 알아보기</StoreButtonText>
                </StoreButton>

                <StoreButton onClick={_handleRoomKnow}>
                    <StoreButtonText>공간대여 알아보기</StoreButtonText>
                </StoreButton>

           
            </StoreButtonView>

            <StoreBusinessView>
                    <StoreBusinessText style={{fontSize:12}}>(주)홍컴즈 사업자등록번호 : 480-86-03245 통신판매업 신고번호 : 2024-다산-0881  </StoreBusinessText>
                
            </StoreBusinessView>
            <StoreBusinessInfoView>
                <StoreBusinessInfoText>
                (주)홍컴즈은 통신판매중개자이며, 통신판매의 당사자가 아닙니다.
                따라서, 상품의 예약, 이용 및 환불 등과 관련한 책임을 지지 않습니다.
                </StoreBusinessInfoText>
            </StoreBusinessInfoView>
    
        </Container>
    );
}



MobileStoreInfo.propTypes = {
    containerStyle : PropTypes.object,
}

MobileStoreInfo.defaultProps ={
    height : 200,
}
export default MobileStoreInfo;
