import { db, auth, storage, firebaseConfig, firebaseApp } from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy, arrayUnion } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { COMMUNITYSTATUS, WORKSTATUS } from '../utility/status';
import randomLocation from 'random-location'
import { useSleep } from '../utility/common';
import Axios from 'axios';
import { CHATCONTENTTYPE } from '../utility/screen';
const authService = getAuth(firebaseApp);


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;




/**
 * CHAT 관련 서비스
 *! Create 
 * ① CreateChat : 

 *! Read

 *! Update
 
 *! Delete

 */


export const CreateChat = async({OWNER, OWNER_ID, SUPPORTER, SUPPORTER_ID,INFO,TYPE}) =>{

    let success = true;
    const CHATREF = doc(collection(db, "CHAT"));
    const id = CHATREF.id;

    try{
       const newdata = {
           CHAT_ID : id,
           OWNER : OWNER,
           OWNER_ID :OWNER_ID,
           SUPPORTER : SUPPORTER,
           SUPPORTER_ID :SUPPORTER_ID,
           INFO : INFO,
           TYPE : TYPE,
           CREATEDT : Date.now(),
       }
       await setDoc(CHATREF, newdata);
    
    }catch(e){
      console.log("TCL: Create chat -> error ",e.message )
       
        alert( e.message);
        success =false;
        return -1;
    }finally{
      return id;
    }
}


export const ReadDocChat = async({DOC}) =>{


  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, `CHAT/${DOC}/messages`);
    const q = query(userRef, orderBy('CREATEDAT',"asc"));
  

    let chatitem = [];
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
    
        chatitem.push(doc.data());
 
      });
  
      if (chatitem.length != 0) {
        resolve(chatitem);
      }else{
        resolve(-1);
      }
    } catch (e) {
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }
  });
}
export const UpdateDocChat= async({DOC, MESSAGE_ID, USERS_ID}) =>{

  const chatRef = collection(db, DOC);
  console.log("TCL: UpdateDocChat -> DOC", DOC, MESSAGE_ID);

  const rows = query(chatRef, where("MESSAGE_ID",'==', MESSAGE_ID ));

  let docid = "";
  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {

          docid = doc.id;
          updateDoc(doc.ref, {
            READ  : arrayUnion(USERS_ID),
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return docid;
  }
}

export const ReadChat = ({USERS_ID}) =>{


  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "CHAT");
    const q = query(userRef, orderBy('CREATEDT',"desc"));
  
    let chatitems = [];
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];

  
      
        if((doc.data().OWNER_ID == USERS_ID) || (doc.data().SUPPORTER_ID == USERS_ID)){

          const DOC = doc.id;
          data = doc.data();
          data["DOC"] = DOC;
          chatitems.push(data);

         
          const items = await ReadDocChat({DOC});
         
      
          let unReadcount = 0;

          if(items != -1){
            items.map((sub)=>{
              if(sub.READ.length ==1){
                unReadcount++;
              }
            })
            data["message"] = items[items.length -1];
            data["unReadcount"] = unReadcount;
          }else{
            data["message"] = -1;
            data["unReadcount"] = unReadcount;
          }

  
        }
     


      });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      if (chatitems.length > 0) {
          resolve(chatitems);
      }else{
        resolve([]);
      }
  
    }catch (e){
      console.log("error", e.message);
      resolve([]);
    } finally {

    }
  });
}


export const ReadChatReview = ({USERS_ID}) =>{


  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "CHAT");
    const q = query(userRef, orderBy('CREATEDT',"desc"));
  
    let chatitems = [];
    let reviewitems = [];
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];

  
      
        if( (doc.data().SUPPORTER_ID == USERS_ID)){

          const DOC = doc.id;
          data = doc.data();
          data["DOC"] = DOC;
          chatitems.push(data);
         
          const items = await ReadDocChat({DOC});
          if(items != -1){
          console.log("TCL: ReadChatReview -> items", items)
   
            const FindIndex = items.findIndex(x=>x.CHAT_CONTENT_TYPE == CHATCONTENTTYPE.REVIEW);

            if(FindIndex != -1){
              data["REVIEWITEMS"] = items[FindIndex];
            }

          }
  
        }
     


      });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      if (chatitems.length > 0) {
          resolve(chatitems);
      }else{
        resolve([]);
      }
  
    }catch (e){
      console.log("error", e.message);
      resolve([]);
    } finally {

    }
  });
}



export const ReadChatByCHATID = async({CHAT_ID}) =>{
 
  
    return new Promise(async (resolve, reject) => {
      const userRef = collection(db, "CHAT");
      const q = query(userRef, where('CHAT_ID',"==", CHAT_ID));
    
  
      let chatitem = {};
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
    
            chatitem = doc.data();
  
        });
    
        if (chatitem.CHAT_ID != undefined) {
          resolve(chatitem);
        }else{
          resolve(-1);
        }
      } catch (e) {
        console.log("error", e.message);
        resolve(-1);
      } finally {
  
      }
    });
  }
export const ReadChannel = async({CHAT_ID}) =>{

  return new Promise(async (resolve, reject) => {
    const chatRef = collection(db, `CHAT/${CHAT_ID}/messages`);
    const q = query(chatRef, orderBy('CREATEDT',"asc"));
  
    let chatitems = [];
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
         chatitems.push(doc.data());
      });
  
      if (chatitems.length > 0) {
        resolve(chatitems);
      }else{
        resolve(-1);
      }
    } catch (e) {
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }

  });
  

}



export const CreateMessage = async ({ CHAT_ID, msg, users_id,read,CHAT_CONTENT_TYPE }) => {

  console.log("TCL: CreateMessage -> data", msg,users_id,read)

  
  const messageRef = doc(collection(db, `CHAT/${CHAT_ID}/messages`));
  const id = messageRef.id;
  const newMessage = {
    MESSAGE_ID: id,
    TEXT: msg,
    CREATEDAT: Date.now(),
    USERS_ID: users_id,
    READ:read,
    CHAT_CONTENT_TYPE: CHAT_CONTENT_TYPE
  };

  try {
    await setDoc(messageRef, newMessage);
  } catch (e) {
    console.log("error", e.message);
  }
};


export const CreateMessageEx = async ({ CHAT_ID, msgitems, users_id,read,CHAT_CONTENT_TYPE, RESULTITEM }) => {

  const messageRef = doc(collection(db, `CHAT/${CHAT_ID}/messages`));
  const id = messageRef.id;
  const newMessage = {
    MESSAGE_ID: id,
    TEXT: msgitems,
    CREATEDAT: Date.now(),
    USERS_ID: users_id,
    READ:read,
    RESULTITEM : RESULTITEM,
    CHAT_CONTENT_TYPE: CHAT_CONTENT_TYPE
  };

  try {
    await setDoc(messageRef, newMessage);
  } catch (e) {
    console.log("error", e.message);
  }
};
export const CreateMessageEx2 = async ({ CHAT_ID, msgitems, users_id,read,CHAT_CONTENT_TYPE }) => {

  
  const messageRef = doc(collection(db, `CHAT/${CHAT_ID}/messages`));
  const id = messageRef.id;
  const newMessage = {
    MESSAGE_ID: id,
    TEXT: msgitems,
    CREATEDAT: Date.now(),
    USERS_ID: users_id,
    READ:read,
    CHAT_CONTENT_TYPE: CHAT_CONTENT_TYPE
  };

  try {
    await setDoc(messageRef, newMessage);
  } catch (e) {
    console.log("error", e.message);
  }
};
export const CreateMessageEx3 = async ({ CHAT_ID,msgitems, resultitems, users_id,read,CHAT_CONTENT_TYPE }) => {

  
  const messageRef = doc(collection(db, `CHAT/${CHAT_ID}/messages`));
  const id = messageRef.id;
  const newMessage = {
    MESSAGE_ID: id,
    RESULTITEMS: resultitems,
    TEXT: msgitems,
    CREATEDAT: Date.now(),
    USERS_ID: users_id,
    READ:read,
    CHAT_CONTENT_TYPE: CHAT_CONTENT_TYPE
  };

  try {
    await setDoc(messageRef, newMessage);
  } catch (e) {
    console.log("error", e.message);
  }
};

