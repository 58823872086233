import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { sleep, useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { BetweenRow, Row } from "../../common/Row";
import { readuserbyphone, Update_usertoken } from "../../service/UserService";
import localforage from 'localforage';
import { Column } from "../../common/Column";
import ButtonEx from "../../common/ButtonEx";
import StoreInfo from "../../components/StoreInfo";
import { Helmet } from "react-helmet";

const Container = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const style = {
  display: "flex"
};

const ButtonLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : center;

`
const SkipButton = styled.div`
  z-index: 10;
  width: 150px;

  height: 50px;
  background: #131313;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const LoginButton = styled.div`
  z-index: 10;
  width: 200px;

  height: 50px;
  background: #ff7e19;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const MainItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

`

const HighLight = styled.div`
  font-size: 20px;
  color: rgb(255, 126, 25);
  font-family: Pretendard-SemiBold;
  margin-bottom: 15px;

`

const  MatchingItem = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction : row;

`
const MatchingItemData = styled.div`
  font-size: 28px;
  line-height:48px;
  color : #c2c1c1db;
`
const MatchingItemSubData = styled.div`
  font-size: 24px;
  line-height:48px;
  color : #c2c1c1db;
`
const Tag = styled.div`

  background: #ff7e19;
  color: #fff;
  padding: 10px 20px;
  font-size: 30px;
  border-radius: 5px;
  margin-left:5px;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCGatecontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);



  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);

  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleCanPlay = () => {
    setIsVideoReady(true);
    console.log('비디오가 재생 준비되었습니다.');
  };

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{

  },[refresh])

  /**

   */
  useEffect(()=>{
  }, [])




  // FFFAE0
  return (

    <>
        <Container style={containerStyle}>
          <div style={{height:"600px", backgroundColor:"#e0f2ff", width:"100%"}}>
   
            <Row style={{height:"100%"}}>
              <Column style={{width:"60%",height:"100%", height:"100%", paddingTop:20}}>
                  <div style={{fontSize:43, fontFamily:"Pretendard-Bold"}}>
                    <div>당신 근처의</div>
                    <div>가장 일잘하는 동네 일꾼</div>
                  </div>


                  <div style={{fontSize:18, fontFamily:"Pretendard-Regular", color:"#7c7c7c",
                    lineHeight: "1.8",
                    marginTop: "20px"}}>
                    <div>이제 집안일은 동네에서 맡기세요</div>
                    <div>구해줘 홍여사는 가장 믿음직스러운 일꾼을 찾아 드립니다</div>
                  </div>

                  <Row style={{width:"45%", marginTop:30}}>
                    <ButtonEx text={'Google Play'} width={'85'} containerStyle={{height:"64px", borderRadius:"10px", fontSize:24}}  onPress={()=>{}} bgcolor={'#FF7125'} color={'#fff'} />
                    <ButtonEx text={'App Store'} width={'85'} containerStyle={{height:"64px", marginLeft:"10px", borderRadius:"10px", fontSize:24}}  onPress={()=>{}} bgcolor={'#FF7125'} color={'#fff'} />
                  </Row>
              </Column>
              <div style={{width:"40%"}}>
                  <img src={imageDB.logo}  style={{width:"300px"}}/>
              </div>
            </Row>


          </div>


          <Row style={{margin:"100px 0px", width:"80%"}}>

          <div style={{paddingLeft:30}}>
              <HighLight>구해줘 홍여사 소개</HighLight>

              <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>
                <div>믿을만한 홍여사</div>
                <div>실시간 견적 문의</div>
                <div>쉬운 요청</div>
              </div>

              <div style={{fontSize:18, fontFamily:"Pretendard-Regular", color:"#7c7c7c",
                lineHeight: "1.8",
                marginTop: "20px"}}>
                <div>구해줘 홍여사의 인증 과정을 거친 </div>
                <div>홍여사와 실시간 채팅을 통해 계약, 내주변 주위의 일감 확인</div>
                <div>일 완료후 지급되는 ESCRO 시스템</div>
              </div>
            </div>
            <video class="background-video"   controls loop onCanPlay={handleCanPlay}>
              <source src={imageDB.introduce2}  type="video/mp4" />
            </video>
          </Row>

          <div style={{height:"600px", width:"100%", justifyContent:"flex-start", display:"flex", backgroundColor:"#FFF8F1"}}>

            <Row>
                <video class="background-video"   controls loop onCanPlay={handleCanPlay}>
                <source src={imageDB.introduce1}  type="video/mp4" />
                </video>
                <div style={{paddingLeft:30}}>
                  <HighLight>모바일 웹 동시지원</HighLight>

                  <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>
                    <div>이제 홍여사를 </div>
                    <div>모바일로도 만나보세요</div>
                  </div>

                  <div style={{fontSize:18, fontFamily:"Pretendard-Regular", color:"#7c7c7c",
                    lineHeight: "1.8",
                    marginTop: "20px"}}>
                    <div>일일 다운로드 횟수 200% 증가세 </div>
                    <div>일일 계약 횟수 500% 증가 </div>
                    <div>혁신적인 플랫폼으로 심부름앱의 지각변동 </div>
                  </div>
                </div>
            </Row>
          

          </div>


     

          <div style={{height:"650px", backgroundColor:"#fff", width:"100%"}}>
            <Row style={{width:"100%", margin:"50px auto 0px"}}>

            
              <MatchingItem  style={{width:"40%"}}>
                <img src={imageDB.request1} style={{width:"380px"}}/>
              </MatchingItem>

              <Column style={{width:"50%", alignItems:"flex-start"}}>
              <div >
                <HighLight>간편한 등록요청</HighLight>
                <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>3단계만으로 요청가능</div>
                <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>10초만에 요청</div>
              </div>
              <Row style={{marginTop:5}}>
                  <Tag>3단계</Tag>
                  <Tag>10초</Tag>
                  <Tag>실시간</Tag>
              </Row>     
              </Column>
            </Row>
          </div>

          <div style={{height:"630px", backgroundColor:"#fff", width:"100%"}}>
                <Row style={{width:"100%", margin:"0px auto 0px"}}>    
                  <div style={{width:"30%"}}>
                    <HighLight>실시간 매칭</HighLight>
                    <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>긴급한 당신을 위한</div>
                    <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>지금 즉시 매칭 시스템</div>
                  </div>

                  <MatchingItem>
                    <img src={imageDB.matching} style={{width:"300px"}}/>
                    <img src={imageDB.matching2} style={{width:"380px"}}/>
                  </MatchingItem>
            
                </Row>
          </div>


          <div style={{height:"800px", backgroundColor:"#F6FDEC", width:"100%"}}>

            <Column style={{width:"100%", margin:"70px auto 0px"}}>
              <div style={{width:"80%",display:"flex", justifyContent:"center", flexDirection:"column"}}>
                  <HighLight>안전하고 쉬운 계약</HighLight>
                  <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>모든 거래는 채팅으로 복잡하지 않고 간결하고 안전하게 거래</div>
              </div>
              <MatchingItem style={{marginTop:50}}>
                <img src={imageDB.chat1} style={{width:"350px", marginRight:10}}/>
                <img src={imageDB.chat2} style={{width:"350px", marginRight:10}}/>
                <img src={imageDB.chat3} style={{width:"350px"}}/>
              </MatchingItem>

            </Column>
          </div>



        </Container>
        <StoreInfo />
    </>

  );

}

export default PCGatecontainer;

