import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../screen/css/common.css";
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { Column, FlexstartColumn } from "../common/Column";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../common/Button";
import { FILTERITEMMONEY, FILTERITEMPERIOD, FILTERITMETYPE, LoadingType } from "../utility/screen";

import { WORKNAME } from "../utility/work";
import { ROOMSIZE, ROOMSIZEDISPALY } from "../utility/room";

import { MdLockReset } from "react-icons/md";
import { imageDB } from "../utility/imageData";

import { se } from "date-fns/locale";

import { model } from "../api/config";
import Loading from "../components/Loading";
import { useSleep } from "../utility/common";
import { CreateSearch, DeleteSearchByid, ReadSearch, ReadSearchByid } from "../service/SearchService";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { getFullTime } from "../utility/date";

import TimeAgo from 'react-timeago';

import { FaListCheck } from "react-icons/fa6";



import { GrUploadOption } from "react-icons/gr";
import LottieAnimation from "../common/LottieAnimation";
import TypingText from "../common/TypingText";
import { LoadingAnimationStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { CiSearch } from "react-icons/ci";
import ButtonEx from "../common/ButtonEx";
import MobileSearchMemo from "../modal/MobileSearchMemo";
import { UpdateCATEGORYCONTENTMEMOByid } from "../service/CategoryService";

const formatter = buildFormatter(koreanStrings); 


const MobileResultContent = {
  width: '90%',
  height: '100vh',
  padding: '0px 20px 0px 20px',
  fontSize: '16px',
  fontFamily: 'Pretendard-Regular',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",

}

const AddButton = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 80px;
  width: 80px;
  background: #ff7e19;
  border-radius: 80px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard-Bold';
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

`

const MobileAiCategoryContent = ({CATEGORYCONTENT_ID, CONTENT ,MEMOITEMS}) =>{
console.log("TCL: MobileAiCategoryContent -> CATEGORYCONTENT_ID", CATEGORYCONTENT_ID)

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(-1);
  const [memopopup, setMemopopup] = useState(false);


  useEffect(() => {
    setLoading(loading);
    setMemopopup(memopopup);

  }, [refresh]);

  const _handleMemo = () =>{
    setMemopopup(true);
    setRefresh((refresh) => refresh +1);
  }
  const MobileMemoCallback = async(data)=>{

    
    console.log("TCL: MobileMemoCallback -> ",data,CATEGORYCONTENT_ID );

    const MEMO = data;

    const Updatedata = await UpdateCATEGORYCONTENTMEMOByid({CATEGORYCONTENT_ID, MEMO });

    MEMOITEMS.push(MEMO);

    setMemopopup(false);
    setRefresh((refresh) => refresh +1);
  }

  async function FetchData(){

    
  }
  /**
   * 데이타를 가져온다
   * 1) 무조건 데이타를 가져 와서 저장 해둔다
   * 2) 검색어가 있다면 zemini에 요청한다
   * 3) 검색 결과를 searchresult 에 저장 해두고 데이타 베이스에 입력한다
   * 4) 검색어가 없다면 처음에 가져온 데이타에서 첫번째 인덱스 값을 보여준다 
   */
  useEffect(() =>{

    FetchData();

  }, [])

  
  return (
    <Column style={{width:'100%', margin: '0 auto'}}>


      {
        memopopup == true && <MobileSearchMemo callback={MobileMemoCallback} />
      }
  
      <textarea value ={CONTENT} style={MobileResultContent}></textarea>

      {
        MEMOITEMS.map((data)=>(
          <FlexstartColumn style={{width:"90%", margin:"0 auto"}}>
              <div style={{color: '#ff4e19',fontSize: '12px'}}>메모추가</div>
              <div style={{fontStyle:"italic", fontSize:'14px'}}>{data}</div>
          </FlexstartColumn>
      
        ))
      }
      <AddButton onClick={_handleMemo}>메모추가</AddButton>

    </Column> 
  );
};

export default MobileAiCategoryContent;