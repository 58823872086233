import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";
import { IoFilterCircle } from "react-icons/io5";
import { MdOutlineRecommend } from "react-icons/md";
import { MdLockReset } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";
import { imageDB } from "../utility/imageData";
import { MdDataSaverOn } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { RiListView } from "react-icons/ri";
import { GrFilter } from "react-icons/gr";
import { FaRegMap } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";

const Container = styled.div`

    height: ${({height})=> height};
    width: ${({width})=> width};
    border-radius: ${({radius})=> radius};
    background: ${({bgcolor})=> bgcolor};
    color :${({color})=> color};

    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
    &:active {
      transform: scale(0.95); /* 눌렀을 때 크기 조정 */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
    }


`
const style = {
  display: "flex"
};

const IconButton =({containerStyle, text, height,width, radius, bgcolor, color, onPress, icon, iconcolor}) =>  {

  return (

    <Container 
    onClick={onPress}
    style={containerStyle} height={height} width={width} radius={radius} bgcolor={bgcolor} color={color}>
      {
        icon =='filter' &&  <GrFilter size={16} color={iconcolor} />
      }
      {
        icon =='recommend' &&  <MdOutlineRecommend size={30} color={iconcolor}/>
      }
      {
        icon =='reset' &&  <img src={imageDB.reset}  style={{width:16, height:16}}/>
      }
      {
        icon =='prev' &&  <FaArrowLeft size={30} color={iconcolor}/>
      }
      {
        icon =='list' &&  <RiListView size={18} color={iconcolor}/>
      }
      {
        icon =='save' &&  <FaPlus size={16} color={iconcolor}/>
      }
      {
        icon =='map' && <FaRegMap size={16} color={iconcolor} />
      }

 
        
      <div style={{marginLeft:5}}>{text}</div>

      {
        icon =='rightarrow' && <FaArrowRight size={14} color={iconcolor} style={{marginLeft:5}} />
      }
      {
        icon =='teacher' && <FaArrowRight size={16} color={iconcolor} />
      }

    </Container>
  );

}

export default IconButton;

