
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { ensureHttps, useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { ReadCommunitySummary } from "../service/CommunityService";
import TimeAgo from 'react-timeago';
import { getFullTime } from "../utility/date";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingCommunityStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { PCCOMMNUNITYMENU } from "../utility/screen";
import { ReadRECIPE } from "../service/RecipeService";

import { shuffleArray } from "../utility/common";
import { getNewDate } from "../utility/date";
import LazyImage from "../common/LasyImage";

import { FiPlus } from "react-icons/fi";
import ButtonEx from "../common/ButtonEx";
import Empty from "./Empty";
import MobileRecipeadd from "../modal/MobileRecipeadd";
import { ReadFREEZE, UpdateFREEZECHECKid } from "../service/FreezeService";
import MobileRecipeadjust from "../modal/MobileRecipeadjust";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyImageex from "../common/LasyImageex";


const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;

`
const style = {
  display: "flex"
};



const BoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BoxItem = styled.div`
  width : 49%;
  height : 100%; 
  margin-bottom:40px;

`
const FreezeItem = styled.div`
  width : 100%;
  height : 100%; 
  margin-bottom:40px;
  display : flex;
  flex-direction: row;

`

const FreezeBoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FreezeBoxItem = styled.div`
  width : 49%;
  background: ${({enable})=> enable== true ? ('bisque') :('#f9f9f9')};
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction:column;
  border-radius: 5px;
  margin-bottom:5px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}



const RecommendTitle = styled.div`
  font-family: 'Pretendard-Bold';
  margin: 20px 0px;

`
const Recipename = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
`
const RecommendButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  color : #999;
`
const AdddButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  color : #ff7e19;
  display:flex;
`

const Recipetip = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
`
const Recipereview = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
margin-top:10px;
`

const Tag1 = styled.div`

font-size: 12px;
background: #fff;
color: #070606;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
`
const Tag2= styled.div`

  font-size: 12px;
  background: #fff;
  color: #070606;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left:5px;
`

const EmptyLine = styled.div`
  height: 2px;
  background: #ededed;
  margin: 20px 0px;

`
const Dday = styled.div`
  font-size: 10px;
  background-color : ${({out}) => out == true ? ('#ff7e19') :('#fff')};
  border-radius: 20px;
  border : ${({out}) => out == true ? ('1px solid #ff7e19') :('1px solid #ff7e19')};
  color : ${({out}) => out == true ? ('#fff') :('#ff7e19')};

  padding: 5px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';
`

const Alarm = styled.div`

  font-size: 10px;
  border: 1px solid #1982ff;
  background-color : #1982ff;
  border-radius: 20px;
  color: #fff;
  padding: 5px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';


`
const Freezename = styled.div`
  font-size:16px;

`
const Property = styled.div`
  font-size:10px;

`

const MenuButton = styled.div`
  background: ${({enable})=> enable == true ? ('#ff7e19'):('#fff')};
  color: ${({enable})=> enable == true ? ('#fff'):('#131313')};
  padding: 5px;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom:5px;
  font-size:12px;
  width: 50px;
  display: flex;
  justify-content: center;

`
const CheckLayer = styled.div`
  display : flex;
  width :20px;
  height :15px;
`

const MobileFreezeBoard =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */




  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);


  const [displayitems, setDisplayitems] = useState([]);
  const [freezerecommenditems, setFreezerecommenditems] = useState([]);
  const [freezerecommenddisplayitems, setFreezerecommenddisplayitems] = useState([]);
  const [totalitems, setTotalitems] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectmenu, setSelectmenu] = useState(PCCOMMNUNITYMENU.ALLITEM);

  const [contentItems, setContentItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [categoryMenu, setCategoryMenu] = useState('전체');

  const [recipepopup, setRecipepopup] = useState(false);
  const [recipeadjustpopup, setRecipeadjustpopup] = useState(false);

  const [adjustfreezeitem, setAdjustfreezeitem] = useState({});

 
  const [visibleImages, setVisibleImages] = useState(10);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setVisibleImages((prevVisibleImages) => prevVisibleImages + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setDisplayitems(displayitems);
    setLoading(loading);
    setSelectmenu(selectmenu);
    setTotalitems(totalitems);
    setFreezerecommenditems(freezerecommenditems);
    setFreezerecommenddisplayitems(freezerecommenddisplayitems)
    setContentItems(contentItems);
    setCategoryItems(categoryItems);
    setCategoryMenu(categoryMenu);
    setRecipepopup(recipepopup);
    setRecipeadjustpopup(recipeadjustpopup);
    setAdjustfreezeitem(adjustfreezeitem);
  },[refresh])


  const _handleRecommend = () =>{

    let itemsTmp = shuffleArray(totalitems);
    setDisplayitems(itemsTmp);
    setRefresh((refresh) => refresh +1);

  }

  const _handleFreeClick = (data)=>{

   const FindIndex=  contentItems.findIndex(x=>x.NAME == data.NAME);

   if(contentItems[FindIndex].check == true){
    contentItems[FindIndex].check = false;
   }else{

    contentItems[FindIndex].check = true;
   }
   setRefresh((refresh) => refresh +1);
  }

  const _handleFreeAdjustClick =(data)=>{
    setRecipeadjustpopup(true);
    setAdjustfreezeitem(data);
    setRefresh((refresh) => refresh +1);

  }

  async function SelectFetchData(type){


    
    const items = await ReadRECIPE();

    setTotalitems(items);
  
    let itemsTmp = shuffleArray(items);
    setDisplayitems(itemsTmp);


    const USERS_ID = user.users_id;
    const freezeitemsTmp = await ReadFREEZE({USERS_ID});
    console.log("TCL: SelectFetchData -> freezeitemsTmp", freezeitemsTmp)
    setContentItems(freezeitemsTmp);
    setRefresh((refresh) => refresh+1);

    RecipeInitSearch(freezeitemsTmp,itemsTmp);


    setLoading(false);
    setRefresh((refresh) => refresh+1);
  }
  
  useEffect(()=>{
    SelectFetchData();
  }, [])

  const _handleRecipeSearch = () =>{

    let filterary = [];

    console.log("TCL: _handleRecipeSearch -> contentItems", contentItems)
    contentItems.map((data)=>{

      if(data.check == true){
        filterary.push(data.NAME);
      }
    })

    if(filterary.length > 2){
      alert("재료는 두개만 선택해 주세요");
      return;
    }
    

    contentItems.map(async(data)=>{
    console.log("TCL: _handleRecipeSearch -> contentItems", data)
    const FREEZE_ID = data.FREEZE_ID;
    const check = data.check;

    const checkupdate = await UpdateFREEZECHECKid({FREEZE_ID, check})
    })
    





    let items = [];

    if(filterary.length == 1){
      totalitems.map((data)=>{
        let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
        if(startposition != -1){
          console.log("handleRecipeSearch", data);
          items.push(data);
  
        }
      });
    }else if(filterary.length ==2){
      totalitems.map((data)=>{

        let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
        if(startposition != -1){

          console.log("handleRecipeSearch", data.ITEM);

          let twoposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[1]);

          if(twoposition != -1){
            items.push(data);
    
          }


  
        }
      });
    }

    console.log("TCL: _handleRecipeSearch -> items", items);
    const groupedItemsTmp = items.reduce((result, item) => {

      // item.category 값이 이미 존재하는지 확인
      const key = item.ITEM.RCP_PAT2;
      
      if (!result[key]) {
        result[key] = []; // category 값이 없으면 배열 초기화
      }
      
      result[key].push(item); // 해당 category에 item 추가
      return result;
    }, {});
    
    console.log("TCL: _handleRecipeSearch -> groupedItemsTmp", groupedItemsTmp)


    const keysWithSizes = Object.keys(groupedItemsTmp).map((key) => ({
      key,
      size: groupedItemsTmp[key].length,
    }));

    setCategoryItems( keysWithSizes);
 
    console.log("TCL: _handleRecipeSearch -> groupedItemsTmp", keysWithSizes)

    setFreezerecommenditems(items);
    setFreezerecommenddisplayitems(items);
    setRefresh((refresh) => refresh +1);
  }
  const RecipeInitSearch = (freezeitems, totalitems) =>{

    let filterary = [];

    console.log("TCL: RecipeInitSearch -> items", freezeitems)
    freezeitems.map((data)=>{

      if(data.check == true){
        filterary.push(data.NAME);
      }
    })

    if(filterary.length > 2){
      alert("재료는 두개만 선택해 주세요");
      return;
    }
    


  

    let items = [];

    if(filterary.length == 1){
      totalitems.map((data)=>{
        let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
        if(startposition != -1){
          console.log("handleRecipeSearch", data);
          items.push(data);
  
        }
      });
    }else if(filterary.length ==2){
      totalitems.map((data)=>{

        let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
        if(startposition != -1){

          console.log("handleRecipeSearch", data.ITEM);

          let twoposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[1]);

          if(twoposition != -1){
            items.push(data);
    
          }


  
        }
      });
    }

    console.log("TCL: RecipeInitSearch -> items", items);
    const groupedItemsTmp = items.reduce((result, item) => {

      // item.category 값이 이미 존재하는지 확인
      const key = item.ITEM.RCP_PAT2;
      
      if (!result[key]) {
        result[key] = []; // category 값이 없으면 배열 초기화
      }
      
      result[key].push(item); // 해당 category에 item 추가
      return result;
    }, {});
    
    console.log("TCL: RecipeInitSearch -> groupedItemsTmp", groupedItemsTmp)


    const keysWithSizes = Object.keys(groupedItemsTmp).map((key) => ({
      key,
      size: groupedItemsTmp[key].length,
    }));

    setCategoryItems( keysWithSizes);
 
    console.log("TCL: RecipeInitSearch -> groupedItemsTmp", keysWithSizes)

    setFreezerecommenditems(items);
    setFreezerecommenddisplayitems(items);
    setRefresh((refresh) => refresh +1);
  }

  async function SelectFreezeData(type){

    const USERS_ID = user.users_id;
    const freezeitemsTmp = await ReadFREEZE({USERS_ID});
    console.log("TCL: SelectFetchData -> freezeitemsTmp", freezeitemsTmp)
    setContentItems(freezeitemsTmp);


    setLoading(false);
    setRefresh((refresh) => refresh+1);
  }


  function DDayCheck (lastdate){
    const today = new Date();
    const targetDate = new Date(lastdate); // 목표 날짜를 지정하세요
    
    // 두 날짜의 차이를 밀리초 단위로 계산
    const diffInTime = targetDate.getTime() - today.getTime();
    
    // 밀리초를 일(day)로 변환
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    if(diffInDays < 0){
      return diffInDays * -1 + "일지남";
    }else{
      return "D - " +diffInDays +"일";
    }

  }

  function DDayCheckValue (lastdate){
    const today = new Date();
    const targetDate = new Date(lastdate); // 목표 날짜를 지정하세요
    
    // 두 날짜의 차이를 밀리초 단위로 계산
    const diffInTime = targetDate.getTime() - today.getTime();
    
    // 밀리초를 일(day)로 변환
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    if(diffInDays < 0){
      return -1;
    }else{
      return 0;
    }

  }



  const _handleRecipe = (item)=>{
    navigate("/Mobilerecipe", {state : {item: item.ITEM}})
  }

  const _handleFreezeRecipe = (item)=>{
    navigate("/Mobilerecipe", {state : {item: item}})
  }

  const _handleCategory = (category)=>{

    setCategoryMenu(category);

    if(category == '전체'){
      setFreezerecommenddisplayitems(freezerecommenditems);
    }else{

      let items = [];
      freezerecommenditems.map((data)=>{
        if(data.ITEM.RCP_PAT2 == category){
          items.push(data);
        }
      })

      setFreezerecommenddisplayitems(items);
    }
    setRefresh((refresh) => refresh +1);
  }

  const MobileRecipeCallback = () =>{
    setRecipepopup(false);

    SelectFreezeData();
    setRefresh((refresh) => refresh +1);
  }

  const MobileRecipeAdjustCallback = () =>{
    setRecipeadjustpopup(false);
    SelectFreezeData();
    setRefresh((refresh) => refresh +1); 
  }

  const _handleadd = () =>{
    setRecipepopup(true);
    setRefresh((refresh) => refresh +1);
  }
  return (

    <Container style={containerStyle}>     
    {
      loading == true ? (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'}/>)
      :(
        <Column style={{marginTop:10,width:"100%",margin:"0 auto", paddingTop:"0px"}}>


          {
            recipepopup == true && <MobileRecipeadd callback={MobileRecipeCallback} />
          }
  
          {
            recipeadjustpopup == true && <MobileRecipeadjust callback={MobileRecipeAdjustCallback} adjustfreezeitem={adjustfreezeitem} />
          }
  

          <div style={{overflowY:"hidden",width:"90%",margin:"0 auto"}}>


          <FlexEndRow style={{width:"100%", height:50}}>
  
          <AdddButton onClick={_handleadd}>
            <FiPlus size={16} color={'#ff7e19'}/>
            <div>재료추가</div>
            
          </AdddButton>
          </FlexEndRow>



          {
            contentItems.length > 0 &&          <FreezeBoxLayer>
            {
                contentItems.map((data)=>(
                  <FreezeBoxItem enable ={data.check}>
                    <BetweenRow>
                    {
                        data.check == true ? (<CheckLayer onClick={()=>{_handleFreeClick(data)}}><img src={imageDB.checkbtn_e} style={{width:"20px", paddingLeft:5}}/></CheckLayer>)
                        :(<CheckLayer onClick={()=>{_handleFreeClick(data)}}><img src={imageDB.checkbtn_d} style={{width:"20px", paddingLeft:5}}/></CheckLayer>)
                    }
                  
                    <Row>        
                    {
                      data.ENDDATE != '' && <Dday out={DDayCheckValue(data.ENDDATE) =='-1'}>{DDayCheck(data.ENDDATE)}</Dday>
                    }
                  
                    </Row>

                    </BetweenRow>
            
                    <div style={{width:"100%", paddingTop:5}}  onClick={()=>{_handleFreeAdjustClick(data)}}>    

                      <Column>

                      <Row>

                      <Freezename>{data.NAME}</Freezename>

                   
                      </Row>
                      </Column>


                    </div>
                  </FreezeBoxItem>
              ))
            }

          </FreezeBoxLayer>
          }


          <Row style={{width:"100%", margin:"20px 0px"}}>
            <ButtonEx text={'나의 냉장고 레시피 찾기'} width={'60'} containerStyle={{fontSize:16, boxShadow:"none"}}onPress={_handleRecipeSearch} bgcolor={'bisque'} color={'#131313'} />
          </Row>


          {
            freezerecommenddisplayitems.length == 0 && 
            <Column>
            
            <BetweenRow style={{width:"100%"}}>
            <RecommendTitle>나의 냉장고 레시피</RecommendTitle>
            </BetweenRow>

            <Empty content={'냉장고 재료에 맞는  레시피가 없습니다'} fontsize={'14px'}/>
            </Column>
            
            
        

          }
          {
            freezerecommenddisplayitems.length > 0 && <Column>
            
            <BetweenRow style={{width:"100%"}}>
            <RecommendTitle>나의 냉장고 레시피</RecommendTitle>
            </BetweenRow>


            <FlexstartRow style={{width:"100%", marginBottom:10, flexWrap:"wrap"}}>
              <MenuButton enable={categoryMenu == '전체'} onClick={()=>{_handleCategory('전체')}}>전체</MenuButton>
              {
                categoryItems.map((data)=>(
                  <MenuButton enable ={categoryMenu ==data.key}  onClick={()=>{_handleCategory(data.key)}}>{data.key}{data.size}</MenuButton>
                ))
              }
            </FlexstartRow>

            <BoxLayer>



              {
                freezerecommenddisplayitems.slice(0, visibleImages).map((data, index)=>(
                  <FreezeItem key= {index} onClick={()=>{_handleRecipe(data)}} >
                  {/* <div style={{width:"40%"}}>
                    <LazyImageex src={ensureHttps(data.ITEM.ATT_FILE_NO_MAIN)} containerStyle={{width:'90%', borderRadius:"10px", backgroundColor: '#ededed' }}/> */}
                    <div style={{width:"40%"}}>
                    <LazyLoadImage
                      style={{borderRadius:10, background:"#ededed"}}
                      src={ensureHttps(data.ITEM.ATT_FILE_NO_MAIN)} 
                      alt="Lazy Loaded Example"
                      effect="blur"
                      offset={100} // 이미지가 보이기 100px 전 미리 로드
                      width={'90%'}
                    />
                  </div>

                  <div style={{width:"60%"}}>
                  <Recipename>{data.ITEM.RCP_NM}</Recipename>
                  <Recipetip>{data.ITEM.RCP_NA_TIP}</Recipetip>
                  <Recipereview>조회수 {data.VIEW}</Recipereview>
                  </div>

               
                </FreezeItem>
                ))
              }
            </BoxLayer>
            </Column>

          }

     
      
          </div>  
        </Column>)
    }  
    </Container>
  );

}

export default MobileFreezeBoard;

