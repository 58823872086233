import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import MobileChatcontainer from "../../container/main/MobileChatcontainer";
import MobileCompletecontainer from "../../container/main/MobileCompletecontainer";
import MobileContentcontainer from "../../container/main/MobileContentcontainer";

import PCChatcontainer from "../../container/PCmain/PCChatcontainer";

import { UserContext } from "../../context/User";
import MobileChatContentLayout from "../../screen/Layout/Layout/MobileChatContentLayout";
import MobileCommunityLayout from "../../screen/Layout/Layout/MobileCommunityLayout";
import PCChatLayout from "../../screen/LayoutPC/Layout/PCChatLayout";

import PCLayout from "../../screen/LayoutPC/Layout/PCLayout";
import { MOBILEMAINMENU, PCMAINMENU } from "../../utility/screen";
import MobilePrevLayout2 from "../../screen/Layout/Layout/MobileCommunityLayout";
import MobilePrevLayout from "../../screen/Layout/Layout/MobilePrevLayout";
import MobileReviewcontainer from "../../container/main/MobileReviewcontainer";
import MobileOnlyPrevLayout from "../../screen/Layout/Layout/MobileOnlyPrevLayout";

const Container = styled.div`

`
const style = {
  display: "flex"
};

const MobileReviewpage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])

 
  return (

    <MobileOnlyPrevLayout name={'홍여사 평가'} type={MOBILEMAINMENU.CHATMENU}>
        <MobileReviewcontainer ITEM ={location.state.ITEM} NAME={location.state.NAME} OWNER={location.state.OWNER} LEFTIMAGE={location.state.LEFTIMAGE}
        LEFTNAME= {location.state.LEFTNAME}/>
    </MobileOnlyPrevLayout>
  )

}

export default MobileReviewpage;

