export const LoadingSearchAnimationStyle={
    zIndex: 11,
    position: "absolute",
    top: "35%",
    left: "35%"
}
export const LoadingPCPhoneAnimationStyle={
    zIndex: 11,
    position: "absolute",
    top: "45%",
    left: "40%"
}

export const LoadingMainAnimationStyle ={
    zIndex: 11,
    position: "absolute",
    top: "35%",
    left: "40%"
}

export const LoadingConfigAnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "10%",
    top: "300px",
    position:"absolute"
}



export const LoadingChatAnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "300px",
    position:"absolute"
}

export const LoadingProfileAnimationStyle={
    zIndex: 11,
    position: "absolute",
    left: "40%",
    top: "300px",
}

export const LoadingMapConfigAnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "80%",
    position:"absolute"
}


export const LoadingChat2AnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "500px",
    position:"absolute"
}


export const LoadingChat3AnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    top: "300px",
    position:"absolute"
}


export const LoadingCommunityStyle={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "300px",
    position:"absolute"
  }

  export const LoadingBoardStyle={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "500px",
    position:"absolute"
  }

  export const LoadingLifeStyle={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    margin:"0px auto",
    top: "300px",
    left:"300px",
    position:"absolute"
  }