import React, { Fragment, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { GrPrevious } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { CURRENTPAGE } from "../../../utility/router";
import { IoEllipseSharp } from "react-icons/io5";
import { LuUserCircle } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import { GoSearch } from "react-icons/go";
const Container = styled.div``;

const LogoText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF-Pro-Text-Semibold';
    font-weight:600;
    padding-top: 5px;
    font-size: 20px;
    padding-left: 10px;
    color :#000;

`



const MobilePrevheader = ({callback, registbtn, name, iconname}) => {
  const navigation = useNavigate();
  const { user, dispatch } = useContext(UserContext);

  const location = useLocation();




  const _handleprev = () =>{

    if(location.pathname == CURRENTPAGE.MOBILESEARCH){
      navigation('/mobilemain');
    }else{
      navigation(-1);
    }
   
  }

  const _handlehistory = () =>{
    navigation("/Mobilesearchhistory");
  }

  const _handleConfig=()=>{
    navigation("/Mobileconfig");
  }

  return (
    <Container
      id="header"
      style={{
        zIndex: 999,
        position: "fixed",
        background: "#fff",
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px solid #ededed",
        fontFamily:"Pretendard-SemiBold",
  
      }}
    >

      <div style={{paddingLeft:15, width:'30%', display:"flex", alignItems:"center"}}>
        <GrPrevious onClick={_handleprev} size={20} />
        <div style={{fontSize:"18px"}}>{name}</div>
      </div>

      {
        iconname == 'searchmenu' &&  <div 
        onClick={_handlehistory}
        style={{paddingRight:20, width:'70%', display:"flex", justifyContent:"flex-end"}}>
        <GiHamburgerMenu size={22}/>
      </div>
      }
      <div  style={{display: "flex",flexDirection: "row",justifyContent: "flex-end",marginRight: "10px",width: "10%"}}>
      <div style={{marginRight:10, display:"flex", alignItems:"center"}}>
        <GoSearch size={24}/>
        </div>
      <img src={imageDB.profile}  style={{width:"30px", height:"30px"}} onClick={_handleConfig}/>
      </div>
    
    </Container>
  );
};

export default MobilePrevheader;
