import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../screen/css/common.css";
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { Column, FlexstartColumn } from "../common/Column";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../common/Button";
import { FILTERITEMMONEY, FILTERITEMPERIOD, FILTERITMETYPE, LoadingType } from "../utility/screen";

import { WORKNAME } from "../utility/work";
import { ROOMSIZE, ROOMSIZEDISPALY } from "../utility/room";

import { MdLockReset } from "react-icons/md";
import { imageDB } from "../utility/imageData";

import { se } from "date-fns/locale";

import { model } from "../api/config";
import Loading from "../components/Loading";
import { useSleep } from "../utility/common";
import { CreateSearch, DeleteSearchByid, ReadSearch, ReadSearchByid } from "../service/SearchService";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { getFullTime } from "../utility/date";

import TimeAgo from 'react-timeago';

import { FaListCheck } from "react-icons/fa6";



import { GrUploadOption } from "react-icons/gr";
import LottieAnimation from "../common/LottieAnimation";
import TypingText from "../common/TypingText";
import { LoadingAnimationStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { CiSearch } from "react-icons/ci";
import ButtonEx from "../common/ButtonEx";
import { UserContext } from "../context/User";
import { DeleteCATEGORYCONTENTByid, ReadCATEGORYCONTENT, UpdateCATEGORYCONTENTCOLORByid } from "../service/CategoryService";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GoPencil } from "react-icons/go";
import { useNavigate } from "react-router-dom";

import { BsBookmarkCheck } from "react-icons/bs";
import { BsBookmarkCheckFill } from "react-icons/bs";

const formatter = buildFormatter(koreanStrings); 


const BoxItem = styled.div`
  border: 1px solid #ededed;
  background: ${({bgcolor})=>bgcolor};
  padding: 20px;
  font-size: 14px;
  margin: 5px 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
`

const KeywordItem = styled.div`
  font-size: 16px;
  font-family: 'Pretendard-Bold';
`
const DateItem = styled.div`
  font-size: 13px;
  font-family: 'Pretendard-Light';
  color : #666;

`
const ControlButton = styled.div`
  font-size: 12px;
  background: #fff;
  padding: 3px 5px;
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`
const ColorButton = styled.div`
  background : ${({bgcolor}) => bgcolor};
  width:10px;
  height:10px;
  border-radius:10px;
  border : 1px solid #999;

`

const Mark = styled.div`
position: relative;
top: 30px;
left: 60px;


`
const ONECOLOR = "#f9e0d38c";
const TWOCOLOR = "#9cd7fa";
const THREECOLOR = "#ffed08";
const FOURCOLOR = "#fff";

const MobileAiCategoryList = ({name}) =>{
  const { dispatch, user } = useContext(UserContext);
  const navigation = useNavigate();

  const [refresh, setRefresh] = useState(-1);
  const [contentitems1, setContentitems1] = useState([]);
  const [contentitems2, setContentitems2] = useState([]);

  const [loading, setLoading] = useState(false);


  useEffect(() => {

    setLoading(loading);
  }, [refresh]);

  async function FetchData(){

    setLoading(true);
    const USERS_ID = user.users_id;
    const CATEGORY = name;
    console.log("TCL: FetchData -> name", name)
    
    const items = await ReadCATEGORYCONTENT({USERS_ID});
    console.log("TCL: FetchData -> items", items);

    let itemsTmp = [];

    if(items != -1){
      items.map((data)=>{
        if(data.CATEGORY == name){
          itemsTmp.push(data);
        }
      })
    }


    let items1 = [];
    let items2 = [];
    let items3 = [];

    itemsTmp.map((data, index)=>{
      if(index % 2 == 0){
        items1.push(data);
      }
      else if(index % 2 ==1){
        items2.push(data);       
      }

    })


    setContentitems1(items1);
    setContentitems2(items2);

    setLoading(false);
    setRefresh((refrehs) => refresh +1);

  }
  useEffect(() =>{

  
    FetchData();

  }, [])

  const _handleContent = async(id, keyword, content, memoitems)=>{

    const CATEGORYCONTENT_ID = id;
    const KEYWORD = keyword;
    const CONTENT = content;
    const MEMOITEMS = memoitems
    console.log("TCL: _handleContent -> memoitems", id)

    navigation("/Mobileaicategorycontent", {state:{CATEGORYCONTENT_ID : CATEGORYCONTENT_ID,
      CONTENT : CONTENT,
      MEMOITEMS : MEMOITEMS,
      KEYWORD : KEYWORD}});
  }



  const _handleDelete = async (id)=>{
    setLoading(true);
    const CATEGORYCONTENT_ID = id;
    const Delete = await DeleteCATEGORYCONTENTByid({CATEGORYCONTENT_ID});
    FetchData();
  }
  const _handleColorChange = async(id, type)=>{
    setLoading(true);
    setRefresh((refresh) => refresh +1);
    const CATEGORYCONTENT_ID = id;
    let BGCOLOR = "";
    if(type == 1){
      BGCOLOR = ONECOLOR;
    }else if(type == 2){
      BGCOLOR = TWOCOLOR;
    }else if(type == 3){
      BGCOLOR = THREECOLOR;
    }else if(type == 4){
      BGCOLOR = FOURCOLOR;
    }
    const update = await UpdateCATEGORYCONTENTCOLORByid({CATEGORYCONTENT_ID, BGCOLOR});
    FetchData();
  }

  
  return (




    <Column style={{width:'100%', margin: '0 auto'}}>

    {loading == true && <LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loading}
      width={"50px"} height={'50px'}/>
    }

    <Row style={{alignItems:"unset"}}>

    <div style={{ height:'100%', width:'45%'}}>
    {
      contentitems1.map((data, index)=>(
        <Column>
        {
          data.MEMOITEMS.length != 0 &&  <Mark> <BsBookmarkCheckFill color={'#ff7e19'}/></Mark>
        }
       
        <BoxItem bgcolor={data.BGCOLOR} onClick={()=>{_handleContent(data.CATEGORYCONTENT_ID, 
          data.KEYWORD, data.CONTENT, data.MEMOITEMS)}}>
          <KeywordItem>{data.KEYWORD}</KeywordItem>
          <DateItem>
            <div><TimeAgo date={getFullTime(data.CREATEDT)}formatter={formatter}/></div>
          </DateItem>
          <div>
            {data.CONTENT.slice(0, 60)}
            {data.CONTENT.length > 60 ? "..." : null}
          </div>
        </BoxItem>
        <FlexEndRow style={{width:"100%"}}>
   
          <ControlButton>
            <ColorButton bgcolor={'#f9e0d3'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,1)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#069ffa'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,2)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#ffed08'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,3)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#fff'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,4)}}/>
          </ControlButton>
          <ControlButton>
            <RiDeleteBin5Line onClick={()=>{_handleDelete(data.CATEGORYCONTENT_ID)}}/>
          </ControlButton>
   
        </FlexEndRow>
      </Column>
      ))
    }
    </div>
    <div style={{ height:'100%', width:'45%', marginLeft:5}}>
    {
      contentitems2.map((data, index)=>(
        <Column>
          {
           data.MEMOITEMS.length != 0 &&  <Mark> <BsBookmarkCheckFill color={'#ff7e19'}/></Mark>
          }
          <BoxItem bgcolor={data.BGCOLOR} onClick={()=>{_handleContent(data.CATEGORYCONTENT_ID, 
            data.KEYWORD, data.CONTENT, data.MEMOITEMS)}}>
            <KeywordItem>{data.KEYWORD}</KeywordItem>
            <DateItem>
              <div><TimeAgo date={getFullTime(data.CREATEDT)}formatter={formatter}/></div>
            </DateItem>
            <div>
              {data.CONTENT.slice(0, 60)}
              {data.CONTENT.length > 60 ? "..." : null}
            </div>
          </BoxItem>
          <FlexEndRow style={{width:"100%"}}>
        
          <ControlButton>
            <ColorButton bgcolor={'#f9e0d3'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,1)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#069ffa'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,2)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#ffed08'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,3)}}/>
          </ControlButton>
          <ControlButton>
            <ColorButton bgcolor={'#fff'} onClick={()=>{_handleColorChange(data.CATEGORYCONTENT_ID,4)}}/>
          </ControlButton>
          <ControlButton>
            <RiDeleteBin5Line onClick={()=>{_handleDelete(data.CATEGORYCONTENT_ID)}}/>
          </ControlButton>
 
          </FlexEndRow>
        </Column>


      ))
    }
    </div>



    </Row>

    </Column> 
  );
};

export default MobileAiCategoryList;