import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";

import "./PCmain.css"
import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { LoadingType } from "../../utility/screen";
import { ROOMPOLICY, ROOMSIZE } from "../../utility/room";
import { WORKPOLICY } from "../../utility/work";
import { Column } from "../../common/Column";
import Button from "../../common/Button";
import { GoPlus } from "react-icons/go";

const Container = styled.div`
  background :#f3f3f3;
  height:650px;
  display:flex;
  flex-direction:column;

`
const style = {
  display: "flex"
};

const RegistButton = styled.div`
    height: 50px;
    width: 131px;
    border-radius: 100px;
    background: #FF7125;
    color: #fff;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    font-size: 18px;
`



const Box = styled.div`
  background : #f9f9f9;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 100px;
  width: 20%;
  background: #f9f9f9;
  margin-right: 10px;
  margin-bottom: 20px;
  border: ${({clickstatus}) => clickstatus == true ? ('2px solid #ff0000') :('') };
  border-radius: 15px;

`

const RoomItems=[
  {name :ROOMSIZE.ONESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.TWOSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.THREESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.FOURSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.FIVESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.SIXSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.SEVENSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize1},

]

const Label = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight :400;



`

const PCRoomRegistercontainer =({containerStyle}) =>  {

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [menu, setMenu] = useState('');
  const [totalset, setTotalset] = useState(0);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setMenu(menu);
    setTotalset(totalset);
  },[refresh])


  useEffect(()=>{

    async function FetchData(){
     
    } 
    FetchData();
  }, [])


  const _handleroomselect = () =>{
    if(menu ==''){
      alert("대여할 공간형태를 선택해주세여");
      return;
    }
    navigate("/PCregist",{state :{WORKTYPE :menu, WORKTOTAL : totalset}});
  }
  const _handlemenuclick =(menu) =>{

    setMenu(menu);

    setTotalset(6);


    setRefresh((refresh) => refresh +1);
  }

  return (
    <>
      <Container style={containerStyle}>

      <FlexstartRow style={{background:"#fff", height:'80px', paddingLeft:'15%'}}>
          {
               menu != '' ? (<>
                <img src={Seekimage(menu)} style={{width:40, height:40, marginRight:20}}/>
                <Label>{menu}는 6단계만 거치면 대여할 공간 자유롭게 등록할수가 있습니다</Label>
                </>) :(<div>
                <Label>대여할 공간을 선택해 주세요</Label>
                </div>)
          }

            
            
        </FlexstartRow>


      <Column style={{width:'70%', margin:"0px auto",height:"95%"}}>
          {/* <TitleLayer><Title>대여할 공간을 선택해 주세요</Title></TitleLayer> */}
          <Row style={{flexWrap:"wrap", width:"100%"}}>
            {
              RoomItems.map((data, index)=>(
                <Box onClick={()=>{_handlemenuclick(data.name)}} clickstatus={menu == data.name}>
                  <div><img src={data.img} style={{width:64, height:64}}/></div>
                  <div style={{ fontSize:14}}>{data.name}</div>
                </Box>
              ))
            }
          


          </Row>


          <RegistButton onClick={_handleroomselect}><GoPlus size={24}/> 등록</RegistButton>

      </Column>


      </Container>


    </>


  );

}

export default PCRoomRegistercontainer;

