import React, { Fragment, useContext, useEffect, useInsertionEffect, useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { Badge, setRef } from "@mui/material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Image from "../../../common/Image";
import { WORKNAME } from "../../../utility/work";
import { WORK } from "../../../utility/db";
import { colors } from "../../../theme/theme";
import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import Categorymenu from "../../../common/Categorymenu";
import { CENTERTYPE, CONFIGMOVE, EventItems, EVENTTYPE, GUIDETYPE, LAWTYPE, LoadingType, PCMAINMENU } from "../../../utility/screen";


import { ROOMSIZE, ROOMSIZEDISPALY } from "../../../utility/room";
import { GoPlus } from "react-icons/go";
import { DataContext } from "../../../context/Data";
import { model } from "../../../api/config";
import PcAipopup from "../../../modal/PcAiPopup/PcAiPopup";
import Loading from "../../../components/Loading";
import localforage from 'localforage';

import { useDispatch, useSelector } from "react-redux";
import { ALLWORK, HOMECLEAN,BUSINESSCLEAN,
  MOVECLEAN,FOODPREPARE,ERRAND,GOOUTSCHOOL,BABYCARE,LESSON,PATIENTCARE,CARRYLOAD,
  GOHOSPITAL,RECIPETRANSMIT,GOSCHOOLEVENT,SHOPPING,GODOGHOSPITAL,GODOGWALK,ALLROOM, SMALLROOM, MEDIUMROOM, LARGEROOM, TOURREGION, TOURCOUNTRY, TOURFESTIVAL, TOURCITY, TOURPICTURE, PERFORMANCEEVENT, PERFORMANCECINEMA, MEDICALMEDICINE, MEDICALHOSPITAL, FOODHISTORY, FOODINFOMATION, CONVENIENCECAMPING, COMMUNITY,
  ONESIZE, TWOSIZE, THREESIZE, FIVESIZE, FOURSIZE, SIXSIZE, SEVENSIZE, EIGHTSIZE, RESET} from "../../../store/menu/MenuSlice"
import { CONVENIENCEMENU, FAMILYMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU, WEATHERMENU } from "../../../utility/life";

import "./PCMainheader.css";
import { sleep } from "../../../utility/common";
import { readuserbyphone, Update_usertoken } from "../../../service/UserService";



const PCHeader = styled.div`
  height: ${({height}) => height}px;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 1400px;


`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding:10px 0px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%);

`;


const OneHeaderMainMenu = styled.div`
  display: flex;
  padding-left: 24px;
  color : #ff7e19;
  font-size:22px;
  width:45%;
  align-items:center;
  justify-content: flex-start;
`;
const OneHeaderOptionMenu = styled.div`
  display: flex;
  flex-direction:row;
  font-size:14px;
  justify-content:space-around;
  width:40%;
`
const OneHeaderLoginMenu = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
  align-items: center;
`;

const EventDesc = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-left:20px;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
`

const MainMenuText = styled.div`
  font-size: 20px;
  color: ${({clickstatus}) => clickstatus == true ? ('#ff7e19') :('#131313') };
  font-weight: ${({clickstatus}) => clickstatus == true ? ('600') :('400') };
`

const EventMainText ={
  fontSize: '14px',
  color: '#fff',
  fontFamily:"Pretendard-SemiBold",
  fontWeight:600,
}

const EventSubText ={
  fontSize: '14px',
  color: '#fff',
  fontWeight:400,
}

const EventBtn ={
  display:"flex",
  justifyContent:"space-between",
  width:'30%',

}


const CategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height:30px;
`;
const CategoryItem = styled.div`
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  display: inline-block;
  color: #595959;

`




const OneContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;
  background-color: #f39435;
  position: fixed;
  z-index: 5;
  color: #595959;
  font-size:14px;
`;

const TwoContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  z-index: 5;


  
`;




const LineControl = styled.div`

  width: 100%;
  position: absolute;
  margin-top: 45px;
`;

const Inputstyle ={
  border: '1px solid #C3C3C3',
  background: '#fff',
  width: '100%',
  borderRadius:'5px',
  fontSize:'16px',
  padding :'8px'

}



const Searchstyle={
  position: "relative",
  left: '-35px'
}
const Search2style={
  position: "relative",
  left: '0px',
  top: '5px'
}


const LoginBtn = styled.div`
  padding: 10px 30px;
  background: #ffd6ac;
  border-radius: 10px;
`;

const NavItemLayer = styled.div`

  position: absolute;
  left : ${({left}) => left};
`
const DropdownContent = styled.div`
display: block;
border: none;
height: 110px;
position: absolute;
background-color: #fff;
width: 100%;
z-index: 1;
text-align: left;
color: #636363;
font-size: 14px;
flex-direction: row;
font-weight: 700;
padding: 15px;
top: 120px;
left: 10px;
font-size: 14px;
line-height: 2;
border-bottom: 1px solid #ededed;

`
const MedicalItems= [
  MEDICALMENU.MEDICALMEDICINE,
  MEDICALMENU.MEDICALHOSPITAL,

]

const Attendance = styled.div`

  font-family : Pretendard-SemiBold;
  background: #ff7e19;
  color: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  font-size:12px;
  width:80px;

`
const ButtonLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : space-between;

  width: 250px;



`
const SkipButton = styled.div`
  z-index: 10;
  background: #fff;
  border : 1px solid #ededed;
  color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 10px;
  padding :10px 20px;
  font-family:'Pretendard-SemiBold';
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`
const LoginButton = styled.div`
  z-index: 10;
  border : 1px solid #ededed;
  background: #fff;
  color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family:'Pretendard-SemiBold';
  border-radius: 10px;
  padding :10px 20px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`


const PCGateheader = ({ height}) => {


  const navigation = useNavigate();
  const {user, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const _handleNext =() =>{
    navigate("/PCPhone");
  }

  const _handleExperience = async() =>{
    // const PHONE = "01062149756";
    // const userdata = await readuserbyphone({PHONE});

    // // console.log("TCL: _handleCheck -> userdata", userdata)

    // user.phone = PHONE;
    // dispatch(user);

    // const DEVICEID = userdata.USERINFO.deviceid;
    // const TOKEN = user.token;
    // const LATITUDE = user.latitude;
    // const LONGITUDE = user.longitude;

    // if(TOKEN != ''){
    //   const usertoken = await Update_usertoken({DEVICEID, TOKEN });
    // }

    // // new Promise(resolve => setTimeout(resolve, 1000));

    // await sleep(1000);
    // user.users_id = userdata.USERS_ID;
    // user.deviceid = DEVICEID;
    // user.nickname = userdata.USERINFO.nickname;
    // user.userimg = userdata.USERINFO.userimg
    // user.latitude = userdata.USERINFO.latitude;
    // user.longitude = userdata.USERINFO.longitude;
    // user.address_name = userdata.USERINFO.address_name;


    // dispatch(user);


    // localforage.setItem('userconfig', user)
    // .then(function(value) {
    //   navigate("/PCMain");
    // })
    // .catch(function(err) {
    //   console.error('데이터 저장 실패:', err);
    // });

    navigate("/PCexperience");

  }


  return (
    <>
    <PCHeader height={height}>

      <TwoContainer id="twoheader">
        <BetweenRow style={{height:70, background:"#fff", width:'80%', margin:"0 auto"}} id="twosubheader">
          <OneHeaderMainMenu onClick={()=>{}}>
            <Row>      
              <img src={imageDB.logo} width={30} height={30} />
              <div style={{fontFamily:"Pretendard-Bold",display:"flex", justifyContent:"center", alignItems:"center", paddingLeft:10}}>구해줘 홍여사</div>
            </Row>
  
   
          </OneHeaderMainMenu>

          <ButtonLayer>

            <Link to="/PCGuideInfo"><a title="구해줘 홍여사 알아보기" aria-label="구해줘 홍여사 알아보기" style={{color:"#131313"}}>알아보기</a></Link>
            <Link to="/PCGuideLifeInfo"><a title="구해줘 홍여사 일상생활" aria-label="구해줘 홍여사 일상생활" style={{color:"#131313"}}>일상생활</a></Link>
            <Link to="/PCPhone"><a title="구해줘 홍여사 들어가기" aria-label="구해줘 홍여사 들어가기" style={{color:"#131313"}}>들어가기</a></Link>

            </ButtonLayer>

        </BetweenRow>


      
     
      </TwoContainer>

    </PCHeader>
    </>
  );
};

export default PCGateheader;
