import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';


import {motion} from 'framer-motion';




import { PiLockKeyLight } from "react-icons/pi"
import { sleep } from '../utility/common';
import { setRef } from '@mui/material';
import { DataContext } from '../context/Data';

function RotateExample({image, open, index, item}) {
  
  const [flip, setFlip] = useState(false);
  const [refresh, setRefresh] = useState(-1);
  const { datadispatch, data } = useContext(DataContext);

  const toggleFlip =async() => {
    setFlip(!flip);


    if(data.gameitems.length % 2 == 0 || data.gameitems.length ==0 ){
      data.gameitems.push(item);
      datadispatch(data);
    }else{
      const FindIndex = data.gameitems.findIndex(x=>x.image == item.image);

      if(FindIndex != -1){
        data.gameitems.push(item);
        datadispatch(data);

        await sleep(800);
        setFlip(true);
      
      }else{
        await sleep(800);
        setFlip(false);
      }
    }
   
   

  };

  
  useEffect(()=>{
    setFlip(flip);
  },[refresh])

  useEffect(()=>{


    async function OpenFunc(){
      if(index % 2 == 1){
        setFlip(true);
        await sleep(2000);
        setFlip(false);
      }else{
        await sleep(2000);
        setFlip(true);
        await sleep(1000);
        setFlip(false);
      }
    }
    OpenFunc();


  }, [])

  return (
    <div style={{ perspective: '1000px', margin:"3px 5px" }}>

      
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: flip ? 180 : 0 }}
        transition={{ duration: 1 }}
        style={{
          width: '70px',
          height: '70px',
          position: 'relative',
          transformStyle: 'preserve-3d', // 3D 회전 활성화
        }}
      
      >
        {/* Front Card */}
        <motion.div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#e2e2e2',
            backfaceVisibility: 'hidden', // 뒷면을 숨김
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: 'black',
            borderRadius: '10px',
          }}
          onClick={toggleFlip}
        >
          <PiLockKeyLight size={22}/>
         
        </motion.div>

        {/* Back Card */}
        <motion.div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#ee903361',
            backfaceVisibility: 'hidden', // 뒷면을 숨김
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: 'black',
            borderRadius: '10px',
            transform: 'rotateY(180deg)', // 180도 회전하여 뒤집힘
          }}
        >
          <img src={image} style={{width:50}}/>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default RotateExample;

