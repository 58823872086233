import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { EventItems, EVENTTYPE, FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCCOMMNUNITYMENU, PCMAINMENU } from "../../utility/screen";
import Communitymenu from "../../common/Communitymenu";
import CommunityItem from "../../components/CommunityItem";
import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { DataContext } from "../../context/Data";
import { sleep, useSleep } from "../../utility/common";
import QRCode from 'qrcode.react';
import Loading from "../../components/Loading";
import { ReadWork } from "../../service/WorkService";
import PCRoomItem from "../../components/PCRoomItem";
import { ReadRoom } from "../../service/RoomService";
import StoreInfo from "../../components/StoreInfo";
import Position from "../../components/Position";
import { ROOMSIZE } from "../../utility/room";
import { useSelector } from "react-redux";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import PCBanner from "../../components/PCBanner";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";
import SlickSliderComponentEx from "../../common/Swipeex";
import RoomSlickSliderComponentEx from "../../common/RoomSwipeex";

import { Helmet } from 'react-helmet';

const Container = styled.div`
  padding:0px 24px 100px 24px;

`
const style = {
  display: "flex"
};

const EventTitle = styled.div`
  font-size: 25px;
  line-height: 80px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  width : 85%;
  font-weight:500;

`
const EventBox = styled.div`

  margin-top:30px;
  width: calc(50% - 20px);
  margin-bottom: 30px;
  cursor: pointer;
  transition: .2s all;
  margin-left:15px;
  
`
const txtWrap = {
  backgroundColor:'#fafafa',
  padding: '18px 20px 24px',
  lineHeight:2
}

const tit ={
  fontSize: '18px',
  fontWeight: 700,
}

const day = {
  color: '#797979',
  fontSize: '16px'
}

const Label = styled.div`
  font-size: 18px;
  font-family : Pretendard-SemiBold;


`

const RoomItems=[
  {name :ROOMSIZE.ONESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.TWOSIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.THREESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.FOURSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},
  {name :ROOMSIZE.FIVESIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},
  {name :ROOMSIZE.SIXSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},
  {name :ROOMSIZE.SEVENSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},
  {name :ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},

]

const SmallRoomItems=[
  {name :ROOMSIZE.ONESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.TWOSIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},


]

const Small1RoomItems=[

  {name :ROOMSIZE.THREESIZE, img:imageDB.roomsize1, bgcolor:"#f9f9f9"},
  {name :ROOMSIZE.FOURSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},


]


const LargeRoomItems=[

  {name :ROOMSIZE.FIVESIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},
  {name :ROOMSIZE.SIXSIZE, img:imageDB.roomsize1, bgcolor:"#fff1c6"},


]
const Large1RoomItems=[


  {name :ROOMSIZE.SEVENSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},
  {name :ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize1, bgcolor:"#d5dee8"},

]

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 70px;
  width: 100px;
  margin-right: 30px;
  margin-bottom: 40px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  width : 100%;
  background: ${({bgcolor}) => bgcolor};
  border: ${({clickstatus}) => clickstatus == true ? ('1px solid') :('') };
  padding: 20px 10px;
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  box-shadow: 0 1px 2px rgba(0,0,0,0.03), 
  0 2px 4px rgba(0,0,0,0.03), 
  0 4px 8px rgba(0,0,0,0.03), 
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03), 
  0 32px 64px rgba(0,0,0,0.03);
`
const RegistButton = styled.div`
    height: 50px;
    width: 10%;
    border-radius: 100px;
    background: #FF7e19;
    color: #fff;
    margin: 10px auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
    &:active {
      transform: scale(0.95); /* 눌렀을 때 크기 조정 */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
    }
`
const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #ddd;
  margin-top: 5px;

`
const NewBox = styled.div`
  width: 20%;
  height: ${({height}) => height}px;
  border: 1px solid rgb(237, 237, 237);
  margin: 20px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0,0,0,0.03), 
  0 2px 4px rgba(0,0,0,0.03), 
  0 4px 8px rgba(0,0,0,0.03), 
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03), 
  0 32px 64px rgba(0,0,0,0.03);

`

const ButtonSt = styled.div`
height: 44px;
width: 85%;
border-radius: 4px;
background: #ffe797;
color: #131313;
display: flex;
justify-content: center;
align-items: center;
border: none;
font-size: 18px;
margin: 0px auto;
font-family: "Pretendard-Regular";
cursor: pointer;
margin-bottom:10px;
cursor: pointer;
transition: transform 0.1s ease, box-shadow 0.1s ease;

&:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
}
`
const BoxHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  width :100%;
  padding: 15px 0px;
`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const BannerItems =[
  {image : imageDB.roombannerex4, maintext:"인공지능이 탑재된 홍여사", subtext1:"집안일에 필요한 모든것", subtext2:"홍여사 AI가 알려드립니다", buttontype: 3},
  {image : imageDB.roombannerex1, maintext:"급하게 처리해야 하는 집안일", subtext1:"3단계 10초만에 일감요청", subtext2:"", buttontype: 1},
  {image : imageDB.roombannerex2, maintext:"모든 거래는 계약서로", subtext1:"공식화된 계약서에", subtext2:"정식서명으로 계약 완성", buttontype: 2},
  {image : imageDB.roombannerex3, maintext:"인공지능이 탑재된 홍여사", subtext1:"집안일에 필요한 모든것", subtext2:"홍여사 AI가 알려드립니다", buttontype: 3},

]

const PCRoomcontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [roomitems, setRoomitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState('');
  const [totalset, setTotalset] = useState(0);
  const [popupstatus, setPopupstatus] = useState(false);

  const _handlemenuclick =(menu) =>{

    setMenu(menu);

    setTotalset(6);


    setRefresh((refresh) => refresh +1);
  }


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setMenu(menu);
    setTotalset(totalset);
    setPopupstatus(popupstatus);
  },[refresh])

    /**
   * 팝업 노출여부를 확인 한다(hongpopup1, hongpopup2, hongpopup3 를 localstorage에서 가져온다
   * ! 홍여사 요청 업무를 초기 로딩시에 구해온 데이타로 세팅 한다
   * ! 현재 페이지에서 리플레시 햇을때 서버에서 데이타를 구해 올수 있어야 한다 서비스 사용 : ReadWork()
   * 
   */
    useEffect(()=>{
      const now = moment();
  
      async function FetchData(){
  
        await sleep(1500);
        const today = moment(); // 현재 날짜
        const yesterday = today.clone().subtract(1, 'days'); // 1일 전 날짜
        const diffInDays = moment(today).diff(today, 'days');
        let time = moment(now).subtract(1, "days").unix();
        const popupdate = window.localStorage.getItem("hongroompopup");
        if (popupdate /1000 < time) {
          setPopupstatus(true);
        }
      } 
  
      FetchData();
  
    }, [])
  

  const _handleroomselect = (menu) =>{
    if(menu ==''){
      alert("대여할 공간형태를 선택해주세여");
      return;
    }
    navigate("/PCregist",{state :{WORKTYPE :menu, WORKTOTAL : totalset}});
  }



    

  // const _handleSelectWork = (ROOM_ID) =>{
  //   navigate("/PCmap" ,{state :{ID :ROOM_ID, TYPE : FILTERITMETYPE.ROOM}});
  // }


  const positioncallback = () =>{
    setCurrentloading(true);
    setRefresh((refresh) => refresh +1);
  } 
  
  const _handlecurrentloadingcallback = ()=> {
    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);
  }

  const popupcallback= () =>{
    setPopupstatus(false);
    setRefresh((refresh) => refresh +1);
  }
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://honglady.com/PCRoom",
    "name": "구해줘 홍여사 공간대여",
 
  };
  return (
    <>

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

    {
      currentloading == true && <Loading type={LoadingType.CURRENTPOS} callback={_handlecurrentloadingcallback}/>
    }
    


    {/* <PCBanner backgroundcolor={"#d2edfa"} 
     main1={'남아 있는 집 공간'} main2={'짐을 보관'}
    top={'50px'}
    sub1={'겨울짐 캠핑짐등 보관이 필요한'} 
    sub2={'짐을 맡아주고 돈벌어보세요'} 
    imagewidth={'230px'}
    // sub3={'짐을 맡길사람 짐을 맡을 사람 모두 구해줘 홍여사에서 매칭해드립니다'} 
    image={imageDB.roomgroup}
    /> */}

    <Column style={{width:"100%", margin: "0px auto 0px"}}>
    <RoomSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#ec8260'}/>
    </Column>

     
    <Container style={containerStyle}>
    {/* {popupstatus == true ? (
        <PcAdvertisePopup type ={3}  callback={popupcallback} top={'60%'}  left={'25%'} height={'400px'} width={'500px'} image={imageDB.roombanner}></PcAdvertisePopup>
      ) : null} */}

    <div style={{display:"flex", flexDirection:"column"}}>
 
      {/* {
        searching == true  ? (<LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'} />):(
            <>
              
              {
                  roomitems.length == 0 && <Empty content = "공간대여가 존재 하지 않습니다"
                  containerStyle={{marginTop:"150px"}}
                  />
              }
                
              <FlexstartRow style={{flexWrap:"wrap", justifyContent:"space-around"}}
              className="WorkLayer">
              {
                  roomitems.map((item, index)=>(
                    <MobileRoomItem key={index}  index={index} width={'23%'} roomdata={item} onPress={()=>{_handleSelectWork(item.ROOM_ID)}}/>
                  ))
                }
              </FlexstartRow>
            
            </>
          )
      } */}

  

        <FlexstartRow style={{background:"#fff", alignItems:"center", width:"100%", marginTop:"10px",justifyContent:"space-between"}}>
          {
              menu != '' ? (<>
                {/* <img src={Seekimage(menu)} style={{width:40, height:40, marginRight:20}}/> */}
                <Label>6단계만 거치면 대여할 공간 자유롭게 등록할수가 있습니다</Label>
                </>) :(<div>
                <Label>대여할 공간을 선택해 주세요</Label>
                </div>)
          }
          <Position type={PCMAINMENU.ROOMMENU} callback={positioncallback}/>
        </FlexstartRow>
        <Line></Line>


      <FlexstartColumn style={{width:'100%', margin:"40px auto 10px", justifyContent:"center"}}>
          {/* <TitleLayer><Title>대여할 공간을 선택해 주세요</Title></TitleLayer> */}
          <FlexstartRow style={{flexWrap:"wrap", width:"80%",margin:"0 auto"}}>
            {/* {
              RoomItems.map((data, index)=>(
                <Box onClick={()=>{_handlemenuclick(data.name)}} >
      
                  <BoxImg bgcolor ={data.bgcolor} clickstatus={menu == data.name}>
                  
                  <div style={{ fontSize:16,  fontFamily:"Pretendard-SemiBold"}}>{data.name}</div>
                  <div style={{ fontSize:16, marginTop:5, fontFamily:"Pretendard-SemiBold"}}>공간</div>
                  </BoxImg>


                </Box>
              ))
            } */}

            <NewBox height={200}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>1평이하</div></Row>
              </BoxHeader>

              {
                SmallRoomItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleroomselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>

            <NewBox height={200}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>1평이하</div></Row>
              </BoxHeader>

              {
                Small1RoomItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleroomselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>

            <NewBox height={200}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>1평초과</div></Row>
              </BoxHeader>

              {
                LargeRoomItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleroomselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>

            <NewBox height={200}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>1평초과</div></Row>
              </BoxHeader>

              {
                Large1RoomItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleroomselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>
          
          </FlexstartRow>


          {/* <RegistButton onClick={_handleroomselect}><GoPlus size={34}/> 등록</RegistButton> */}

      </FlexstartColumn>

    </div>

  

    </Container>
    <StoreInfo />
    
    </>
  );

}

export default PCRoomcontainer;

