import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME, WORKPOLICY } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Empty from "../../components/Empty";
import localforage from 'localforage';
import { getDateEx, getDateFullTime } from "../../utility/date";
import { readuserbydeviceid, Readuserbyusersid } from "../../service/UserService";
import { sleep } from "../../utility/common";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import PCBanner from "../../components/PCBanner";

import { Helmet } from 'react-helmet';
import SlickSliderComponent from "../../common/Swipe";
import SlickSliderComponentEx from "../../common/Swipeex";
import ButtonEx from "../../common/ButtonEx";


const Container = styled.div`
  padding:0px 24px 0px 24px;

`


const style = {
  display: "flex"
};

const Label = styled.div`
  font-size: 18px;
  font-family : Pretendard-SemiBold;



`
const RegistButton = styled.div`
    height: 50px;
    width: 10%;
    border-radius: 100px;
    background: #FF7e19;
    color: #fff;
    margin: 0px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
    &:active {
      transform: scale(0.95); /* 눌렀을 때 크기 조정 */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
    }
`
const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 5.5%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`

const NewBoxItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:row;
  height: 90px;
  width: 30%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`

const BoxImg = styled.div`
  background: ${({bgcolor}) => bgcolor};
  border-radius: 100px;
  border: ${({clickstatus}) => clickstatus == true ? ('1px solid') :('') };
  padding: 10px;
  height:50px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.03), 
  0 2px 4px rgba(0,0,0,0.03), 
  0 4px 8px rgba(0,0,0,0.03), 
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03), 
  0 32px 64px rgba(0,0,0,0.03);
`

const NewBoxImg = styled.div`
  background: #fff;
  border-radius: 100px;
  height: 50px;

`


const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #ddd;
  margin-top: 5px;

`
const NewBox = styled.div`
  width: 15%;
  height: ${({height}) => height}px;
  border: 1px solid rgb(237, 237, 237);
  margin: 20px;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0,0,0,0.03), 
  0 2px 4px rgba(0,0,0,0.03), 
  0 4px 8px rgba(0,0,0,0.03), 
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03), 
  0 32px 64px rgba(0,0,0,0.03);

`

const ButtonSt = styled.div`
height: 44px;
width: 85%;
border-radius: 4px;
background: #ffe797;
color: #131313;
display: flex;
justify-content: center;
align-items: center;
border: none;
font-size: 18px;
margin: 0px auto;
font-family: "Pretendard-Regular";
cursor: pointer;
margin-bottom:10px;
cursor: pointer;
transition: transform 0.1s ease, box-shadow 0.1s ease;

&:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
}
`
const BoxHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  width :100%;
  padding: 15px 0px;
`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const CleanWorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house, bgcolor:"#e8e7e7"},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business, bgcolor:"#e8e7e7"},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move, bgcolor:"#e8e7e7"},
]

const HouseWorkItems=[
  {name :WORKNAME.ERRAND, img:imageDB.help, bgcolor:"#fff1c6"},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry, bgcolor:"#fff1c6"},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe, bgcolor:"#fff1c6"},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook, bgcolor:"#fff1c6"},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping, bgcolor:"#fff1c6"},
]

const BabyWorkItems=[
  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool, bgcolor:"#d5dee8"},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare, bgcolor:"#d5dee8"},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent, bgcolor:"#d5dee8"},
  {name :WORKNAME.LESSON, img:imageDB.lesson, bgcolor:"#d5dee8"},
]

const HospitalWorkItems=[
  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare, bgcolor:"#ffdb6b"},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital, bgcolor:"#ffdb6b"},
]

const DogWorkItems=[
  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital, bgcolor:"#f9f9f9"},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog, bgcolor:"#f9f9f9"},
]

const WorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house, bgcolor:"#e8e7e7"},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business, bgcolor:"#e8e7e7"},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move, bgcolor:"#e8e7e7"},


  {name :WORKNAME.ERRAND, img:imageDB.help, bgcolor:"#fff1c6"},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry, bgcolor:"#fff1c6"},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe, bgcolor:"#fff1c6"},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook, bgcolor:"#fff1c6"},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping, bgcolor:"#fff1c6"},

  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool, bgcolor:"#d5dee8"},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare, bgcolor:"#d5dee8"},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent, bgcolor:"#d5dee8"},
  {name :WORKNAME.LESSON, img:imageDB.lesson, bgcolor:"#d5dee8"},

  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare, bgcolor:"#ffdb6b"},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital, bgcolor:"#ffdb6b"},



  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital, bgcolor:"#f9f9f9"},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog, bgcolor:"#f9f9f9"},
]

const BannerItems =[
  {image : imageDB.workbannerex1, maintext:"급하게 처리해야 하는 집안일", subtext1:"3단계 10초만에 일감요청", subtext2:"", buttontype: 1},
  {image : imageDB.workbannerex2, maintext:"모든 거래는 계약서로", subtext1:"공식화된 계약서에", subtext2:"정식서명으로 계약 완성", buttontype: 2},
  {image : imageDB.workbannerex3, maintext:"인공지능이 탑재된 홍여사", subtext1:"집안일에 필요한 모든것", subtext2:"홍여사 AI가 알려드립니다", buttontype: 3},
  {image : imageDB.workbannerex4, maintext:"홍여사 세상으로 접속", subtext1:"일상생활을 공유", subtext2:"관광지도 캠핑장정보 의약품정보", buttontype: 4},
  {image : imageDB.workbannerex5, maintext:"이벤트", subtext1:"출석이벤트 룰렛이벤트", buttontype: 5},

]

/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */


const PCMaincontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [workitems, setWorkitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState('');
  const [totalset, setTotalset] = useState(0);

  const _handlemenuclick =(checkmenu) =>{

    setMenu(checkmenu);

    let totalset = 0;

    if(checkmenu == WORKNAME.HOMECLEAN){
      setTotalset(WORKPOLICY.HOMECLEAN);
      totalset = WORKPOLICY.HOMECLEAN;
    }else if(checkmenu == WORKNAME.BUSINESSCLEAN){
      setTotalset(WORKPOLICY.BUSINESSCLEAN);
      totalset = WORKPOLICY.BUSINESSCLEAN;
    }else if(checkmenu == WORKNAME.MOVECLEAN){
      setTotalset(WORKPOLICY.MOVECLEAN);
      totalset = WORKPOLICY.MOVECLEAN;
    }else if(checkmenu == WORKNAME.FOODPREPARE){
      setTotalset(WORKPOLICY.FOODPREPARE);
      totalset = WORKPOLICY.FOODPREPARE;
    }else if(checkmenu == WORKNAME.GOOUTSCHOOL){
      setTotalset(WORKPOLICY.GOOUTSCHOOL);
      totalset = WORKPOLICY.GOOUTSCHOOL;
    }else if(checkmenu == WORKNAME.BABYCARE){
      setTotalset(WORKPOLICY.BABYCARE);
      totalset = WORKPOLICY.BABYCARE;
    }else if(checkmenu == WORKNAME.LESSON){
      setTotalset(WORKPOLICY.LESSON);
      totalset = WORKPOLICY.LESSON;
    }else if(checkmenu == WORKNAME.PATIENTCARE){
      setTotalset(WORKPOLICY.PATIENTCARE);
      totalset = WORKPOLICY.PATIENTCARE;
    }else if(checkmenu == WORKNAME.GOHOSPITAL){
      setTotalset(WORKPOLICY.GOHOSPITAL);
      totalset = WORKPOLICY.GOHOSPITAL;
    }else if(checkmenu == WORKNAME.RECIPETRANSMIT){
      setTotalset(WORKPOLICY.RECIPETRANSMIT);
      totalset = WORKPOLICY.RECIPETRANSMIT;
    }else if(checkmenu == WORKNAME.GOSCHOOLEVENT){
      setTotalset(WORKPOLICY.GOSCHOOLEVENT);
      totalset = WORKPOLICY.GOSCHOOLEVENT;
    }else if(checkmenu == WORKNAME.GODOGHOSPITAL){
      setTotalset(WORKPOLICY.GODOGHOSPITAL);
      totalset = WORKPOLICY.GODOGHOSPITAL;
    }else if(checkmenu == WORKNAME.GODOGWALK){
      setTotalset(WORKPOLICY.GODOGWALK);
      totalset = WORKPOLICY.GODOGWALK;
    }else if(checkmenu == WORKNAME.CARRYLOAD){
      setTotalset(WORKPOLICY.CARRYLOAD);
      totalset = WORKPOLICY.CARRYLOAD;
    }else if(checkmenu == WORKNAME.ERRAND){
      setTotalset(WORKPOLICY.ERRAND);
      totalset = WORKPOLICY.ERRAND;
    }else if(checkmenu == WORKNAME.SHOPPING){
      setTotalset(WORKPOLICY.SHOPPING);
      totalset = WORKPOLICY.SHOPPING;
    }


    setRefresh((refresh) => refresh +1);
  }

  const _handleworkselect = (checkmenu) =>{
    // if(menu ==''){
    //   alert("일감 형태를 선택해주세여");
    //   return;
    // }

    // console.log("TCL: _handleworkselect -> totalset", totalset);

    setMenu(checkmenu);

    let totalset = 0;

    if(checkmenu == WORKNAME.HOMECLEAN){
      setTotalset(WORKPOLICY.HOMECLEAN);
      totalset = WORKPOLICY.HOMECLEAN;
    }else if(checkmenu == WORKNAME.BUSINESSCLEAN){
      setTotalset(WORKPOLICY.BUSINESSCLEAN);
      totalset = WORKPOLICY.BUSINESSCLEAN;
    }else if(checkmenu == WORKNAME.MOVECLEAN){
      setTotalset(WORKPOLICY.MOVECLEAN);
      totalset = WORKPOLICY.MOVECLEAN;
    }else if(checkmenu == WORKNAME.FOODPREPARE){
      setTotalset(WORKPOLICY.FOODPREPARE);
      totalset = WORKPOLICY.FOODPREPARE;
    }else if(checkmenu == WORKNAME.GOOUTSCHOOL){
      setTotalset(WORKPOLICY.GOOUTSCHOOL);
      totalset = WORKPOLICY.GOOUTSCHOOL;
    }else if(checkmenu == WORKNAME.BABYCARE){
      setTotalset(WORKPOLICY.BABYCARE);
      totalset = WORKPOLICY.BABYCARE;
    }else if(checkmenu == WORKNAME.LESSON){
      setTotalset(WORKPOLICY.LESSON);
      totalset = WORKPOLICY.LESSON;
    }else if(checkmenu == WORKNAME.PATIENTCARE){
      setTotalset(WORKPOLICY.PATIENTCARE);
      totalset = WORKPOLICY.PATIENTCARE;
    }else if(checkmenu == WORKNAME.GOHOSPITAL){
      setTotalset(WORKPOLICY.GOHOSPITAL);
      totalset = WORKPOLICY.GOHOSPITAL;
    }else if(checkmenu == WORKNAME.RECIPETRANSMIT){
      setTotalset(WORKPOLICY.RECIPETRANSMIT);
      totalset = WORKPOLICY.RECIPETRANSMIT;
    }else if(checkmenu == WORKNAME.GOSCHOOLEVENT){
      setTotalset(WORKPOLICY.GOSCHOOLEVENT);
      totalset = WORKPOLICY.GOSCHOOLEVENT;
    }else if(checkmenu == WORKNAME.GODOGHOSPITAL){
      setTotalset(WORKPOLICY.GODOGHOSPITAL);
      totalset = WORKPOLICY.GODOGHOSPITAL;
    }else if(checkmenu == WORKNAME.GODOGWALK){
      setTotalset(WORKPOLICY.GODOGWALK);
      totalset = WORKPOLICY.GODOGWALK;
    }else if(checkmenu == WORKNAME.CARRYLOAD){
      setTotalset(WORKPOLICY.CARRYLOAD);
      totalset = WORKPOLICY.CARRYLOAD;
    }else if(checkmenu == WORKNAME.ERRAND){
      setTotalset(WORKPOLICY.ERRAND);
      totalset = WORKPOLICY.ERRAND;
    }else if(checkmenu == WORKNAME.SHOPPING){
      setTotalset(WORKPOLICY.SHOPPING);
      totalset = WORKPOLICY.SHOPPING;
    }


    setRefresh((refresh) => refresh +1);

    navigate("/PCregist",{state :{WORKTYPE :checkmenu, WORKTOTAL : totalset}});

  }
  
  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setSearching(searching);
  },[refresh])

  /**
   * 팝업 노출여부를 확인 한다(hongpopup1, hongpopup2, hongpopup3 를 localstorage에서 가져온다
   * ! 홍여사 요청 업무를 초기 로딩시에 구해온 데이타로 세팅 한다
   * ! 현재 페이지에서 리플레시 햇을때 서버에서 데이타를 구해 올수 있어야 한다 서비스 사용 : ReadWork()
   * 
   */
  // useEffect(()=>{
  //   const now = moment();

  //   async function FetchData(){

  //     await sleep(1500);
  //     const today = moment(); // 현재 날짜
  //     const yesterday = today.clone().subtract(1, 'days'); // 1일 전 날짜
  //     const diffInDays = moment(today).diff(today, 'days');
  //     let time = moment(now).subtract(1, "days").unix();
  //     const popupdate = window.localStorage.getItem("hongmainpopup");
  //     if (popupdate /1000 < time) {
  //       setPopupstatus(true);
  //     }
  //   } 

  //   FetchData();

  // }, [])

  /**
   * useSelector menu 가 변경됨을 감지 함에 따라 호출되는  Hook 함수
   * 데이타를 서버로 부터 불러와서 FilterwokrItems 함수로 걸러진값을 workitems 에 설정 해준다
   */




  const popupcallback = async (data) => {

    setPopupstatus(!popupstatus);
  };



  /**
   *  필터 값에 의해서 다시 데이타를 정리 해주는 함수
   *  WORKNAME.ALLWORK 인경우는 모든 값을 보여준다
   */
  function FilterWorkitems(filter, workitems){
    let items = [];
    workitems.map((data) =>{

      if(filter == WORKNAME.ALLWORK || filter == "REFRESH")
      {
        items.push(data);
      }else{
        if(data.WORKTYPE == filter){
          items.push(data);
        }
      }
    })
    return items;
  }

  /**
   * 단위 일감에서 해당 일감을 클릭햇을때 내주변으로 이동 할수 있도록 한다
   * @param 해당 work_id 와 타입을 보내주어야 한다
   */
  const _handleSelectWork = (WORK_ID) =>{
    navigate("/PCmap" ,{state :{ID :WORK_ID, TYPE : FILTERITMETYPE.HONG}});

  }


  const _handlecurrentloadingcallback = ()=> {
    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);
  }

  // function workfilterapply(menu, items){

  //   let itemsTmp = [];

  //   if(items == -1){
  //     return itemsTmp;
  //   }

  //   if(menu == WORKNAME.ALLWORK || menu ==''){
  //     return items;
  //   }
  //   items.map((data)=>{
  //     if(data.WORKTYPE == menu){
  //       itemsTmp.push(data);
  //     }
  //   })

  //   return itemsTmp;

  // }
  const positioncallback = () =>{

  }
  function checkworkstep(menu){
    if(menu == WORKNAME.HOMECLEAN){
      return WORKPOLICY.HOMECLEAN;
    }else if(menu == WORKNAME.BUSINESSCLEAN){
      return WORKPOLICY.BUSINESSCLEAN;
    }else if(menu == WORKNAME.MOVECLEAN){
      return WORKPOLICY.MOVECLEAN;
    }else if(menu == WORKNAME.FOODPREPARE){
      return WORKPOLICY.FOODPREPARE;
    }else if(menu == WORKNAME.GOOUTSCHOOL){
      return WORKPOLICY.GOOUTSCHOOL;
    }else if(menu == WORKNAME.BABYCARE){
      return WORKPOLICY.BABYCARE;
    }else if(menu == WORKNAME.LESSON){
      return WORKPOLICY.LESSON;
    }else if(menu == WORKNAME.PATIENTCARE){
      return WORKPOLICY.PATIENTCARE;
    }else if(menu == WORKNAME.GOHOSPITAL){
      return WORKPOLICY.GOHOSPITAL;
    }else if(menu == WORKNAME.RECIPETRANSMIT){
      return WORKPOLICY.RECIPETRANSMIT;
    }else if(menu == WORKNAME.GOSCHOOLEVENT){
      return WORKPOLICY.GOSCHOOLEVENT;
    }else if(menu == WORKNAME.GODOGHOSPITAL){
      return WORKPOLICY.GODOGHOSPITAL;
    }else if(menu == WORKNAME.GODOGWALK){
      return WORKPOLICY.GODOGWALK;
    }else if(menu == WORKNAME.CARRYLOAD){
      return WORKPOLICY.CARRYLOAD;
    }else if(menu == WORKNAME.ERRAND){
      return WORKPOLICY.ERRAND;
    }else if(menu == WORKNAME.SHOPPING){
      return WORKPOLICY.SHOPPING;
    }


    if(menu == WORKNAME.HOMECLEAN){
      return WORKPOLICY.HOMECLEAN;
    }else if(menu == WORKNAME.BUSINESSCLEAN){
      return WORKPOLICY.BUSINESSCLEAN;
    }else if(menu == WORKNAME.MOVECLEAN){
      return WORKPOLICY.MOVECLEAN;
    }else if(menu == WORKNAME.FOODPREPARE){
      return WORKPOLICY.FOODPREPARE;
    }else if(menu == WORKNAME.ERRAND){
      return WORKPOLICY.ERRAND;
    }else if(menu == WORKNAME.GOOUTSCHOOL){
      return WORKPOLICY.GOOUTSCHOOL;
    }else if(menu == WORKNAME.BABYCARE){
      return WORKPOLICY.BABYCARE;
    }else if(menu == WORKNAME.LESSON){
      return WORKPOLICY.LESSON;
    }else if(menu == WORKNAME.PATIENTCARE){
      return WORKPOLICY.PATIENTCARE;
    }else if(menu == WORKNAME.CARRYLOAD){
      return WORKPOLICY.CARRYLOAD;
    }else if(menu == WORKNAME.GOHOSPITAL){
      return WORKPOLICY.GOHOSPITAL;
    }else if(menu == WORKNAME.RECIPETRANSMIT){
      return WORKPOLICY.RECIPETRANSMIT;
    }else if(menu == WORKNAME.GOSCHOOLEVENT){
      return WORKPOLICY.GOSCHOOLEVENT;
    }else if(menu == WORKNAME.SHOPPING){
      return WORKPOLICY.SHOPPING;
    }else if(menu == WORKNAME.GODOGHOSPITAL){
      return WORKPOLICY.GODOGHOSPITAL;
    }else if(menu == WORKNAME.GODOGWALK){
      return WORKPOLICY.GODOGWALK;
    }
  }

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://honglady.com/PCMain",
    "name": "구해줘 홍여사 일감요청",
 
  };

  return (
    <>

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

    {
      currentloading == true && <Loading type={LoadingType.CURRENTPOS} callback={_handlecurrentloadingcallback}/>
    }

      <Column style={{width:"100%", margin: "0px auto 0px"}}>
      <SlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#ec8260'}/>
      </Column>

      {/* <PCBanner backgroundcolor={'#FFF1AA'} top={'50px'}
      main1={'도움이 필요한 집안일'} main2={'간편하게 도움 요청'} sub1={'3단계만 거치면 필요한 집안일을'} sub2={'요청할수가 있어요 '} 
      image={imageDB.honggroup} imagewidth={'300px'}/> */}
     
      <Container style={containerStyle}>

      {/* {popupstatus == true ? (
        <PcAdvertisePopup type ={1}  callback={popupcallback} top={'60%'}  left={'25%'} height={'550px'} width={'500px'} image={imageDB.sample12}></PcAdvertisePopup>
      ) : null} */}
      <div style={{display:"flex", flexDirection:"column"}}>

        <FlexstartRow style={{background:"#fff", alignItems:"center", width:"100%", marginTop:"10px",justifyContent:"space-between"}}>
          {
               menu != '' ? (<>
                <Label>{menu}는 {checkworkstep(menu)}단계만 거치면 도움요청을 등록할수가 있습니다</Label>
                </>) :(<div>
                <Label>도움이 필요한 내용을 자유롭게 등록하세요</Label>
                </div>)
          }
          <Position type={PCMAINMENU.HOMEMENU} callback={positioncallback}/>
        </FlexstartRow>
        <Line></Line>
        <FlexstartColumn style={{width:'100%', margin:"30px auto 10px", justifyContent:"center"}}>
     
          <Row style={{flexWrap:"wrap", width:"80%", margin:"0 auto"}}>
            {/* {
              WorkItems.map((data, index)=>(
                <Box onClick={()=>{_handlemenuclick(data.name)}} >
                  <BoxImg bgcolor ={data.bgcolor} clickstatus={menu == data.name}><img src={data.img} style={{width:40, height:40}}/></BoxImg>
                  <div style={{ fontSize:14, marginTop:10, fontFamily:"Pretendard-SemiBold"}}>{data.name}</div>
                </Box>
              ))
            } */}

            <NewBox height={340}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>청소하기</div></Row>
              </BoxHeader>

              {
                CleanWorkItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleworkselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>
            <NewBox height={340}>
              <BoxHeader>
                 <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>집안일</div></Row>
              </BoxHeader>
              {
                HouseWorkItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleworkselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>
            <NewBox height={340}>
              <BoxHeader>
                <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>아이돌봄</div></Row>
              </BoxHeader>
              {
                BabyWorkItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleworkselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }
            </NewBox>
            <NewBox height={340}>
            <BoxHeader>
                <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>간병하기</div></Row>
              </BoxHeader>
              {
                HospitalWorkItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleworkselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }

            </NewBox>
            <NewBox height={340}>
            <BoxHeader>
                <Row><div style={{fontSize: "25px",fontFamily: 'Pretendard-SemiBold'}}>반려동물</div></Row>
              </BoxHeader>
              {
                DogWorkItems.map((data, index)=>(
                  <ButtonSt onClick={()=>{_handleworkselect(data.name)}}>{data.name}</ButtonSt>
                ))
              }
            </NewBox>

          </Row>
          {/* <RegistButton onClick={_handleworkselect}><GoPlus size={34}/> 등록</RegistButton> */}
        </FlexstartColumn>
      </div>
      </Container>
      <StoreInfo />
    </>


  );

}

export default PCMaincontainer;

