import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";


import {  SubKeywordAddress } from "../../../utility/region";
import { FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";

import localforage from 'localforage';

import MobileGpsPopup from "../../../modal/MobileGpsPopup/MobileGpsPopup";

import "../../css/common.css"

const Container = styled.div`


`;





const Mobileheader = ({callback, registbtn, name}) => {

  const {value} = useSelector((state)=> state.menu);
  const navigation = useNavigate();
  const { user, dispatch } = useContext(UserContext);
  const [refresh, setRefresh] = useState(1);
  const [registbutton, setReigstbutton] = useState(false);
  const [address_name, setAddress_name] = useState(user.address_name);

  const [gpspopup, setGpspopup] = useState(false);
  const [unreadcount, setUnreadcount] = useState(0);

  useEffect(() => {
    setAddress_name(address_name);
    setReigstbutton(registbutton);
    setGpspopup(gpspopup);
    setUnreadcount(unreadcount);
  }, [refresh]);




  useLayoutEffect(()=>{
    localforage.getItem('userconfig')
    .then(function(value) {
      console.log("TCL: listener -> GetItem value", value.address_name)
      setAddress_name(value.address_name);
    })
    .catch(function(err) {

    });

  
    setRefresh((refresh) => refresh +1);
  },[value])






  const _handlemapreconfig = () =>{
    navigation("/Mobilemapreconfig",{state :{TYPE : "ADDRREGIST"}});

  }

  const  _handlemapgps = () =>{
    setGpspopup(true);
    setRefresh((refresh) => refresh +1);
  }
  const gpspopupcallback = () =>{
    setGpspopup(false);
    setRefresh((refresh) => refresh +1); 
  }



  const _handleConfig=()=>{
    navigation("/Mobileprofile");
  }





/**
 * 마우스를 움직일때 사라지고 없어지고 한다
 * ! id 값 : oneheader, twohenader
 */
useEffect(() => {
  const handleShowButton = () => {

    if (window.scrollY > 10) {
      setReigstbutton(true);
    } else {
      setReigstbutton(false);
    }
    setRefresh((refresh)=> refresh +1);
  };

  window.addEventListener("scroll", handleShowButton);

  return () => {
    window.removeEventListener("scroll", handleShowButton);
  };
}, []);

  return (
    <Container
      id="header"
      style={{
        zIndex: 999,
        position: "fixed",
        background: "#fff",
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily :"Pretendard-SemiBold",
        flexShrink: 0
  
      }}
    >


     

        <div style={{paddingLeft:"15px", display:"flex",color:"#131313", fontSize:"18px",
        display:"flex", justifyContent:"flex-start", alignItems:"center",fontFamily:"Pretendard-SemiBold"}}>
      
          {/* <Row> 
            <img src={imageDB.logo} width={28} height={24} />
            <div style={{fontFamily:"Pretendard-Bold", fontSize:'18px', color:"#ff7e19"}}>구해줘	홍여사</div>
          </Row> */}

          {/* <img src={imageDB.mappin} style={{width:20, height:20, marginLeft:5}} onClick={_handlemapgps}/> */}
   
          <div style={{ width:'100%', display:"flex",color:"#131313", 
          fontSize:"18px",display:"flex", justifyContent:"flex-start", alignItems:"center",}}>
            <img src={imageDB.mappin} style={{width:20, height:20}} onClick={_handlemapgps}/>
            <div style={{marginLeft:5}}>{SubKeywordAddress(address_name)}</div>
            <FaChevronRight onClick={_handlemapreconfig}/>
          </div>

          {/* <FaChevronRight onClick={_handlemapreconfig} /> */}

          {
            gpspopup == true && <MobileGpsPopup callback={gpspopupcallback} />
          }

        </div>

     
        <div style={{display:"flex", flexDirection:"row", alignItems:"center",paddingRight:10}} >



        <img src={imageDB.profile}  style={{width:"30px", height:"30px"}} onClick={_handleConfig}/>

        
        {/* <img src={imageDB.community} width={24} onClick={_handleCommunity} />  */}

        {/* <img src={imageDB.search} width={24} onClick={_handleAI} style={{paddingRight:10}}/> */}
        {/* <RiCommunityLine size={25}  onClick={_handleCommunity}/> */}
          {/* <Badge badgeContent={unreadcount} color="warning" style={{paddingBottom:10}}></Badge>  */}
        </div>
    


    
    </Container>
  );
};

export default Mobileheader;
