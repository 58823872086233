import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";



const Container = styled.div`
    height: 44px;
    width: ${({width})=> width}%;
    border-radius: 4px;
    background: ${({bgcolor})=> bgcolor};
    color :${({color})=> color};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 18px;
    margin: 0px auto;
    font-family:"Pretendard-SemiBold";
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
    &:active {

      transform: scale(0.95); /* 눌렀을 때 크기 조정 */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
    }


`
const style = {
  display: "flex"
};

const ButtonEx =({containerStyle, text, width, bgcolor, color, onPress}) =>  {

  return (

    <Container 
    onClick={onPress} 
    style={containerStyle}  width={width} bgcolor={bgcolor} color={color}>
        <div>{text}</div>
    </Container>
  );

}

export default ButtonEx;

