import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PCExperiencecontainer from "../../container/PCmain/PCExperiencecontainer";
import PCGatecontainer from "../../container/PCmain/PCGatecontainer";

import { UserContext } from "../../context/User";
import PCGateLayout from "../../screen/LayoutPC/Layout/PCGateLayout";




const Container = styled.div`

`
const style = {
  display: "flex"
};

const PCExperiencepage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);



 
  return (
    <PCExperiencecontainer/>
  );

}

export default PCExperiencepage;

