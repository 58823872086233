import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { sleep, useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { BetweenRow, Row } from "../../common/Row";
import { readuserbyphone, Update_usertoken } from "../../service/UserService";
import localforage from 'localforage';


const Container = styled.div`
  height: 100vh;
  display: flex;
  margin-top: 15%;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background :#FFF;



`
const style = {
  display: "flex"
};

const ButtonLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : center;

`
const SkipButton = styled.div`
  z-index: 10;
  width: 150px;

  height: 50px;
  background: #131313;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const LoginButton = styled.div`
  z-index: 10;
  width: 200px;

  height: 50px;
  background: #ff7e19;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const MainItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCExperiencecontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);

  const [isVideoReady, setIsVideoReady] = useState(false);



  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{

    Move();
  },[])

  /**

   */
  useEffect(()=>{
  }, [])

  const _handleNext =() =>{
    navigate("/PCPhone");
  }

  async function Move (){
    await sleep(3000);
    const PHONE = "01062149756";
    const userdata = await readuserbyphone({PHONE});

    // console.log("TCL: _handleCheck -> userdata", userdata)

    user.phone = PHONE;
    dispatch(user);

    const DEVICEID = userdata.USERINFO.deviceid;
    const TOKEN = user.token;
    const LATITUDE = user.latitude;
    const LONGITUDE = user.longitude;

    if(TOKEN != ''){
      const usertoken = await Update_usertoken({DEVICEID, TOKEN });
    }

    // new Promise(resolve => setTimeout(resolve, 1000));

    await sleep(1000);
    user.users_id = userdata.USERS_ID;
    user.deviceid = DEVICEID;
    user.nickname = userdata.USERINFO.nickname;
    user.userimg = userdata.USERINFO.userimg
    user.latitude = userdata.USERINFO.latitude;
    user.longitude = userdata.USERINFO.longitude;
    user.address_name = userdata.USERINFO.address_name;
    user.realname = userdata.USERINFO.realname;
    user.resume = userdata.USERINFO.resume;



    dispatch(user);


    localforage.setItem('userconfig', user)
    .then(function(value) {
      navigate("/PCMain");
    })
    .catch(function(err) {
      console.error('데이터 저장 실패:', err);
    });

  }

  const _handleTest = async() =>{
 
  }
 
  return (

    <Container style={containerStyle}>

    <LottieAnimation  animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}
      />
    <div style={{marginTop:20}}>
      <div style={{fontFamily:"Pretendard-SemiBold", fontSize:"22px"}}>구해줘 홍여사 체험 공간으로 이동합니다. </div>
      <div style={{marginTop:10}}>체험존에서 구해줘 홍여사를 자유롭게 경험해 보세요</div>
    </div>


    </Container>
  );

}

export default PCExperiencecontainer;

