import { db, auth, storage, firebaseConfig, firebaseApp } from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { COMMUNITYSTATUS, WORKSTATUS } from '../utility/status';
import randomLocation from 'random-location'
import { shuffleArray, sleep, useSleep } from '../utility/common';
import Axios from 'axios';
import { CreateRegistAddr, ReadRegistAddr } from './RegistAddrService';
import { FILTERITMETYPE } from '../utility/screen';
import { CountryAddress, KeywordAddress, MainAddress } from '../utility/region';
import { CreateName } from '../utility/data';
const authService = getAuth(firebaseApp);


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


export const distanceFunc = (lat1, lon1, lat2, lon2) => {
	const R = 6371; // 지구 반지름 (단위: km)
	const dLat = deg2rad(lat2 - lat1);
	const dLon = deg2rad(lon2 - lon1);
	const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
			  Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
			  Math.sin(dLon/2) * Math.sin(dLon/2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	const distance = R * c; // 두 지점 간의 거리 (단위: km)
	return distance;
}
  
export const  deg2rad = (deg)=> {
	return deg * (Math.PI/180);
}

/**
 * Work 관련 서비스
 *! Create 
 * ① CreateWork : 
 * 요청 일감 생성 
 * USER_ID(요청자 정보 인덱스),
 * WORK_INFO(일감 정보 object)
 * WORK_STATUS(일감상태: 기본값 WORKSTATUS.OPEN), 
 * CREATEDT(일감요청일시: 현재시간), 
 *! Read
 * ① ReadWork : 모든 일감 가져오기
 *! Update
 
 *! Delete

 */


export const CreateWork = async({USERS_ID,USERIMG,WORKTYPE, WORK_INFO, NICKNAME,WORK_STATUS}) =>{

  return new Promise(async (resolve, reject) => {
    let success = true;
    const WORKREF = doc(collection(db, "WORK"));
    const id = WORKREF.id;

    try{
       const newdata = {
           WORK_ID : id,
           USERS_ID : USERS_ID,
           USERIMG, USERIMG,
           NICKNAME : NICKNAME,
           WORKTYPE : WORKTYPE,
           WORK_INFO : WORK_INFO,
           WORK_STATUS : WORK_STATUS,
           CREATEDT : Date.now(),
       }
       await setDoc(WORKREF, newdata);

       resolve(id);
    
    }catch(e){
      console.log("TCL: CreateWork -> error ",e.message )
       
        alert( e.message);
        success =false;
        resolve(-1);
    }finally{
    
    }

  });

}


export const CreateWorkInfo = async({USERS_ID,WORKTYPE, WORK_INFO}) =>{

  let success = true;
  const WORKREF = doc(collection(db, "WORKINFO"));
  const id = WORKREF.id;

  try{
     const newdata = {
         WORK_ID : id,
         WORKTYPE : WORKTYPE,
         WORK_INFO : WORK_INFO,
         WORK_STATUS : WORKSTATUS.OPEN,
         CREATEDT : Date.now(),
     }
     await setDoc(WORKREF, newdata);
  
  }catch(e){
    console.log("TCL: CreateWork -> error ",e.message )
     
      alert( e.message);
      success =false;
      return -1;
  }finally{
    return id;
  }
}

export const ReadAllWork = async()=>{
  const workRef = collection(db, "WORKINFO");

  let workitems = [];
  let success = false;
  const q = query(workRef,orderBy("CREATEDT", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      workitems.push(doc.data());
  
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(workitems);
      } else {
        resolve(-1);
      }
    });
  }
}

export const ReadWork = async({latitude, longitude, checkdistance = 10})=>{
  const workRef = collection(db, "WORK");

  let workitems = [];
  let success = false;
  const q = query(workRef,orderBy("CREATEDT", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      let WORK_INFONEW = doc.data().WORK_INFO;

      const FindIndex = WORK_INFONEW.findIndex(x=>x.requesttype == '지역');

      const distance = distanceFunc(WORK_INFONEW[FindIndex].latitude , WORK_INFONEW[FindIndex].longitude,latitude,longitude );
      

      if(distance < checkdistance){
        workitems.push(doc.data());
      }
  
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(workitems);
      } else {
        resolve(-1);
      }
    });
  }
}

export const ReadWorkByuserid = async({USERS_ID})=>{
  const workRef = collection(db, "WORK");

  let workitems = [];
  let success = false;
  const q = query(workRef,where("USERS_ID", "==", USERS_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      workitems.push(doc.data());
  
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(workitems);
      } else {
        resolve(-1);
      }
    });
  }
}


export const ReadMySupportWork = ({USERS_ID}) =>{


  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "CHAT");
    const q = query(userRef, orderBy('CREATEDT',"desc"));
  
    let workitems = [];
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];

  
      
        if((doc.data().SUPPORTER_ID == USERS_ID)){

          const DOC = doc.id;
          const WORK_ID = doc.data().INFO.WORK_ID;
         
          if(WORK_ID != undefined){
            const item = await ReadWorkByIndividually({WORK_ID});
            workitems.push(item);
          }
        }
     


      });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      if (workitems.length > 0) {
          resolve(workitems);
      }else{
        resolve([]);
      }
  
    }catch (e){
      console.log("error", e.message);
      resolve([]);
    } finally {

    }
  });
}


export const ReadWorkByIndividually = async({WORK_ID})=>{
  return new Promise(async (resolve, reject) => {
    const workRef = collection(db, "WORK");

    let workitem = {};
    let success = false;
    const q = query(workRef,where("WORK_ID", "==", WORK_ID));
   
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        workitem = doc.data();
      });
  
      if (querySnapshot.size > 0) {
        resolve(workitem);
      }else{
        resolve(-1);
      }

    } catch (e) {
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }

  });

}

export const ReadRoomByIndividually = async({ROOM_ID})=>{
  const workRef = collection(db, "ROOM");

  let roomitem = {};
  let success = false;
  const q = query(workRef,where("ROOM_ID", "==", ROOM_ID));
 
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      roomitem = doc.data();
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(roomitem);
      } else {
        resolve(-1);
      }
    });
  }
}


export const DeleteWorkByUSER_ID = async({USER_ID}) =>{


  const workRef = collection(db, "WORK");

  let success = false;
  const q = query(workRef,where("USERS_ID", "==", USER_ID));
 
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async(doc) => {
      await deleteDoc(doc.ref);
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(0);
      } else {
        resolve(-1);
      }
    });
  }


}

export const DeleteWorkByWORK_ID = async({WORK_ID}) =>{

  return new Promise(async (resolve, reject) => {

    const workRef = collection(db, "WORK");

    let success = false;
    const q = query(workRef,where("WORK_ID", "==", WORK_ID));
   
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async(doc) => {
        await deleteDoc(doc.ref);
        resolve(0);
      });
  
      // if (querySnapshot.size > 0) {
      //   success = true;
      // }

      // if (success) {
      //   resolve(0);
      // } else {
      //   resolve(-1);
      // }

    } catch (e) {
      console.log("error", e.message);
    } finally {

    }

  });



}

export const UpdateWorkInfoWORKSTATUS = async({DOC, WORK_STATUS}) =>{

  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "WORK");

    const rows = query(userRef, where("WORK_ID","==",DOC ));
  
    let docid = "";
    try{
        const querySnapshot =  await getDocs(rows);
  
        querySnapshot.forEach(async function (doc) {
  
            docid = doc.id;
            await updateDoc(doc.ref, {
              WORK_STATUS  : WORK_STATUS
            });

     

            resolve(doc.id);
        });
  
    }catch(e){
         console.log("error", e.message);
    }
  });


}


export const DefaultReadWork = async({ADDR, currentlatitude, currentlongitude})=>{

  let PolicyCount = 0;



  return new Promise(async (resolve, resject) => {
    const workRef = collection(db, "WORKINFO");

    let workitems = [];
    let success = false;
    const q = query(workRef);
  
    try {
      const querySnapshot = await getDocs(q);
  
      let icount = 0;

      querySnapshot.forEach((doc) => {
  
        let item ={
          CREATEDT :"",
          WORKTYPE : "",
          WORK_INFO : [],
          WORK_STATUS :""
        }
   
        item.WORKTYPE = doc.data().WORKTYPE;
        let WORK_INFONEW = doc.data().WORK_INFO;
  
  
        const FindIndex = WORK_INFONEW.findIndex(x=>x.requesttype == '지역');
        const P = {
          latitude: currentlatitude,
          longitude: currentlongitude
        }
  
        const R = 5000 // meters
        const randomPoint = randomLocation.randomCirclePoint(P, R);
  
        const geocoder = new kakao.maps.services.Geocoder();
    
        geocoder.coord2Address(randomPoint.longitude, randomPoint.latitude, async (result, status) => {
        
          icount++;
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address.address_name;
        
      
            WORK_INFONEW[FindIndex].result = address;
            WORK_INFONEW[FindIndex].latitude = randomPoint.latitude;
            WORK_INFONEW[FindIndex].longitude = randomPoint.longitude;
            item.WORK_INFO = WORK_INFONEW;


            let min = 1;
            let max = 10;
            let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    

            if(MainAddress(ADDR) =='서울' 
            || MainAddress(ADDR) =='경기'
            || MainAddress(ADDR) =='제주특별자치도'
            || MainAddress(ADDR) =='부산'
            || MainAddress(ADDR) =='대구'
            || MainAddress(ADDR) =='대전'
            || MainAddress(ADDR) =='광주'
            ){

              if(randomInt % 5 == 1){
                workitems.push(item);
                console.log("TCL: ReadRandomWork -> ", item,querySnapshot.size,icount);
              }

            }else{
        
            }


          
            if(querySnapshot.size == icount){
              console.log("TCL: DefaultReadWork -> icount", icount ,querySnapshot.size,icount)
              success = true;
  
              resolve(shuffleArray(workitems));
            }
            
        
          }
        });
      });
      if(querySnapshot.size == 0){
        resolve(-1);
      }
     
    } catch (e) {
      console.log("error", e.message);
    } finally {
  
  
    }
  });

}
export const findWorkAndFunctionCallFromCurrentPosition = async({addr, currentlatitude, currentlongitude}) =>{

  let success = false;
  try{

    const ADDR = addr;
    const TYPE = FILTERITMETYPE.HONG;
    let bExist = await ReadRegistAddr({ADDR, TYPE});
   
    console.log("TCL: findWorkAndFunctionCallFromCurrentPosition -> bExist", bExist)

  
    if(bExist == -1){

      const TYPE = FILTERITMETYPE.HONG;

      CreateRegistAddr({ADDR, TYPE});
      // function에 호출하자
      const defaultreadworkitems = await DefaultReadWork({ADDR, currentlatitude, currentlongitude});

      let icount = 0;
      defaultreadworkitems.map(async(data, index)=>{

        const USERS_ID = "";
        const WORKTYPE = data.WORKTYPE;
        const WORK_INFO = data.WORK_INFO;
        const WORK_STATUS = 1;
        const USERIMG = "";

        const NICKNAME = CreateName(CountryAddress(addr));
        const work = await CreateWork({USERS_ID,USERIMG,WORKTYPE, WORK_INFO, NICKNAME,WORK_STATUS});
        icount++;
        if(icount == defaultreadworkitems.length){
          success = true;
        }
      })

  
    }else{
      
      success = false;             
    
    }

  }catch(e){


  }finally{
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(0);
      } else {
        resolve(-1);
      }
    });
  }


}


export const UpdateWorkInfoNicknameAll = ({USERS_ID, NICKNAME}) =>{

  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "WORK");
    const q = query(userRef, where('USERS_ID',"==", USERS_ID));
  
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];


          const DOC = doc.id;
      
          const items = await UpdateWorkInfoNickname({DOC, NICKNAME});
         

  
        });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      resolve(0);
  
    }catch (e){
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }
  });
}

export const UpdateWorkInfoNickname = async({DOC, NICKNAME}) =>{

  const userRef = collection(db, "WORK");

  const rows = query(userRef, where("WORK_ID","==",DOC ));

  let docid = "";
  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {

          docid = doc.id;
          updateDoc(doc.ref, {
            NICKNAME  : NICKNAME
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return docid;
  }

}

export const UpdateWorkInfoImageAll = ({USERS_ID, USERIMG}) =>{

  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "WORK");
    const q = query(userRef, where('USERS_ID',"==", USERS_ID));
  
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];
        const DOC = doc.id;
        const items = await UpdateWorkInfoUserImg({DOC, USERIMG});
         
        });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      resolve(0);
  
    }catch (e){
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }
  });
}

export const UpdateWorkInfoUserImg = async({DOC, USERIMG}) =>{

  const userRef = collection(db, "WORK");

  const rows = query(userRef, where("WORK_ID","==",DOC ));

  let docid = "";
  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {

          docid = doc.id;
          updateDoc(doc.ref, {
            USERIMG  : USERIMG
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return docid;
  }

}
