import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { FiUnderline } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";




const Container = styled.div`
font-size: 10px;
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;
padding: 5px 0px;
margin-bottom: 5px;
background: #ffffff;
width: 50%;
border: 1px solid #efebeb;
margin-left: 35px;
border-radius: 10px;
`
const style = {
  display: "flex"
};

/**
 * 메뉴를 Hover 했을때 컨트롤
 */

const Communitymenu =(props) =>  {

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <Container onClick={()=>{props.callback(props.menu)}}>
        <img src={props.img} width={24}/>
        <div>
        {props.menu}
        </div>
  
    </Container>
  );

}

export default Communitymenu;

