import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";

import { Calendar, momentLocalizer,Views } from 'react-big-calendar';

import "./WorkCalendar.css";

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);



const Container = styled.div`

`
const style = {
  display: "flex"
};





const WorkCalendar =({containerStyle}) =>  {


    const [view, setView] = useState(Views.MONTH); // 기본 뷰는 월간 뷰

    const events = [
      {
        title: '집청소',
        start: new Date(2024, 10, 11, 10, 0),
        end: new Date(2024, 10, 14, 12, 0),
      },
      {
        title: '우체국가기',
        start: new Date(2024, 10, 11, 10, 0),
        end: new Date(2024, 10, 11, 12, 0),
      },
      {
        title: '공부방청소',
        start: new Date(2024, 10, 11, 10, 0),
        end: new Date(2024, 10, 11, 12, 0),
      },
      {
        title: '재활용하기',
        start: new Date(2024, 10, 26, 13, 0),
        end: new Date(2024, 10, 26, 14, 0),
      },
    ];

    const handleViewChange = (newView) => {
        setView(newView);
      };
    
      return (
        <div>
      
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          view={view} // 현재 뷰 설정
          onView={handleViewChange} // 뷰 변경 핸들러
        />
      </div>
      );
}

export default WorkCalendar;

