

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/User";
import PCCommunityheader from "../Header/PCCommunityheader";
import PCConfigheader from "../Header/PCConfigheader";
import PCContactheader from "../Header/PCContactheader";


const PCContactLayout = (props) => {
  const { user, dispatch } = useContext(UserContext);
  const navigation = useNavigate();


  return (
    <div style={{ovderflow:"hidden"}}> 
      <PCContactheader  name ={props.name} image={props.image}/>
      <main>
        {props.children}
      </main>
    </div>
  );
};

export default PCContactLayout;
