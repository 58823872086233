import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';

import "../../screen/css/common.css"
import styled from 'styled-components';

import SignatureCanvas from 'react-signature-canvas'; // 라이브러리 import
import { imageDB } from '../../utility/imageData';
import { BetweenRow, FlexstartRow, Row } from '../../common/Row';
import { FlexstartColumn } from '../../common/Column';
import ButtonEx from '../../common/ButtonEx';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: 'absolute',
  top: '75%',
  left: '50%',
  height: '360px',
  transform: 'translate(-50%, -50%)',
  width: 280,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const IconCloseView = styled.div`

`
const MainData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top:10px;
`

const MainDataItem = styled.div`
  padding: 10px 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ededed;
  width: 100%;
`
const MainDataItemText = styled.span`
  font-size :14px;
  font-family : ${({theme}) =>theme.REGULAR};
  color :  ${({check}) => check == 1 ? "#FF4E19" : "#000" };  

`
const ApplyItem = styled.div`
  display :flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  background-color : #ff7125;
  margin-bottom : 20px;
  margin: 10px auto;
  height: 40px;

`
const FilterApplyButton = styled.div`

    border-radius :5px;

`
const FilterApplyButtonText = styled.span`
  color :#fff;
  font-size :14px;
  font-family : ${({theme}) =>theme.REGULAR};
`

const CheckItem = styled.div`
  background: ${({check}) => check == true ? ('#FF4E19') :('#FFF')};
  height:15px; 
  width:15px; 
  borderRadius:15px;
  border : ${({check}) => check == true ? (null) :('1px solid #000')};
`
const CanvasContainer = styled.div`
  width: 70%;
  height: 70%;
` 

const HeaderPopupline = styled.div`

  width:20%;
  background:#E3E3E3;
  height:4px;
`

const InputComponent = styled.input`
  width: 95%;
  margin: 5px auto;
  border-top: none;
  border-right: none;
  border :${({adjust}) => adjust == false ?(''):('1px solid #ddd')};
  border-bottom: :${({adjust}) => adjust == false ?('1px solid rgb(218, 218, 218);'):('')};
  border-left :${({adjust}) => adjust == false ?('none'):('')};
  border-image: initial;
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
  font-family: Pretendard-Light;
  flex: 0 0 auto;
  &::placeholder {
    font-size: 12px;
  }
`
const SingLayer = styled.div`
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  border-radius: 100px;
  color: #9e9797;
  min-width:40px;
  min-height:20px;
  border : ${({enable}) => enable == true ? ('1px solid #ff7e19'):('')}
`



export default function MobileSignPopup({callback}) {
  const [open, setOpen] = React.useState(true);
  const [errorcontent, setErrorcontent] = React.useState('');
  const [refresh, setRefresh] = React.useState(1);

  const [errorcheckitems, setErrorcheckitems] = React.useState([0,0,0,0,0,0,0]);

  const [signname, setSignname] = React.useState('홍길동');

  const [autosign, setAutosign] = React.useState(true);

  const [signmode, setSignmode] = React.useState(1);


  const _handlesign =(mode)=>{

    if(autosign){
      setSignmode(mode);
    }


    setRefresh((refresh) => refresh +1);
  }

  const _handleAutosign = (value) =>{
    setAutosign(value);
    if(value == true){
      signCanvas.current.clear();
    }
  
    setSignmode(0);
    setRefresh((refresh) => refresh +1);

  }

  const handleClose = () =>{
    setOpen(false);
    callback("");
    setRefresh((refresh) => refresh +1);
  } 
  const _handleapply = () =>{

    if (signCanvas.current.isEmpty()) {
      return;
    } else {
      const dataURL = signCanvas.current.toDataURL("image/png");

  
      setOpen(false);
      callback(dataURL);
    }

  }
  const handleCheck = (index) =>{
    if(errorcheckitems[index] == 0){
      errorcheckitems[index] = 1;
    }else{
      errorcheckitems[index] = 0;
    }
    setErrorcheckitems(errorcheckitems);

    setRefresh(refresh => refresh + 1);

  }


 

  React.useEffect(()=>{
    setErrorcheckitems(errorcheckitems);
    setSignname(signname);
    setAutosign(autosign);
    setSignmode(signmode);
  },[refresh])



  const signCanvas = React.useRef();
  return (
    <div>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Row>
              <HeaderPopupline/>
            </Row>
            <BetweenRow style={{width:"90%", margin: "5px auto", }}>
                <div style={{fontSize:"18px", fontWeight:900, color:"#131313", fontFamily:'Pretendard-SemiBold'}}>본인 서명</div>
                <IconCloseView onClick={handleClose} >
                <img src={imageDB.close} style={{width:"22px", height:"22px"}}/>
                </IconCloseView>
            </BetweenRow>
      
            <MainData>

                <div style={{marginTop:0, width:"100%"}}>
                {/* <FlexstartRow style={{margin:"10px 0px"}} onClick={()=>{_handleAutosign(true)}}>
                  {
                    autosign == true ? ( <img src={imageDB.checkbtn_e} style={{width:20}} />) :(
                      <img src={imageDB.checkbtn_d} style={{width:20}} />
                    )
                  }
                 
                  <div style={{paddingLeft:10}}>자동 서명 고르기</div>
                </FlexstartRow>

                <InputComponent  
                  type={'text'} value={signname}
                  className="sign-input"
                  placeholder='서명할 이름을 적어주세요'
                  onChange={(e) => {
                    setSignname(e.target.value);
                    setRefresh((refresh) => refresh +1);
                }}/>

                <BetweenRow style={{margin:'10px 0px'}}>
                  <SingLayer enable={signmode == 1} onClick={()=>{_handlesign(1)}} style={{fontFamily:'NanumPenScript-Regular', width:'40px',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 2} onClick={()=>{_handlesign(2)}} style={{fontFamily:'Jalnan2',fontStyle: 'italic',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 3} onClick={()=>{_handlesign(3)}} style={{fontFamily:'EastSeaDokdo-Regular', width:'40px',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 4} onClick={()=>{_handlesign(4)}} style={{fontFamily:'GrandifloraOne-Regular',color:"#ff0000"}}>{signname}</SingLayer>
                </BetweenRow>

                <BetweenRow style={{margin:'10px 0px'}}>
                  <SingLayer enable={signmode == 5} onClick={()=>{_handlesign(5)}} style={{fontFamily:'Great Vibes cursive', fontStyle: 'oblique',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 6} onClick={()=>{_handlesign(6)}} style={{fontFamily:'NanumBrushScript-Regular', width:'40px',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 7} onClick={()=>{_handlesign(7)}} style={{fontFamily:'Pretendard-Light',color:"#ff0000"}}>{signname}</SingLayer>
                  <SingLayer enable={signmode == 8} onClick={()=>{_handlesign(8)}} style={{fontStyle: 'italic',color:"#ff0000"}}>{signname}</SingLayer>
                </BetweenRow> */}


              

                {/* <FlexstartRow style={{margin:"20px 0px 10px"}} onClick={()=>{_handleAutosign(false)}}>

                  {
                    autosign == false ? ( <img src={imageDB.checkbtn_e} style={{width:20}} />) :(
                      <img src={imageDB.checkbtn_d} style={{width:20}} />
                    )
                  }

                  <div style={{paddingLeft:10}}>직접 서명 하기</div>
                </FlexstartRow> */}

                <CanvasContainer>
                 
                <SignatureCanvas // canvas element
                      ref={signCanvas}
                      canvasProps={{ className: 'sigCanvas canvasStyle' }}
                      backgroundColor="rgb(230, 230, 230)"
                    />

                {/* { autosign == false &&
                  <SignatureCanvas // canvas element
                      ref={signCanvas}
                      canvasProps={{ className: 'sigCanvas canvasStyle' }}
                      backgroundColor="rgb(230, 230, 230)"
                    />
                } */}
                </CanvasContainer>

    


                </div>

            </MainData>
      
            <ApplyItem >
             <ButtonEx text={'서명등록'} width={'100'}  
                    onPress={_handleapply} bgcolor={'#FF7125'} color={'#fff'} />
            </ApplyItem>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}