import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Column, FlexstartColumn } from "../common/Column";
import { BetweenRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";
import ProfileImage from "./ProfileImage";
import { FaEllipsis } from "react-icons/fa6";
import TimeAgo from 'react-timeago';

import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import "./Chatgate.css";
import { getFullTime } from "../utility/date";
import { ReadChat } from "../service/ChatService";
import { ChatAddress } from "../utility/region";
import ChatprofileImage from "./ChatprofileImage";
import { PCMAINMENU } from "../utility/screen";
import { imageDB } from "../utility/imageData";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  background-color:#fff; 
  width: 100%;
  height: 100px;
  margin: 10px;

`
const style = {
  display: "flex"
};

const Name  = styled.div`
  height: 20px;
  font-size: 16px;
  white-space: nowrap;
  color : #131313;
  font-family:'Pretendard-SemiBold';
  display:flex;
  justify-content:center;
  align-items:center;

`

const underlineAdjust = styled.div`

` 

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width :300px;
  color :#636363;
  font-size:15px;
  position: relative;
  top: 20px;
  left: 20px;
}

`

const Info = styled.div`
  font-size:12px;
  color :#A3A3A3;
  margin-left:10px;
`

const AddButton = styled.div`
  background: #fff;
  border: 1px solid #eded;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  position:absolute;
  left:230px;


`
const SupportTag = styled.div`
  color: #131313;
  padding: 2px 10px;
  border-radius: 15px;
  margin-right: 5px;
  border: 1px solid #ededed;
  margin-left: 10px;
  font-size:12px;

`

const OwnerTag = styled.div`
  color: #131313;
  padding: 2px 10px;
  border-radius: 15px;
  margin-right: 5px;
  border: 1px solid #ededed;
  margin-left: 10px;
  font-size:12px;

`

const ItemLayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width :100%;
  background :#fff;
`

const UnReadLayout = styled.div`
  padding: 0px 7px;
  background: #ff7e19;
  color: #fff;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  font-size: 12px;


`

const TagOwner = styled.div`
  font-size: 16px;
  background: #fff;
  font-family:Pretendard-SemiBold;
  border-radius: 5px;
  color :#ff7e19;
  


`
const TagSupport = styled.div`
  font-size: 16px;
  background: #fff;
  font-family:Pretendard-SemiBold;
  border-radius: 5px;

  color :#ff7e19;

`
const Badge = styled.div`
  position: relative;
  top: -10px;
  z-index: 10;
  left: 15px;

`




const Mobilechatgate =({containerStyle,item, callback, content, select, index, unReadcount}) =>  {


/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [showcontrol, setShowcontrol] = useState(false);

  const [img, setImg] = useState('');
  const [name, setName] = useState('');
  const [info, setInfo] = useState(item.CREATEDT);

  const [owner, setOwner] = useState(false);


  const _handleShowcontrol = ()=>{
    setShowcontrol(true);
    setRefresh((refresh) => refresh +1);
  }

  const _handleHidecontrol = ()=>{
    setShowcontrol(false);
    setRefresh((refresh) => refresh +1);
  }

  useEffect(()=>{
    setShowcontrol(showcontrol);
    setImg(img);
    setName(name);
    setInfo(info);
    // setContent(content);
    setOwner(owner);
  }, [refresh]);

  useEffect(()=>{
    async function FetchData(){

      let  name = "";
      if(item.SUPPORTER_ID == user.users_id){
       
        name = item.OWNER.USERINFO.nickname;
        setName(name);
        setImg(item.OWNER.USERINFO.userimg);
        setOwner(false);
    
      }else{
  
        name = item.SUPPORTER.USERINFO.nickname;
        setName(name);
        setImg(item.SUPPORTER.USERINFO.userimg);
        setOwner(true);
      }


      setRefresh((refresh) => refresh +1);
      
    }
    FetchData();

  },[])



  const _handleChat = () =>{

    let leftimage ="";
    let leftname ="";

    if(owner){

      leftimage = item.SUPPORTER.USERINFO.userimg;
      leftname =item.SUPPORTER.USERINFO.nickname;
 
    }else{

      leftimage = item.OWNER.USERINFO.userimg;
      leftname =item.OWNER.USERINFO.nickname;

    }
    callback(item, owner, name, leftimage, leftname, index);

    navigate("/Mobilecontent" ,{state :{ITEM :item, OWNER : owner, NAME: name, LEFTIMAGE:leftimage, LEFTNAME:leftname}});

  }


 
  return (

    <Container style={containerStyle} onClick={_handleChat}>
      <ItemLayer select={select}>
        <FlexstartColumn style={{width:"17%", height:"80px"}}>
          {
            item.TYPE == PCMAINMENU.HOMEMENU && <ChatprofileImage source={img} OWNER={owner} TYPE={item.INFO.WORKTYPE} />
          }
          {
            item.TYPE == PCMAINMENU.ROOMMENU &&  <ChatprofileImage source={img} OWNER={owner} TYPE={item.INFO.ROOMTYPE} />
          }

        </FlexstartColumn>
        <FlexstartColumn style={{width:"83%", height:"100px", lineHeight:2, paddingBottom:'50px',paddingLeft:"15px"}}>


            
          <Column style={{width:"100%"}}>
              <Row style={{width:"100%", justifyContent:"space-between"}}>
              <Name>{name}</Name>
              <FlexstartRow style={{paddingRight:"30px"}}>
                {
                  owner == true ? (   <div style={{fontWeight:400, fontSize:12, color :"#A3A3A3"}} >
                    {ChatAddress(item.SUPPORTER.USERINFO.address_name).slice(0, 8)}
                  </div>)
                  :(<div style={{fontWeight:400, fontSize:12, color :"#A3A3A3"}} >
                    {ChatAddress(item.OWNER.USERINFO.address_name).slice(0, 8)}
                  </div>)
                }
                <div style={{margin:'0px 5px', color : "#A3A3A3"}}>·</div>
                <TimeAgo date={getFullTime(info)}formatter={formatter} style={{fontWeight:400, fontSize:12, color :"#A3A3A3"}} />
              </FlexstartRow> 

              </Row>
          </Column>

          <FlexstartRow style={{lineHeight:1.5,width:"90%", fontSize:"15px", color:'#888'}}>

            <div>
            {content.slice(0, 39)}
            {content.length > 39 ? "..." : null}
            </div>
     
       

          {unReadcount > 0 && <UnReadLayout>{unReadcount}</UnReadLayout>}

 
          </FlexstartRow> 


        </FlexstartColumn>
      </ItemLayer> 
    </Container>
  );

}

export default Mobilechatgate;

