import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME, WORKPOLICY } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Empty from "../../components/Empty";
import localforage from 'localforage';
import { getDateEx, getDateFullTime } from "../../utility/date";
import { readuserbydeviceid, Readuserbyusersid } from "../../service/UserService";
import { sleep } from "../../utility/common";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import IconButton from "../../common/IconButton";
import MobileRoomItem from "../../components/MobileRoomItem";
import { ReadRoom } from "../../service/RoomService";


const Container = styled.div`
  padding:0px 24px 0px 24px;

`


const style = {
  display: "flex"
};

const Label = styled.div`
  font-size: 23px;
  line-height: 26px;
  font-family:Pretendard-SemiBold;


`
const RegistButton = styled.div`
    height: 50px;
    width: 131px;
    border-radius: 100px;
    background: #FF7125;
    color: #fff;
    margin: 0px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    font-size: 24px;
`
const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 12%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  background: #f9f9f9;
  border-radius: 100px;
  background: ${({clickstatus}) => clickstatus == true ? ('#ff7e19a8') :('#f9f9f9;') };
  border: ${({clickstatus}) => clickstatus == true ? ('3px solid #FF7125') :('') };
  padding: 10px;
`

const FilterEx2 = styled.div`
  position: fixed;
  width: 200px;
  height: 100%;
  z-index: 2;
  padding: 0px 10px;
  bottom: 0px;
  overflow-y: auto;
  right: 0px;
  display: flex;
  flex-direction: row;

`
const ButtonSt = styled.div`

  border: 1px solid #ededed;
  padding: 10px;
  background: ${({enable}) => enable == true ?('#ff7e19'):('#f6f6f6')};
  color: ${({enable}) => enable == true ?('#fff'):('#131313')};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const WorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook},
  {name :WORKNAME.ERRAND, img:imageDB.help},
  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare},
  {name :WORKNAME.LESSON, img:imageDB.lesson},
  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping},
  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog},
]

/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const PCListcontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [workitems, setWorkitems] = useState([]);
  const [roomitems, setRoomitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState(PCMAINMENU.HOMEMENU);

  const [items, setItems] = useState([]);

  
  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setSearching(searching);
    setMenu(menu);
    setWorkitems(workitems);
    setRoomitems(roomitems);
  },[refresh])


  const positioncallback = () =>{

  }
  /**
   * useSelector menu 가 변경됨을 감지 함에 따라 호출되는  Hook 함수
   * 데이타를 서버로 부터 불러와서 FilterwokrItems 함수로 걸러진값을 workitems 에 설정 해준다
   */

  useEffect(()=>{

    setSearching(true);
    console.log("useEffect value=====>", value);
    async function FetchData(){


      const latitude = user.latitude;
      const longitude = user.longitude;
      const checkdistance = user.radius;

      console.log("TCL: FetchData -> useEffect=======>",user, value)

      const serverworkitems = await ReadWork({latitude, longitude,checkdistance});
      data.workitems = serverworkitems;
      datadispatch(data);

     
      if(value != [] || value != ''){
        let items = FilterWorkitems(value, serverworkitems);
        console.log("TCL: FetchData -> FilterWorkitems", items);
        setWorkitems(items);
      }else{
        setWorkitems(serverworkitems);
      }
      setSearching(false);
    }
    localforage.getItem('userconfig')
    .then(function(value) {
      console.log("TCL: listener -> App userconfig", value);
      user.latitude =value.latitude;
      user.longitude =value.longitude;
      user.radius =value.radius;
      user.deviceid = value.deviceid;
      user.address_name = value.address_name;

      user.token = value.token;
      user.phone = value.phone;
      user.userimg = value.userimg;
      user.users_id = value.users_id;
      user.radius = value.radius;
      user.worker = value.worker;
      user.realname = value.realname;
      user.resume = value.resume;



      dispatch(user);

      FetchData();
    })
    .catch(function(err) {

    });

    setRefresh((refresh) => refresh +1);
  },[value])



  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
  };



  /**
   *  필터 값에 의해서 다시 데이타를 정리 해주는 함수
   *  WORKNAME.ALLWORK 인경우는 모든 값을 보여준다
   */
  function FilterWorkitems(filter, workitems){
    let items = [];
    workitems.map((data) =>{

      if(filter == WORKNAME.ALLWORK || filter == "REFRESH")
      {
        items.push(data);
      }else{
        if(data.WORKTYPE == filter){
          items.push(data);
        }
      }
    })
    return items;
  }

  /**
   * 단위 일감에서 해당 일감을 클릭햇을때 내주변으로 이동 할수 있도록 한다
   * @param 해당 work_id 와 타입을 보내주어야 한다
   */
  const _handleSelectWork = (WORK_ID) =>{
    navigate("/PCmap" ,{state :{ID :WORK_ID, TYPE : FILTERITMETYPE.HONG}});
  }

  const _handlemap = () =>{
    navigate("/PCmap" ,{state :{WORK_ID :"", TYPE : ""}});
  }

  const _handlecurrentloadingcallback = ()=> {
    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);
  }

  const _handleMenu = async(menu)=>{
    setMenu(menu);

    setRefresh((refresh) => refresh +1);

    const latitude = user.latitude;
    const longitude = user.longitude;
    const checkdistance = user.radius;

    if(menu==PCMAINMENU.HOMEMENU){
      setSearching(true);
      const serverworkitems = await ReadWork({latitude, longitude,checkdistance});
      setRoomitems([]);
      setWorkitems(serverworkitems);
      setSearching(false);
    }else{
      setSearching(true);
      const serverworkitems = await ReadRoom({latitude, longitude,checkdistance});
      setWorkitems([]);
      setRoomitems(serverworkitems);
      setSearching(false);
    }



    setRefresh((refresh) => refresh +1);
  }


  return (
    <>
    {
      currentloading == true && <Loading type={LoadingType.CURRENTPOS} callback={_handlecurrentloadingcallback}/>
    }
    {

      <Container style={containerStyle}>

      {popupstatus == true ? (
        <PcAdvertisePopup type ={3}  callback={popupcallback} top={'50%'}  left={'55%'} height={'550px'} width={'500px'} image={imageDB.sample12}></PcAdvertisePopup>
      ) : null}
      <div style={{display:"flex", flexDirection:"column"}}>
          <Position type={PCMAINMENU.HOMEMENU} callback={positioncallback}/>

          <FlexstartRow>
            <ButtonSt enable={menu == PCMAINMENU.HOMEMENU} onClick={()=>{_handleMenu(PCMAINMENU.HOMEMENU)}}>도움요청 리스트</ButtonSt>
            <ButtonSt enable={menu == PCMAINMENU.ROOMMENU} onClick={()=>{_handleMenu(PCMAINMENU.ROOMMENU)}}>공간대여 리스트</ButtonSt>
          </FlexstartRow>
 
          {(searching == true ) ? (<LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loadinglarge}
            width={"100px"} height={'100px'} />) :(

              <>
              {
                menu == PCMAINMENU.HOMEMENU ?(<>
                  {
                    workitems.length == 0 && 
                    <Empty content = "해당일감이 존재 하지 않습니다"
                    containerStyle={{marginTop:"150px"}}
                    />
                  }
                  <Row style={{flexWrap:"wrap", justifyContent:"space-around", alignItems:"flex-start"}}
                  className="WorkLayer">
                  {
                      workitems.map((item, index)=>(
                    
                        <MobileWorkItem key={index}  index={index} width={'20%'} 
                          workdata={item} onPress={()=>{_handleSelectWork(item.WORK_ID)}}/>
                        
                      ))
                    }
                  </Row>
    
                  <FilterEx2 style ={{height:'80px'}}>
    
                    <IconButton onPress={_handlemap} icon={'list'} iconcolor={'#fff'} width={'40%'} radius={'5px'} bgcolor={'#ff7e19'} color={'#fff'} text={'지도로보기'} containerStyle={{fontSize:16, padding:"8px 10px", fontWeight:500,
                      background: "#ff7e19",
                      fontSize: "16px;",width: '60%',height: '30px'
                    }}/>
    
                  </FilterEx2>
                  <StoreInfo />
    
                  </>):(<>
              {
                roomitems.length == 0 && 
                <Empty content = "해당공간이 존재 하지 않습니다"
                containerStyle={{marginTop:"150px"}}
                />
              }
              <Row style={{flexWrap:"wrap", justifyContent:"space-around", alignItems:"flex-start"}}
              className="WorkLayer">
              {
                  roomitems.map((item, index)=>(
                
                    <MobileRoomItem key={index}  index={index} width={'23%'} 
                      roomdata={item} onPress={()=>{_handleSelectWork(item.ROOM_ID)}}/>

                  ))
                }
              </Row>

              <FilterEx2 style ={{height:'80px'}}>

                <IconButton onPress={_handlemap} icon={'list'} iconcolor={'#fff'} width={'40%'} radius={'5px'} bgcolor={'#ff7e19'} color={'#fff'} text={'지도로보기'} 
                containerStyle={{fontSize:16, padding:"8px 10px", fontWeight:500,
                  background: "#ff7e19",
                  fontSize: "16px;",width: '60%',height: '30px'
                }}/>

              </FilterEx2>
              <StoreInfo />

              </>)
              }
              </>
           
            )

          }

  



      </div>
      </Container>
    }


    </>


  );

}

export default PCListcontainer;

