import { db, auth, storage, firebaseConfig, firebaseApp } from '../api/config';
import { collection, getDocs, query, updateDoc,where,doc,setDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { COMMUNITYSTATUS, WORKSTATUS } from '../utility/status';
import { shuffleArray, useSleep } from '../utility/common';
import randomLocation from 'random-location'
import { CountryAddress, distanceFunc, MainAddress } from '../utility/region';
import Axios from 'axios';
import { CreateRegistAddr, ReadRegistAddr } from './RegistAddrService';
import { FILTERITMETYPE } from '../utility/screen';
import { CreateName } from '../utility/data';
import { ReadRoomByIndividually } from './WorkService';

const authService = getAuth(firebaseApp);




/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


/**
 * Room 관련 서비스
 *! Create 
 * ① CreateRoom : 
 * 공간 생성 
 * USER_ID(공간 대여자 정보 인덱스),
 * ROOMTYPE
 * ROOMSIZE
 * ROOMENABLE
 * ROOMIMAGE
 * REGION
 * PRICE
 * LATITUDE
 * LONGITUDE
 * ROOM_STATUS(일감상태: 기본값 ROOMSTATUS.OPEN), 
 * CREATEDT(일감요청일시: 현재시간), 
 *! Read
 * ① ReadRoom : 모든 일감 가져오기
 *! Update
 
 *! Delete

 */


export const CreateRoom = async({USERS_ID,ROOMTYPE,ROOM_INFO, NICKNAME, WORK_STATUS}) =>{

    let success = true;
    const ROOMREF = doc(collection(db, "ROOM"));
    const id = ROOMREF.id;

    try{
       const newdata = {
           ROOM_ID : id,
           USERS_ID : USERS_ID,
           NICKNAME : NICKNAME,
           ROOMTYPE : ROOMTYPE,
           ROOM_INFO : ROOM_INFO,
           ROOM_STATUS : WORK_STATUS,
           CREATEDT : Date.now(),
       }
       await setDoc(ROOMREF, newdata);
    
    }catch(e){
      console.log("TCL: CreateRoom -> error ",e.message )
       
        alert( e.message);
        success =false;
        return -1;
    }finally{
      return id;
    }
}

export const CreateRoomInfo = async({USERS_ID,ROOMTYPE,ROOM_INFO}) =>{

  let success = true;
  const ROOMREF = doc(collection(db, "ROOMINFO"));
  const id = ROOMREF.id;

  try{
     const newdata = {
         ROOM_ID : id,
         ROOMTYPE : ROOMTYPE,
         ROOM_INFO : ROOM_INFO,
         ROOM_STATUS : WORKSTATUS.OPEN,
         CREATEDT : Date.now(),
     }
     await setDoc(ROOMREF, newdata);
  
  }catch(e){
    console.log("TCL: CreateRoom -> error ",e.message )
     
      alert( e.message);
      success =false;
      return -1;
  }finally{
    return id;
  }
}

export const ReadRoom = async({latitude, longitude, checkdistance =5})=>{

  const roomRef = collection(db, "ROOM");

  let roomitems = [];
  let success = false;
  const q = query(roomRef);

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      let ROOM_INFONEW = doc.data().ROOM_INFO;

      const FindIndex = ROOM_INFONEW.findIndex(x=>x.requesttype == '지역');

      const distance = distanceFunc(ROOM_INFONEW[FindIndex].latitude , ROOM_INFONEW[FindIndex].longitude,latitude,longitude );
      
      roomitems.push(doc.data());

      // if(distance < checkdistance){
      //   roomitems.push(doc.data());
      // }
    });

    if (querySnapshot.size > 0) {
      success = true;
    }

    return new Promise((resolve, resject) => {
      if (success) {
        resolve(roomitems);
      } else {
        resolve(-1);
      }
    });

  } catch (e) {
    console.log("error", e.message);
  } finally {

  }

}
export const ReadRoomByuserid = async({USERS_ID})=>{
  const workRef = collection(db, "ROOM");

  let roomitems = [];
  let success = false;
  const q = query(workRef,where("USERS_ID", "==", USERS_ID));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      roomitems.push(doc.data());
  
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(roomitems);
      } else {
        resolve(-1);
      }
    });
  }
}

export const ReadMySupportRoom = ({USERS_ID}) =>{


  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "CHAT");
    const q = query(userRef, orderBy('CREATEDT',"desc"));
  
    let roomitems = [];
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];

  
      
        if((doc.data().SUPPORTER_ID == USERS_ID)){

          const DOC = doc.id;
          const ROOM_ID = doc.data().INFO.ROOM_ID;
         
          if(ROOM_ID != undefined){
            const item = await ReadRoomByIndividually({ROOM_ID});
            roomitems.push(item);
          }
        }
     


      });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      if (roomitems.length > 0) {
          resolve(roomitems);
      }else{
        resolve([]);
      }
  
    }catch (e){
      console.log("error", e.message);
      resolve([]);
    } finally {

    }
  });
}

export const DeleteRoomByROOM_ID = async({ROOM_ID}) =>{

  return new Promise(async (resolve, reject) => {

    const workRef = collection(db, "ROOM");

    let success = false;
    const q = query(workRef,where("ROOM_ID", "==", ROOM_ID));
   
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async(doc) => {
        await deleteDoc(doc.ref);
        resolve(0);
      });
  


    } catch (e) {
      console.log("error", e.message);
    } finally {

    }

  });



}

export const ReadAllRoom = async()=>{
  const roomRef = collection(db, "ROOM");

  let roomitems = [];
  let success = false;
  const q = query(roomRef,orderBy("CREATEDT", "desc"));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      roomitems.push(doc.data());
  
    });

    if (querySnapshot.size > 0) {
      success = true;
    }
  } catch (e) {
    console.log("error", e.message);
  } finally {
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(roomitems);
      } else {
        resolve(-1);
      }
    });
  }
}

export const DefaultReadRoom = async({ADDR, currentlatitude, currentlongitude})=>{


  return new Promise(async (resolve, resject) => {
    const roomRef = collection(db, "ROOMINFO");

    let roomitems = [];
    let success = false;
    const q = query(roomRef);
  
    try {
      const querySnapshot = await getDocs(q);
  
      let icount = 0;
      querySnapshot.forEach((doc) => {
      console.log("TCL: DefaultReadRoom -> ", querySnapshot.size )
  
        let item ={
          CREATEDT :"",
          ROOMTYPE : "",
          ROOM_INFO : [],
          ROOM_STATUS :""
        }
   
        item.ROOMTYPE = doc.data().ROOMTYPE;
  
        let ROOM_INFONEW = doc.data().ROOM_INFO;
  
        const FindIndex = ROOM_INFONEW.findIndex(x=>x.requesttype == '지역');
        const P = {
          latitude: currentlatitude,
          longitude: currentlongitude
        }
  
        const R = 5000 // meters
        const randomPoint = randomLocation.randomCirclePoint(P, R);
  
        const geocoder = new kakao.maps.services.Geocoder();
  
        geocoder.coord2Address(randomPoint.longitude, randomPoint.latitude, async (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address.address_name;
        
            ROOM_INFONEW[FindIndex].result = address;
            ROOM_INFONEW[FindIndex].latitude = randomPoint.latitude;
            ROOM_INFONEW[FindIndex].longitude = randomPoint.longitude;
            item.ROOM_INFO = ROOM_INFONEW;
            item.ROOM_STATUS = doc.data().ROOM_STATUS;
            icount++;

            let min = 1;
            let max = 10;
            let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    
            console.log("TCL: ReadAllRoom -> randomInt", randomInt, icount,querySnapshot.size );
    

            if(MainAddress(ADDR) =='서울' 
            || MainAddress(ADDR) =='경기'
            || MainAddress(ADDR) =='경기'
            || MainAddress(ADDR) =='부산'
            || MainAddress(ADDR) =='대구'
            || MainAddress(ADDR) =='대전'
            || MainAddress(ADDR) =='광주'
            ){

          roomitems.push(item);
                console.log("TCL: ReadRandomRoom -> ", item,querySnapshot.size,icount);

            }else{
      
            }


            if(querySnapshot.size == icount){
              console.log("TCL: DefaultReadRoom -> icount", icount ,querySnapshot.size)
              success = true;

              resolve(shuffleArray(roomitems));
            }
    
          }
        });
      });

      if(querySnapshot.size ==0){
        resolve(-1);
      }
  
  
    } catch (e) {
      console.log("error", e.message);
    } finally {
   
    }

  });
}

export const findRoomAndFunctionCallFromCurrentPosition = async({addr, currentlatitude, currentlongitude}) =>{

  let success = false;
  try{

    const ADDR = addr;
    const TYPE = FILTERITMETYPE.ROOM;
    let bExist = await ReadRegistAddr({ADDR, TYPE});

    console.log("TCL: findRoomAndFunctionCallFromCurrentPosition -> bExist", bExist)


    if(bExist == -1){
      // function에 호출하자
      const TYPE = FILTERITMETYPE.ROOM;
      CreateRegistAddr({ADDR, TYPE});

      const defaultreadroomitems = await DefaultReadRoom({ADDR, currentlatitude, currentlongitude});


      let icount = 0;
      defaultreadroomitems.map(async(data, index)=>{

        const USERS_ID = "";
        const ROOMTYPE = data.ROOMTYPE;
        const ROOM_INFO = data.ROOM_INFO;
        const WORK_STATUS = 1;

        const NICKNAME = CreateName(CountryAddress(addr));
        const work = await CreateRoom({USERS_ID,ROOMTYPE, ROOM_INFO, NICKNAME,WORK_STATUS});
        icount++;
        if(icount == defaultreadroomitems.length){
          success = true;
        }
      })

      // console.log("TCL: MobileSplashcontainer -> defaultreadroomitems", defaultreadroomitems)

      // const jsonPayload = {
      //   roomitems: defaultreadroomitems,
     
      // };
      // console.log("TCL: StartProcess -> defaultreadworkitems", defaultreadroomitems);
  
      // Axios.post('https://asia-northeast1-help-bbcb5.cloudfunctions.net/api/newroom',  jsonPayload, {
      //   headers: {
      //     "Content-Type": "application/json"
      //   }
      // })
      // .then(async(response) =>{
      //   console.log("TCL: ROOM -> post url", );

      //   success = true;
      // })
      // .catch((error) => {
      //   success = false;
      // })
    }

  }catch(e){


  }finally{
    return new Promise((resolve, resject) => {
      if (success) {
        resolve(0);
      } else {
        resolve(-1);
      }
    });
  }


}

export const UpdateRoomInfoNicknameAll = ({USERS_ID, NICKNAME}) =>{

  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "ROOM");
    const q = query(userRef, where('USERS_ID',"==", USERS_ID));
  
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];


          const DOC = doc.id;
      
          const items = await UpdateRoomInfoNickname({DOC, NICKNAME});
         

  
        });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      resolve(0);
  
    }catch (e){
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }
  });
}

export const UpdateRoomInfoNickname = async({DOC, NICKNAME}) =>{

  const userRef = collection(db, "ROOM");

  const rows = query(userRef, where("ROOM_ID","==",DOC ));

  let docid = "";
  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {

          docid = doc.id;
          updateDoc(doc.ref, {
            NICKNAME  : NICKNAME
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return docid;
  }

}

export const UpdateRoomInfoImageAll = ({USERS_ID, USERIMG}) =>{

  return new Promise(async (resolve, reject) => {
    const userRef = collection(db, "ROOM");
    const q = query(userRef, where('USERS_ID',"==", USERS_ID));
  
    try {
      const querySnapshot = await getDocs(q);
      const promises = querySnapshot.docs.map(async(doc)=>{
        
        let data= [];
        const DOC = doc.id;
        const items = await UpdateRoomInfoUserImg({DOC, USERIMG});
         
        });  // 각 문서에 대한 비동기 작업을 배열에 저장
      await Promise.all(promises);  // 모든 비동기 작업이 완료될 때까지 기다림
      resolve(0);
  
    }catch (e){
      console.log("error", e.message);
      resolve(-1);
    } finally {

    }
  });
}

export const UpdateRoomInfoUserImg = async({DOC, USERIMG}) =>{

  const userRef = collection(db, "ROOM");

  const rows = query(userRef, where("ROOM_ID","==",DOC ));

  let docid = "";
  try{
      const querySnapshot =  await getDocs(rows);

      querySnapshot.forEach(function (doc) {

          docid = doc.id;
          updateDoc(doc.ref, {
            USERIMG  : USERIMG
          });
      });

  }catch(e){
       console.log("error", e.message);
  }finally{
      return docid;
  }

}
