import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB } from "../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { GUIDETYPE, LAWTYPE, PCCOMMNUNITYMENU } from "../../utility/screen";

import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { DataContext } from "../../context/Data";
import { useSleep } from "../../utility/common";
import QRCode from 'qrcode.react';
import UseLaw from "../../components/UseLaw";
import PrivacyLaw from "../../components/PrivacyLaw";
import GpsLaw from "../../components/GpsLaw";
import HongKnow from "../../components/HongKnow";
import RoomKnow from "../../components/RoomKnow";
import CommunityKnow from "../../components/CommunityKnow";

import { Helmet } from "react-helmet";

const Container = styled.div`
 

`
const style = {
  display: "flex"
};

const PolicyBtn = styled.div`
  width: 230px;
  margin: 0 5px;
  line-height: 55px;
  background : ${({selected}) => selected == true ? ('#ff7e19') :('#f0f0f0')};
  color : ${({selected}) => selected == true ? ('#fff') :('#000')};
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;


`

const HighLight = styled.div`
  font-size: 20px;
  color: rgb(255, 126, 25);
  font-family: Pretendard-SemiBold;
  margin-bottom: 15px;

`






const PCGuideLifecontainerInfo =({containerStyle}) =>  {


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleCanPlay = () => {
    setIsVideoReady(true);
    console.log('비디오가 재생 준비되었습니다.');
  };



  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{

  },[refresh])



  /**
   * 초기 페이지 진입시에는 context에 있는 정보를 가져온다
  
   */
  useLayoutEffect(()=>{
    async function FetchData(){

    } 

    FetchData();
  }, [])



  const _handleMenustatus=()=>{
  
      navigate("/PCGate");
  }


  return (
    <Container style={containerStyle}>

    


      <FlexstartRow onClick={()=>{_handleMenustatus()}} style={{marginTop:20, marginLeft:20}}>
          <img src={imageDB.logo} width={38} height={34} style={{borderRadius:50}} />
          <div style={{fontFamily:"Pretendard-Bold", paddingTop:'5px', fontSize:25}}>홍여사</div>    
      </FlexstartRow>


      <div style={{height:"700px", backgroundColor:"#fffae0", width:"100%"}}>

          <Column style={{width:"80%", margin:"10px auto 0px", paddingTop:30}}>
            <div style={{width:"80%",display:"flex", justifyContent:"center", flexDirection:"column"}}>
                <HighLight>전국민 똑순이 프로젝트</HighLight>
                <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>세상의 모든 지식을 정리 하고 똑순이로 만들어 드립니다</div>
            </div>
            <div style={{height:30}}></div>
                <video class="background-video" style={{width:"70%"}}  controls loop onCanPlay={handleCanPlay}>
                <source src={imageDB.introduce3}  type="video/mp4" />
                </video>
          </Column>
      </div>

      <Row style={{margin:"100px auto", width:"80%"}}>
        <div style={{paddingLeft:30}}>
          <HighLight>구해줘 홍여사 냉장고 관리 프로젝트</HighLight>

          <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>
            <div>냉장고에 있는 음식</div>
            <div>유효기간 관리</div>
          </div>

          <div style={{fontSize:18, fontFamily:"Pretendard-Regular", color:"#7c7c7c",
            lineHeight: "1.8",
            marginTop: "20px"}}>
            <div>냉장고에 오래된 음식재료를 위한 맞춤요리 </div>
            <div>매일 매일 제공 되는 추천 레시피 확인</div>
      
          </div>
        </div>
        <video class="background-video"   controls loop onCanPlay={handleCanPlay}>
          <source src={imageDB.introduce4}  type="video/mp4" />
        </video>
      </Row>

      <div style={{height:"700px", backgroundColor:"#f6fdec", width:"100%"}}>
        <Row style={{margin:"100px auto"}}>
        <video class="background-video" style={{width:'50%', background:"#f6fdec", paddingTop:100}}   controls loop onCanPlay={handleCanPlay}>
          <source src={imageDB.introduce5}  type="video/mp4" />
        </video>
        <div style={{paddingLeft:30}}>
          <HighLight>구해줘 홍여사 가사분담</HighLight>

          <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>
            <div>집안일 가사분담</div>
     
          </div>


      
        </div>
        </Row>
     
      </div>

    </Container>
  );

}

export default PCGuideLifecontainerInfo;

