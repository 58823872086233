import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import LazyImage from "../common/LasyImage";
import { FlexstartRow } from "../common/Row";
import { UserContext } from "../context/User";
import { imageDB, Seekimage } from "../utility/imageData";
import { CHATIMAGETYPE } from "../utility/screen";
import "./ProfileImage.css"



const Container = styled.div`

`
const style = {
  display: "flex"
};

const ImageLayout = styled.div`
    background: #fff;
    height: 50px;
    border-radius: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items:center;
   
`
const Tag = styled.div`

  color: ${({OWNER}) => OWNER == true ? ('#FF7125') :('#000')};
  position: relative;
  height: 20px;
  background-color :#f9f9f9;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family:Pretendard-SemiBold;

`

const TypeImage = styled.div`

position: relative;
height: 20px;
color: #000;
border: 2px double #ff7e19;
left: 30px;
top: -50px;
display: flex;
justify-content: center;
align-items: center;
background: #fff;
border-radius: 50px;
width: 30px;
height: 30px;

`





const ChatprofileImage =({containerStyle, source, OWNER, TYPE}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);


 
  return (

    <Container style={containerStyle}>
      <ImageLayout>
       <LazyImage src={source}
       containerStyle={{height:"50px",width:"50px",
        borderRadius:"100px", backgroundColor: '#e0e0e0', display:"flex", justifyContent:"center" }}
       />
      </ImageLayout>
      <Tag OWNER={OWNER}>
        {OWNER == true ? (<span>{'의뢰하다'}</span>) :(<span>{'지원하다'}</span>)}
      </Tag>
      <TypeImage>
      <img src={Seekimage(TYPE)} style={{width:"20px", height:"20px"}}/>
      </TypeImage>
    </Container>
  );

}

export default ChatprofileImage;

