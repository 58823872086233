import React, { Component, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { CHATCONTENTTYPE, CHATIMAGETYPE, CONTACTTYPE, EventItems, MOBILEMAINMENU, PCCOMMNUNITYMENU, PCMAINMENU } from "../../utility/screen";
import Communitymenu from "../../common/Communitymenu";
import CommunityItem from "../../components/CommunityItem";
import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { ReadCommunity, ReadCommunitySummary, ReadCommunityTop10 } from "../../service/CommunityService";
import { DataContext } from "../../context/Data";
import { sleep, useSleep } from "../../utility/common";
import Chatgate from "../../components/Chatgate";
import { distanceFunc } from "../../utility/region";
import { CommaFormatted } from "../../utility/money";
import { WORKNAME,REQUESTINFO } from "../../utility/work";
import {
  SlShield,
  SlPaperClip,
  SlLogout,
  SlUserUnfollow,
} from "react-icons/sl";
import { CreateMessage, CreateMessageEx, UpdateDocChat } from "../../service/ChatService";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../api/config";
import { getDateFullTime, getTime, getDate } from "../../utility/date";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingChat2AnimationStyle, LoadingChatAnimationStyle } from "../../screen/css/common";
import { uploadImage } from "../../service/UploadService";
import MobileWarningPopup from "../../modal/MobileWarningPopup/MobileWarningPopup";

import MobileContactMainPopup from "../../modal/MobileContactMainPopup/MobileContactMainPopup";
import { RiRectangleFill } from "react-icons/ri";
import ButtonEx from "../../common/ButtonEx";
import Requestdoc from "../../components/Requestdoc";
import { ReadContact, ReadContactByIndividually, UpdateContactByPURCHASE, UpdateContactByResult } from "../../service/ContactService";
import { GoPlusCircle } from "react-icons/go";

import  "./MobileCompletecontainer.css";
import MobileFailPopup from "../../modal/MobileFailPopup/MobileFailPopup";

const Container = styled.div`
    background-color : #fff;
    height:100vh;
    width : 90%;
    margin : 0 auto;
    padding-top:70px;
    fontSize: 15px;

`
const BoxLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;

`

const BoxItem = styled.div`
  width: 30%;
  height: 100px;
  background: #f9f9f9;
  margin: 0px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

`
const Label = styled.div`

  width: 95%;
  margin: 0px auto 10px;
  font-size:14px;
`

const ResultContent = {
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Pretendard-Light',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border :'1px solid #dadada',
  borderRadius:'5px',
  height: '250px',
  padding: '5px 10px',
}


const MobileCompletecontainer =({containerStyle, ITEM, OWNER, LEFTIMAGE, LEFTNAME, NAME}) =>  {

  console.log("TCL: MobileCompletecontainer -> ITEM", ITEM)

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
 
  const [registimgfail, setRegistimgfail] = useState(false);

  const [result, setResult] = useState('');
  const [images, setImages] = useState([]);
  const [chatid, setChatid] = useState('');
  const [fail, setFail] = useState(false);
  const [content, setContent] = useState('');

  const fileInput = useRef();




  useEffect(()=>{
    setResult(result);
    setImages(images);
    setContent(content);
  }, [refresh])


  const handleUploadClick = (e) => {
    fileInput.current.click();
  };

  const ALLOW_IMAGE_FILE_EXTENSION = "jpg,jpeg,png,bmp";

  const ImagefileExtensionValid = (name) => {
    const extention = removeFileName(name);

    if (
      ALLOW_IMAGE_FILE_EXTENSION.indexOf(extention) <= -1 ||
      extention == ""
    ) {
      return false;
    }
    return true;
  };
  const removeFileName = (originalFileName) => {
    const lastIndex = originalFileName.lastIndexOf(".");

    if (lastIndex < 0) {
      return "";
    }
    return originalFileName.substring(lastIndex + 1).toLowerCase();
  };

  const ImageUpload = async (data, data2) => {
    const uri = data;
    const random = data2;
    const URL = await uploadImage({ uri, random });
    return URL;
  };
  
  
  const handlefileuploadChange = async (e) => {


    if(images.length ==5){
      setContent("일에 대한 결과사진을 최대 5장까지 첨부할수 있습니다");
      setFail(true);
      setRefresh((refresh) => refresh +1);
      return;  
    }
    let filename = "";
    const file = e.target.files[0];
    filename = file.name;


    if (!ImagefileExtensionValid(filename)) {

      setRegistimgfail(true);
      setRefresh((refresh) => refresh +1);
      return;
    }

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;


        img.onload = function() {
          // 원본 이미지 크기
          const originalWidth = img.width;
          console.log("TCL: img.onload -> originalWidth", originalWidth)
       
          const originalHeight = img.height;
   
  
          // 원하는 이미지 크기 (예: 300x300으로 크기 조정)
          const targetWidth = 200;
          const targetHeight = 200;
  
          // Canvas 생성
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // 비율 유지를 위해 축소
          let width = originalWidth;
          let height = originalHeight;
          if (width > height) {
              if (width > targetWidth) {
                  height *= targetWidth / width;
                  width = targetWidth;
              }
          } else {
              if (height > targetHeight) {
                  width *= targetHeight / height;
                  height = targetHeight;
              }
          }
  
          // 리사이즈된 캔버스 크기 설정
          canvas.width = width;
          canvas.height = height;
  
          // 이미지 리사이즈하여 캔버스에 그리기
          ctx.drawImage(img, 0, 0, width, height);
          console.log("TCL: img.onload -> ctx", ctx)
          console.log("TCL: img.onload -> img", img)
  
          // 캔버스 데이터를 Blob으로 변환 (JPEG 포맷, 품질 0.8)
          // Canvas 데이터를 base64로 변환
          const base64Image = canvas.toDataURL('image/jpeg', 0.8);  // 품질 0.8로 JPEG 변환

          resolve(base64Image);

          
        }


      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;
      console.log("uri", uri);

      let msg = await ImageUpload(uri, email);
      const IMGTYPE = true;

      images.push(msg);

      let read= [];
      read.push(user.users_id);

      setRefresh((refresh) => refresh +1);


      // try {
  
      //   const CHAT_ID = chatid;
      //   const CHAT_CONTENT_TYPE = CHATCONTENTTYPE.IMAGE;
  
 
      //   const users_id = user.users_id;
      //   await CreateMessage({
      //     CHAT_ID,
      //     msg,
      //     users_id,
      //     read,
      //     CHAT_CONTENT_TYPE,
        
      //   });
      // } catch (e) {
      //   console.log("error", e);
      // }
    });
  };

  const handleImageDelete = (index) =>{
    images.splice(index, 1);
    setRefresh((refresh) => refresh +1);
  }


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);




  useEffect(()=>{
    async function FetchData(){

    }
    FetchData();
  }, [])

  const _handleComplete = async() =>{
    if(result == ''){
     
      setContent("일에대한 결과를 적고 일감완료 버튼을 클릭해주세요");
      setFail(true);
      setRefresh((refresh) => refresh +1);
      return;
    }
    if(images.length == 0){

      setContent("일에 대한 결과사진을 적어도 한장은 첨부해주세요");
      setFail(true);
      setRefresh((refresh) => refresh +1);
      return;
    }


    const OWNER_ID = ITEM.OWNER_ID;
    const SUPPORTER_ID = ITEM.SUPPORTER_ID;

    
    let ID = "";
    if(ITEM.TYPE == PCMAINMENU.HOMEMENU){
      ID = ITEM.INFO.WORK_ID;
    }else {
      ID = ITEM.INFO.ROOM_ID;
    }


    const CONTACTITEM = await ReadContactByIndividually({ID, OWNER_ID, SUPPORTER_ID});

    const CONTACT_ID = CONTACTITEM.CONTACT_ID;

    const CHAT_ID = ITEM.CHAT_ID;

    await UpdateContactByResult({CONTACT_ID});

    await sleep(100);

    const read = [];
    const users_id = user.users_id;
    const CHAT_CONTENT_TYPE = CHATCONTENTTYPE.COMPLETE;
    read.push(user.users_id);
    // const msg = ITEM.SUPPORTER.USERINFO.nickname +"님이 일감을 완료 하고 결과를 작성하였습니다" +
    // ITEM.OWNER.USERINFO.nickname +"님은 작업 한 내용에 대해 평가를 해주시기 바랍니다. 평가가 완료 되면 용역비가"+
    // ITEM.SUPPORTER.USERINFO.nickname+"님에게 지급 되겠습니다";

    const msgitems = [];
    msgitems.push(ITEM.SUPPORTER.USERINFO.nickname +"님이 일감을 완료 하고 결과를 작성하였습니다");
    msgitems.push(ITEM.OWNER.USERINFO.nickname +"님은 작업 한 내용에 대해 평가를 해주시기 바랍니다");
    msgitems.push("평가가 완료 되면 용역비가" + ITEM.SUPPORTER.USERINFO.nickname+"님에게 지급 되겠습니다");

    let RESULTITEM ={
      result : "",
      images : []
    }
    RESULTITEM.result = result;
    RESULTITEM.images = images;
    await CreateMessageEx({
      CHAT_ID,
      msgitems,
      users_id,
      read,
      CHAT_CONTENT_TYPE,
      RESULTITEM,
    
    });
    await sleep(1000);

    navigate("/Mobilecontent" ,{state :{ITEM :ITEM, OWNER : OWNER, NAME: NAME, LEFTIMAGE:LEFTIMAGE, LEFTNAME:LEFTNAME}});


  }
  const failcallback = () =>{
    setFail(false);
    setRefresh((refresh) => refresh +1);
  }
  return (
    <Container style={containerStyle}>

      { 
        fail == true && <MobileWarningPopup callback={failcallback} content={content} />
      }

      <Label>일을 완료 하고 일감 결과를 사진으로 첨부해주세요. 최대 5장 까지 첨부할수 있습니다</Label>
      <BoxLayer>
        <BoxItem onClick={handleUploadClick}>
          <GoPlusCircle size={20}/>
        </BoxItem>
        {
          images.map((data, index)=>(
            <BoxItem onClick={()=>{handleImageDelete(index)}} >
              <img src={data} style={{width:"100%"}}/>
            </BoxItem>
          ))
        }
        <input
            type="file"
            ref={fileInput}
            onChange={handlefileuploadChange}
            style={{ display: "none" }}
            />
        <textarea style={ResultContent} value={result}
        placeholder={'간단하게 완료 된 일에 대해 결과를 적어주세요'}
          onChange={(e) => {
            setResult(e.target.value);
            setRefresh((refresh) => refresh +1);
        }}
        />
      </BoxLayer>

            
      <div style={{margin:"20px auto 50px", width:"100%"}}>
              <ButtonEx text={'일감완료'} width={'100'}  
              onPress={_handleComplete} bgcolor={'#FF7125'} color={'#fff'} />
      </div>
     


    </Container>
  );

}

export default MobileCompletecontainer;

