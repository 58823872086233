
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { ReadCommunitySummary } from "../service/CommunityService";
import TimeAgo from 'react-timeago';
import { getFullTime } from "../utility/date";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingCommunityStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { PCCOMMNUNITYMENU } from "../utility/screen";
import { ReadRECIPE } from "../service/RecipeService";

import { shuffleArray } from "../utility/common";
import LazyImage from "../common/LasyImage";

import { FiPlus } from "react-icons/fi";
import ButtonEx from "../common/ButtonEx";
import Empty from "./Empty";
import WorkCalendar from "./WorkCalendar";
import { BiTask } from "react-icons/bi";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;

`
const style = {
  display: "flex"
};



const BoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`



const FreezeBoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FreezeBoxItem = styled.div`
  width : 49%;
  background: #fbecc2;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction:column;
  border-radius: 5px;
  margin-bottom:5px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}



const RecommendTitle = styled.div`
  font-family: 'Pretendard-Bold';
  margin: 20px 0px;

`
const Recipename = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
`
const RecommendButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  color : #999;
`
const AddButton2 = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  color : #fff;
  display:flex;
  background :#0070EB;
  padding :5px 8px;
  border-radius :10px;
`
const AddButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  color : #ff7e19;
  display : flex;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`

const Recipetip = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
`
const Recipereview = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
margin-top:10px;
`

const Tag1 = styled.div`

font-size: 12px;
background: #fff;
color: #070606;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
`
const Tag2= styled.div`

  font-size: 12px;
  background: #fff;
  color: #070606;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left:5px;
`

const EmptyLine = styled.div`
  height: 2px;
  background: #ededed;
  margin: 20px 0px;

`
const Dday = styled.div`
  font-size: 10px;
  border: 1px solid #ff7e19;
  background-color : #ff7e19;
  border-radius: 20px;
  color: #fff;
  padding: 0px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';
`

const Alarm = styled.div`

  font-size: 10px;
  border: 1px solid #1982ff;
  background-color : #1982ff;
  border-radius: 20px;
  color: #fff;
  padding: 0px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';


`
const Name = styled.div`
  color: #999;
  font-size: 14px;
  font-family: 'Pretendard-Light';

`
const Time = styled.div`
  color: #999;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

`
const Freezename = styled.div`
  font-size:16px;

`
const Property = styled.div`
  font-size:10px;

`
const FoodContentItems = [
  {fooditem : "쇠고기",dday : "D-7",alarm : true,check : false},
  {fooditem : "우유",dday : "D-3",alarm : true,check : false},
  {fooditem : "갈치",dday : "D-6",alarm : false,check : false},
  {fooditem : "오리",dday : "",alarm : false,check : false},
  {fooditem : "돼지고기",dday : "",alarm : false,check : false},
]

const BoxItem = styled.div`
  width: 90%;
  margin: 10px auto;
  background: #f9f9f9;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-SemiBold;
`
const Task = styled.div`

`
const UnTask = styled.div`
  text-decoration:line-through;
`

const MobileWorkBoard =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */




  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [loading, setLoading] = useState(true);


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

    setLoading(false);



  },[refresh])


  const _handleRecommend = () =>{


  }






  return (

    <Container style={containerStyle}>     
    {
      loading == true ? (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'}/>)
      :(
        <Column style={{marginTop:10,width:"100%",margin:"0 auto 50px", paddingTop:"0px"}}>


          <div style={{overflowY:"hidden",width:"90%",margin:"0 auto"}}>


  

          <BetweenRow style={{fontFamily:"Pretendard-SemiBold", fontSize:25, margin:"20px 0px"}}>
            <div>11월 11일</div>
            <AddButton2 onClick={_handleRecommend}>
            <BiTask size={16} color={'#fff'}/>
            <Row>가사일 추가</Row>   
            </AddButton2>
          </BetweenRow>


      

          <WorkCalendar/>


          <EmptyLine/>

          <BetweenRow style={{width:"100%"}}>
          <RecommendTitle>오늘 할일</RecommendTitle>
          </BetweenRow>

          <div>
            <BoxItem>
              <Task>집청소</Task>
              <Column>
              <Name>일 맡은 사람 은서아빠</Name>
              <Time>20:00</Time>
              </Column>
            </BoxItem>
          </div>

          <EmptyLine/>

          <BetweenRow style={{width:"100%"}}>
          <RecommendTitle>완료된 작업</RecommendTitle>
          </BetweenRow>
          <div>
            <BoxItem>
              <UnTask>우체국가기</UnTask>
              <Column>
              <Name>일 맡은 사람 엄마</Name>
              <Time>09:30</Time>
              </Column>
            </BoxItem>
          </div>

          <div>
            <BoxItem>
              <UnTask>공부방청소</UnTask>
              <Column>
              <Name>일 맡은 사람 우리딸</Name>
              <Time>13:00</Time>
              </Column>
            </BoxItem>
          </div>
      
          </div>  
        </Column>)
    }  
    </Container>
  );

}

export default MobileWorkBoard;

