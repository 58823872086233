import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useSpring, animated } from '@react-spring/web';

import styled from 'styled-components';
import { imageDB } from '../utility/imageData';
import { BetweenRow, FlexstartRow, Row } from '../common/Row';
import { UserContext } from '../context/User';
import { ReadCATEGORY } from '../service/CategoryService';
import { useNavigate } from 'react-router-dom';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { ko, se } from 'date-fns/locale';
import { FaRegCalendarCheck } from "react-icons/fa6";
import MobileCalendarPopup from './MobileCalendarPopup';
import { CreateFreeze } from '../service/FreezeService';
import { sleep } from '../utility/common';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};
//   transform: 'translate(-50%, -50%)',
const style = {
    position: 'absolute',
    top: '70%',
    left: '50%',
    height:'450px',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '14px 34px',
    zIndex:100,
};
const IconCloseView = styled.div`

`
const MainData = styled.div`
  display :flex;
  flex-direction:row;
  background-color : #fff;
  flex-wrap : wrap;
  margin: 0 auto;
  width:80%;
  padding-right: 30px;
`
// background-color :  ${({check}) => check == 1 ? "#ff4e193b" : "#EDEDED" }; 
const MainDataItem = styled.div`
    padding: 5px 10px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    border-radius: 5px;
    width: 40%;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 10px;
`
const MainDataItemText = styled.span`
  font-size :16px;
  font-weight:500;
  font-family : ${({theme}) =>theme.REGULAR};
  color :  ${({check}) => check == 1 ? "#FF4E19" : "#000" };  

`
const ApplyItem = styled.div`
  display :flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  background-color : #fff;
  margin-bottom : 20px;
`
const FilterApplyButton = styled.div`
    background-color :#FF7125;
    padding :0px 24px;
    border-radius :100px;
    height:46px;
    display:flex;
    justify-content:center;
    align-items:center;

`
const FilterApplyButtonText = styled.span`
  color :#fff;
  font-size :18px;
  font-family : ${({theme}) =>theme.REGULAR};
  font-weight:700;
`

const HeaderPopupline = styled.div`

  width:20%;
  background:#E3E3E3;
  height:4px;
`

const MobileResultContent = {
  width: '75%',
  height: '250px',
  padding: '0px 50px',
  margin : "0px auto",
  fontSize: '16px',
  fontFamily: 'Pretendard-Regular',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",

}

const Inputstyle={
  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "1px solid #EDEDED",

}

const Label = styled.div`
  font-size: 16px;
  color: rgb(19, 19, 19);
  width: 40%;
  margin: 10px auto;
`

export const StyledCalendarWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  position: relative;
  margin : 0 auto;
`



// 캘린더를 불러옴
export const StyledCalendar = styled(Calendar)`

width: 100%;
background: white;
border: none;
line-height: 0.9em;
font-size: 12px; /* 글자 크기 */
text-decoration: none; /* 밑줄 제거 */

.react-calendar__navigation button {
  color: #4d4d4d;
  min-width: 44px;
  background: none;
  font-size: 20px; /* 네비게이션 버튼 글자 크기 */
  margin-top: 8px;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 14px; /* 요일 이름 글자 크기 */
  color: #6b6b6b;
  text-decoration: none; /* 밑줄 제거 */
}

.react-calendar__tile {
  background: none;
  font-size: 16px; /* 날짜 타일 글자 크기 */
  color: #4d4d4d;
  padding: 5px 6.6667px;
}

.react-calendar__tile--now {
  font-size: 14px;

 }
 .react-calendar__tile:disabled {
  color: #d6cfcf !important;
 }

.react-calendar__tile--active {
  background: #ff7e19 !important;
  color: white !important;
  border-radius : 20px;
  -webkit-tap-highlight-color: transparent; /* iOS 클릭 시 하이라이트 제거 */
}
.react-calendar__tile .react-calendar__tile--active{
  background: #ff7e19 !important;
  color: white !important;
  border-radius : 20px;
  -webkit-tap-highlight-color: transparent; /* iOS 클릭 시 하이라이트 제거 */
}

.react-calendar__tile--hover {
  background: #1087ff;
}

.react-calendar__tile--active,
.react-calendar__tile:focus,
.react-calendar__tile:active {
  background: #ff7e19 !important;
  color: white !important;

`;



export default function MobileRecipeadd({callback}) {
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState(-1);
  const [categoryitems, setCategoryitems] = React.useState([]);
  const navigate = useNavigate();
  const { dispatch, user } = React.useContext(UserContext);

  const [ingredient, setIngredient] = React.useState('');

  const [icecheck, setIcecheck] = React.useState(false);
  const [alarmcheck, setAlarmcheck] = React.useState(false);

  const [calendarpopup, setCalendarpopup] = React.useState('');
  const [calendarpopup1, setCalendarpopup1] = React.useState('');
  const [startdate, setStartdate]= React.useState('');
  const [lastdate, setLastdate]= React.useState('');



  const handleClose = () =>{
    setOpen(false);
    callback('');
  } 



  React.useEffect(()=>{

    setIngredient(ingredient);
    setIcecheck(icecheck);
    setAlarmcheck(alarmcheck);
    setCalendarpopup(calendarpopup);
    setCalendarpopup1(calendarpopup1);
    setStartdate(startdate);
    setLastdate(lastdate);


  },[refresh])

  React.useEffect(()=>{
    async function FetchData(){

    }
    FetchData();
  }, [])

  const _handleadd = async()=>{

    const USERS_ID = user.users_id;
    const NAME = ingredient;
    const STARTDATE = startdate;
    const ENDDATE = lastdate;
    const ALARM = alarmcheck;

    const addfreeze = await CreateFreeze({USERS_ID,NAME, STARTDATE, ENDDATE, ALARM})


    await sleep(2000);

    callback();
    setOpen(false);
    setRefresh((refresh)=> refresh +1);
  }

  const _handleicecheck = () =>{
    if(icecheck){
      setIcecheck(false);
    }else{
      setIcecheck(true);
    }
    setRefresh((refresh)=> refresh +1);
  }


  const _handlealarmcheck = () =>{
    if(alarmcheck){
      setAlarmcheck(false);
    }else{
      setAlarmcheck(true);
    }
    setRefresh((refresh)=> refresh +1);
  }

  const _handleStart = () =>{
    setCalendarpopup(true);
    setRefresh((refresh)=> refresh +1);
  }

  const _handleLast = () =>{
    setCalendarpopup1(true);
    setRefresh((refresh)=> refresh +1);
  }

  const startcallback = (data)=>{
    setStartdate(data);
    setCalendarpopup(false);
    setRefresh((refresh)=> refresh +1);
  }
  const lastcallback = (data)=>{
    setLastdate(data);
    setCalendarpopup1(false);
    setRefresh((refresh)=> refresh +1);
  }
  return (
    <div>

      {
        calendarpopup == true && <MobileCalendarPopup callback={startcallback}/>
      }
     {
        calendarpopup1 == true && <MobileCalendarPopup callback={lastcallback}/>
      }
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
              <Row>
                <HeaderPopupline/>
              </Row>
       

              <BetweenRow style={{width:"75%", margin: "20px auto", }}>
                <div style={{fontSize:"18px", fontWeight:900, color:"#131313", fontFamily:'Pretendard-SemiBold'}}>식재료 추가</div>
                <IconCloseView onClick={handleClose} >
                <img src={imageDB.close} style={{width:"22px", height:"22px"}}/>
                </IconCloseView>
             </BetweenRow>

            <Row style={{width:"75%", margin:"15px auto"}}>

            <Label>식재료이름</Label>
            <input  type="text" value ={ingredient} style={Inputstyle}
              placeholder={'추가할 식재료를 넣어주세요'}
              onChange={(e) => {
                setIngredient(e.target.value);
                setRefresh((refresh) => refresh +1);
             }}
            ></input>
            </Row>
     
            <Row style={{width:"75%", margin:"15px auto"}}>
            <Label>구매일자</Label>

            <FlexstartRow style={{width:"100%"}}>
              <FaRegCalendarCheck onClick={_handleStart}/>
              {
                startdate == '' ? (<div style={{paddingLeft:5}}>구매일자를 선택해주세요</div>) :(
                  <div style={{paddingLeft:5}}>{startdate}</div>
                )
              }
           
            </FlexstartRow>

            </Row>

            <Row style={{width:"75%", margin:"15px auto"}}>
              <Label>유효일자</Label>
  
              <FlexstartRow style={{width:"100%"}}>
                  <FaRegCalendarCheck onClick={_handleLast}/>
                  {
                  lastdate == '' ? (<div style={{paddingLeft:5}}>유효일자를 선택해주세요</div>) :(
                    <div style={{paddingLeft:5}}>{lastdate}</div>
                  )
                }
              </FlexstartRow>
             </Row>
        

  


            {/* <Row style={{width:"75%", margin:"15px auto"}}>
            <Label  style={{width:"100%"}}>냉동보관 선택</Label>
            <div style={{display:"flex", justifyContent:"flex-end"}} onClick={_handleicecheck}>
              {
                icecheck == true ? ( <img src={imageDB.radioenable}  style={{width:"50px"}}/>) :( <img src={imageDB.radiodisable}  style={{width:"50px"}}/>)
              }
             
            </div>
            </Row> */}

            <Row style={{width:"75%", margin:"15px auto"}}>
            <Label style={{width:"60%"}}>유효일자 도달시 알람발생</Label>
            <div style={{width:"40%",display:"flex", justifyContent:"flex-end"}} onClick={_handlealarmcheck}>
            {
                alarmcheck == true ? ( <img src={imageDB.radioenable}  style={{width:"50px"}}/>) :( <img src={imageDB.radiodisable}  style={{width:"50px"}}/>)
              }
            </div>
            </Row>


             <ApplyItem>
                <div style={{dispaly:"flex", alignItems:"center", justifyContent:"center", width:"50%"}}>   
                    <FilterApplyButton onClick ={_handleadd}><FilterApplyButtonText>추가</FilterApplyButtonText></FilterApplyButton>
                </div>
              </ApplyItem>

   
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}