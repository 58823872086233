import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";
import { imageDB } from "../utility/imageData";



const Container = styled.div`
  height :${({height}) => height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom:50px;

`
const style = {
  display: "flex"
};

const Emptychat =({containerStyle, content, height}) =>  {
 
  return (

    <Container style={containerStyle} height={height} >
       <img src={imageDB.community_d} style={{height:50, width:50}}/>
       <div style={{fontSize:14, marginTop:10}}>{content}</div>
   
    </Container>
  );

}

export default Emptychat;

