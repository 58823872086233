import React, {useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ButtonEx from "../../../common/ButtonEx";
import { BetweenColumn, Column, FlexstartColumn } from "../../../common/Column";
import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import Text from "../../../common/Text";
import { UserContext } from "../../../context/User";
import { CONFIGMOVE, EventItems, ReviewContent } from "../../../utility/screen";

import "../../../screen/css/common.css";
import { FaTemperatureHigh } from "react-icons/fa";

import { GrTransaction } from "react-icons/gr";
import { FaRegHeart } from "react-icons/fa";
import { DiResponsive } from "react-icons/di";
import { TbRelationOneToOne } from "react-icons/tb";
import { RiArrowRightSLine } from "react-icons/ri";
import { uploadImage } from "../../../service/UploadService";
import { Readuserbyusersid, Update_userinfobyusersid } from "../../../service/UserService";
import { FaCamera } from "react-icons/fa";
import { BADGE } from "../../../utility/badge";
import { imageDB } from "../../../utility/imageData";
import { PiLockKeyLight } from "react-icons/pi"
import LottieAnimation from "../../../common/LottieAnimation";
import { LoadingChatAnimationStyle, LoadingMainAnimationStyle, LoadingPCPhoneAnimationStyle, LoadingProfileAnimationStyle } from "../../../screen/css/common";
import { UpdateWorkInfoImageAll } from "../../../service/WorkService";
import { UpdateRoomInfoImageAll } from "../../../service/RoomService";
import Mobileheader from "../../../screen/Layout/Header/Mobileheader";
import PCConfigHeader from "../../PCConfigHeader";
import BadgePopup from "../../../modal/BadgePopup/BadgePopup";
import { IoIosCloseCircle } from "react-icons/io";
import { getDate, getDateFullTime, getFullTime } from "../../../utility/date";
import { GiTruce } from "react-icons/gi";


const Container = styled.div`
  padding-bottom:30px;
  min-height:700px;
  background:#f7f7f7;
  

`
const style = {
  display: "flex"
};

const BoxItem = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px auto 10px;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
`

const Name = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
`

const Number = styled.div`
  font-family: 'Pretendard-Light';
  font-size: 14px;

`

const Badge = styled.div`
  font-size: 16px;
  padding-left:5px;
`
const Date = styled.div`
  font-size: 14px;
  color : #666;
`

const TemperatureLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #131313;
  font-family: 'Pretendard-SemiBold';
`
const PointBox = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin : 10px 0px;
  flex-direction : row;
  padding: 15px 10px;

`
const PointBoxInner = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 5px;
 

`
const SubLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
`
const SubLabelContent = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding: 20px 0px;
`
const Point = styled.div`
  color: #ff4e19;
  padding: 10px 18px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Jalnan2';
  border: 2px dotted #ff4e19;

`
const ULITEM = styled.ul`
  padding-left: 15px;
  list-style-type: disc;
  line-height: 2;
  margin-top: 10px;
`

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  width: 33%;
  border-radius: 15px;
  height:110px;


`
const BoxImg = styled.div`
  border-radius: 50px;
  background: ${({enable}) => enable == true ? ('#ff7e19'):('#ededed')};
  padding: 20px;
  display :flex;
`
const BoxImg2 = styled.div`
  border-radius: 50px;
  background: ${({enable}) => enable == true ? ('#ff7e19'):('#ededed')};
  padding: 30px;
  display :flex;
`
const Poupup = styled.div`
  position: fixed;
  bottom: 0px;
  width: 400px;
  height: 300px;
  background: #fffdfd;
  left: 36%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 1px solid #ededed;
  border-bottom: none;

`
const HeaderItem = styled.div`
  font-family : Pretendard-SemiBold;
  font-size:16px;
`

const ResponseData = styled.div`
  border: 1px solid #ff7e19;
  width: 60%;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  color: #ff7e19;
`
const AuthItem = styled.div`
  display: flex;
  border: 1px solid #ff7e19;
  color: #ff7319;
  margin-left: 20px;
  border-radius: 15px;
  padding: 0px 10px;
  font-size: 12px;
`

const MainDataItem = styled.div`
  padding :10px;
  justify-content : center;
  align-items : center;
  border-radius :5px;
  background-color :  ${({check}) => check == 1 ? "#ff4e193b" : "#fff" }; 
  margin-bottom: 10px;
  border  :1px solid #ededed;
  margin-top:20px;
`
const MainDataItemText = styled.span`
  font-size :12px;
  font-family : ${({theme}) =>theme.REGULAR};
  color :  ${({check}) => check == 1 ? "#FF4E19" : "#000" };  

`



const WorkItems=[
  {name : BADGE.WORKER1, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER2, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER3, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER4, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER5, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER6, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER7, img:"", enable:true,desc : BADGE.WORKER1},
  {name : BADGE.WORKER8, img:imageDB.babycare, enable:true,desc : BADGE.WORKER1},
  {name : BADGE.WORKER9, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER10, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER11, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER12, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER13, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER14, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER15, img:"", enable:true,desc : BADGE.WORKER1},
  {name : BADGE.WORKER16, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER17, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER18, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER19, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER20, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER21, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER22, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER23, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER24, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER25, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER26, img:"", enable:false,desc : BADGE.WORKER1},
  {name : BADGE.WORKER27, img:"", enable:false,desc : BADGE.WORKER1},


]



const PCProfileConfig =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */

 const fileInput = useRef();


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [temperature, setTemperature] = useState(5);
  const [img, setImg] = useState('');

  const [badge, setBadge] = useState(false);
  const [index, setIndex] = useState('');
  const [useritem, setUseritem] = useState({});
  const [currentloading, setCurrentloading] = useState(true);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setTemperature(temperature);
    setImg(img);
    setCurrentloading(currentloading);
    setBadge(badge);
    setIndex(index);
    setUseritem(useritem);
  }, [refresh])

  useLayoutEffect(()=>{

      async function FetchData(){
     

        const USERS_ID = user.users_id;
        const useritem = await Readuserbyusersid({USERS_ID});

        let number = String(useritem.CREATEDT);
        
        setIndex(number.slice(5,number.length));
        setUseritem(useritem);
        setImg(useritem.USERINFO.userimg);
        setCurrentloading(false);
        setRefresh((refesh) => refresh +1);
      }
      FetchData();
  }, [])

  const handleUploadClick = (e) => {
    fileInput.current.click();
  };

  const ImageUpload = async (data, data2) => {
    const uri = data;
    const random = data2;
    const URL = await uploadImage({ uri, random });
    return URL;
  };

    
  const handlefileuploadChange = async (e) => {
    let filename = "";
    setLoading(true);
    setRefresh((refresh) => refresh +1);
    const file = e.target.files[0];
    filename = file.name;

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;


        img.onload = function() {
          // 원본 이미지 크기
          const originalWidth = img.width;
          console.log("TCL: img.onload -> originalWidth", originalWidth)
       
          const originalHeight = img.height;
   
  
          // 원하는 이미지 크기 (예: 300x300으로 크기 조정)
          const targetWidth = 100;
          const targetHeight = 100;
  
          // Canvas 생성
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // 비율 유지를 위해 축소
          let width = originalWidth;
          let height = originalHeight;
          if (width > height) {
              if (width > targetWidth) {
                  height *= targetWidth / width;
                  width = targetWidth;
              }
          } else {
              if (height > targetHeight) {
                  width *= targetHeight / height;
                  height = targetHeight;
              }
          }
  
          // 리사이즈된 캔버스 크기 설정
          canvas.width = width;
          canvas.height = height;
  
          // 이미지 리사이즈하여 캔버스에 그리기
          ctx.drawImage(img, 0, 0, width, height);
          console.log("TCL: img.onload -> ctx", ctx)
          console.log("TCL: img.onload -> img", img)
  
          // 캔버스 데이터를 Blob으로 변환 (JPEG 포맷, 품질 0.8)
          // Canvas 데이터를 base64로 변환
          const base64Image = canvas.toDataURL('image/jpeg', 0.8);  // 품질 0.8로 JPEG 변환

          resolve(base64Image);

          
        }


      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;

      let url = await ImageUpload(uri, email);
      console.log("TCL: handlefileuploadChange -> email", email)
      const IMGTYPE = true;


      setImg(url);

      user.userimg = url;
      dispatch(user);

      const USERINFO = user;
      const USERS_ID = user.users_id;


      const USERIMG = url;
      // 모든 체팅 업데이트 
      
      // 모든 일감 업데이트
      await UpdateWorkInfoImageAll({USERS_ID, USERIMG });
      // 모든 장소대여 업데이트
      await UpdateRoomInfoImageAll({USERS_ID, USERIMG });

      await Update_userinfobyusersid({USERINFO, USERS_ID});
      console.log("TCL: _handleSave -> user", USERINFO, USERS_ID);


      setLoading(false);
      setRefresh((refresh) => refresh +1);



    });
  };

  

  const _handleNameMove = () =>{
    navigate("/PCconfigcontent",{state :{NAME :CONFIGMOVE.PROFILENAME, TYPE : ""}});
  }

  const _handleBadge= () =>{
    setBadge(true);

    setRefresh((refresh) => refresh +1);
  }

  const _handleClose = (data) =>{
    setBadge(false);

    setRefresh((refresh) => refresh +1);
  }

 
  return (

    <Container style={containerStyle}>

      {badge == true ? (
          <Poupup>
            <Column>

              <Column style={{margin:"20px 0px 20px"}}>
                <BoxImg2 enable={true}>
                <img src={imageDB.medal} style={{width:45, height:45}}/>
                </BoxImg2>
              </Column>

              <Column>
              <div style={{fontFamily:'Pretendard-Bold', fontSize:20}}>
                나눔은 습관
              </div>
              
              </Column>

              <Column style={{marginTop:10}}>
                <div style={{fontFamily:'Pretendard-Light'}}>뱃지를 획득하는 방법 : </div>
                <div style={{fontFamily:'Pretendard-Light'}}>나눔을 30회 이상 해보세요</div>
              </Column>

           
              <div style={{margin:"20px auto", width:"100%"}}>
                <ButtonEx text={'닫기'} width={'85'}  
                onPress={_handleClose} bgcolor={'#FF7125'} color={'#fff'} />
              </div>
    

            
            </Column>
         

        
          </Poupup>
      ) : null}

      {
        currentloading == true  ? (<LottieAnimation containerStyle={LoadingProfileAnimationStyle} animationData={imageDB.loadinglarge}/>)
        :(<div>
          <BoxItem style={{marginTop:10}}>
            <Column style={{justifyContent:"space-between", width:"100%"}}>
              <Row style={{justifyContent:"flex-start", width:"90%"}}>
                <div style={{width:"70px", height:"70px", borderRadius:"70px"}}>
                  <img src={img} style={{width:"60px", height:"60px", borderRadius:"70px"}}/>
                </div>

            
                <div style={{position:"relative", top:20, left:-15}} onClick={handleUploadClick}><FaCamera size="24px"/></div>

                <Column style={{justifyContent:"flex-start",alignItems:"flex-start"}}>
                  <BetweenRow style={{width:"220px",justifyContent:"space-between"}}>
                    <Name>{user.nickname}</Name>
                    <Number>#{index}</Number>
          
                  </BetweenRow>
                  <Row>
                    {/* <img src={imageDB.medal} style={{width:34, height:34}}/>
                    <Badge>{'활동뱃지 3개 보유'}</Badge> */}
                    <Date>가입일 {getDate(useritem.CREATEDT)}</Date>
                  </Row>
                </Column>
              </Row>  

              <input
              type="file"
              ref={fileInput}
              onChange={handlefileuploadChange}
              style={{ display: "none" }}
              />

              <ButtonEx text={'대화명 설정'} width={'90'}  onPress={_handleNameMove}
              containerStyle={{marginTop:10, fontFamily: "Pretendard-Bold", fontSize:14, width:"80%",height:"40px"}}
              bgcolor={'#f0f0f0'} color={'#242424'} />

            {loading == true ? (<LottieAnimation containerStyle={LoadingChatAnimationStyle} animationData={imageDB.loadinglarge}
                      width={"100px"} height={'100px'}/>) :("")}

            </Column>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>주소지 인증 내역입니다</HeaderItem>
            <ULITEM>

              {
                useritem.ADDRESSITEMS.map((data, index)=>(
                  <li>
                  <FlexstartRow>
                  <div>{data.ADDR}</div>
                  {
                    data.AUTH == true && <AuthItem>인증완료</AuthItem>
                  }
                  
                  </FlexstartRow>
        
                </li>
                ))
              }
      
    
            </ULITEM>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>대화명 변경 내역입니다</HeaderItem>
            <ULITEM>
              {
                useritem.NAMEITEMS.map((data, index) =>(
                  <>
                    {index == 0 && <li>{data.name} 으로 {getDate(data.adjustdate)}에 최초가입</li>}
                    {index != 0 && <li>{data.name} 으로 {getDate(data.adjustdate)}에 변경</li>}
                  </>
                 
                ))
              }
           
      
            </ULITEM>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>활동 이력 입니다</HeaderItem>
            <ULITEM>
            <li>활동 내역이 없습니다</li>
            </ULITEM>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>후기내역 입니다</HeaderItem>
            <ULITEM>
            <li>후기내역이 없습니다</li>
            </ULITEM>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>받은 매너평가</HeaderItem>  
            <div style={{marginTop:10}}>
            {
              ReviewContent.map((data)=>(
                <MainDataItem check={data.count>0}><MainDataItemText check={data.count>0}>
                  <FlexstartRow>
                    <div style={{fontSize:20}}>{data.imagecontent}</div>
                    <div style={{paddingLeft:10,fontSize:14}}>{data.content}</div>
                    {
                      data.count > 0 && <div style={{paddingLeft:30,fontSize:14}}>{data.count}</div>
                    }
                  </FlexstartRow>
                  
                </MainDataItemText></MainDataItem>
              ))
            }
            </div>
   
          </BoxItem>
          <BoxItem> 
            <HeaderItem>내가쓴 게시물 입니다</HeaderItem>
            <ULITEM>
            <li>내가 쓴 게시물 내역이 없습니다</li>
            </ULITEM>
          </BoxItem>
          <BoxItem> 
            <HeaderItem>채팅응답률입니다</HeaderItem>
            <ULITEM style={{paddingLeft:"unset"}}>
            <ResponseData>평균응답시간은 10분이내입니다</ResponseData>
            </ULITEM>
          </BoxItem>
          <BoxItem>
            <SubLabel>
              <Row> 
                <SubLabelContent>홍여사 활동뱃지</SubLabelContent>
              </Row>
            </SubLabel>
            <Column style={{width:"95%",margin: "0 auto"}} >   
                <BetweenRow style={{flexWrap:"wrap", width:"100%"}}>
                  {
                    WorkItems.map((data, index)=>(
                      <Box onClick={()=>{_handleBadge(data)}}  >
                        <BoxImg enable={data.enable}>
                          
                          {
                            data.enable == true ?  (
                              <img src={imageDB.medal} style={{width:34, height:34}}/>
                            ) 
                            :(  <PiLockKeyLight size={28} color={"#8c8b8b"}/>)
                          }
                        
                        
                        </BoxImg>
                        <div style={{ fontSize:12, color:"#131313", fontFamily:"Pretendard-SemiBold", marginTop:5}}>{data.name}</div>
                      </Box>
                    ))
                  }
                </BetweenRow>
            </Column>   
          </BoxItem>
        </div>)
      }


    </Container>
  );

}

export default PCProfileConfig;

