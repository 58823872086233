import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";

import Button from "../../../common/Button";
import { DataContext } from "../../../context/Data";

import { readuserbydeviceid, Update_attendancebyusersid } from "../../../service/UserService";
import { getDateEx, getDateFullTime } from "../../../utility/date";
import { useDispatch } from "react-redux";
import { ALLREFRESH } from "../../../store/menu/MenuSlice";
import { shuffleArray, sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";
import RotateExample from "../../../common/Example";
import RotateBasicExample from "../../../common/ExampleBasic";
import ButtonEx from "../../../common/ButtonEx";


const Container = styled.div`
  padding-top:10px;

`
const style = {
  display: "flex"
};

const Attdendanceday = 3;



const GameItems = [
  {image:imageDB.game1,open:false},
  {image:imageDB.game2,open:false},
  {image:imageDB.game3,open:false},
  {image:imageDB.game4,open:false},
  {image:imageDB.game5,open:false},
  {image:imageDB.game6,open:false},
  {image:imageDB.game7,open:false},
  {image:imageDB.game8,open:false},
  {image:imageDB.game9,open:false},
  {image:imageDB.game10,open:false},
  {image:imageDB.game11,open:false},
  {image:imageDB.game12,open:false},
  {image:imageDB.game13,open:false},
  {image:imageDB.game14,open:false},
  // {image:imageDB.game15,open:false},
  // {image:imageDB.game16,open:false},
  // {image:imageDB.game17,open:false},
  // {image:imageDB.game18,open:false},
  // {image:imageDB.game19,open:false},
  // {image:imageDB.game20,open:false},
  {image:imageDB.game1,open:false},
  {image:imageDB.game2,open:false},
  {image:imageDB.game3,open:false},
  {image:imageDB.game4,open:false},
  {image:imageDB.game5,open:false},
  {image:imageDB.game6,open:false},
  {image:imageDB.game7,open:false},
  {image:imageDB.game8,open:false},
  {image:imageDB.game9,open:false},
  {image:imageDB.game10,open:false},
  {image:imageDB.game11,open:false},
  {image:imageDB.game12,open:false},
  {image:imageDB.game13,open:false},
  {image:imageDB.game14,open:false},
  // {image:imageDB.game15,open:false},
  // {image:imageDB.game16,open:false},
  // {image:imageDB.game17,open:false},
  // {image:imageDB.game18,open:false},
  // {image:imageDB.game19,open:false},
  // {image:imageDB.game20,open:false},

]

const ButtonSt = styled.div`

    position: absolute;
    background: #ff7e19;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    color :#fff;
    font-family:Pretendard-SemiBold;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border : 2px double #fff;
    

`
const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  font-size: 20px;
  font-family: 'Pretendard-Bold';

`
const MobileGame =({containerStyle}) =>  {

  const [seconds, setSeconds] = useState(0); // 10초 카운트다운

  

  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [items, setItems] = useState(shuffleArray(GameItems));

  const [initialize, setInitialize] = useState(true);
  const [isRunning, setIsRunning] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);




  useEffect(() => {
    let timer = null;
    if (isRunning) {
      data.gameitems = [];
      datadispatch(data);

      // 타이머 실행
      timer = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    } else {
      // 타이머 정지
      clearInterval(timer);
    }
    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearInterval(timer);
  }, [isRunning]);

  useEffect(()=>{

    setItems(items);
    setInitialize(initialize);
    setSeconds(seconds);

  },[refresh])


  /**
   * 초기 페이지 진입시에는 context에 있는 정보를 가져온다
  
   */

  useEffect(()=>{

    
    async function OpenFunc(){
   

    }
  

    OpenFunc();


  }, [])

  const _handleOpen= async(item) => {


    const FindIndex = items.findIndex(x=>x ==item);
    console.log("🚀 ~ const_handleOpen=async ~ FindIndex:", FindIndex)
    
    items[FindIndex].open = false;

    setItems(items);

    console.log("🚀 ~ const_handleOpen=async ~ items:", items[FindIndex])


    setRefresh((refresh) => refresh +1);

    
  }

  const _handleStart =() =>{
    setInitialize(false);
    setIsRunning(true);
    setRefresh((refresh) => refresh +1);
  }
  const _handleStop =() =>{
    setIsRunning(false);
    setInitialize(true);
    setRefresh((refresh) => refresh +1);
    setSeconds(0);
    data.gameitems = [];
    datadispatch(data);
  }

  // 분과 초로 변환
  const minutes = Math.floor(seconds / 60); // 총 초를 60으로 나눈 몫이 분
  const remainingSeconds = seconds % 60; // 총 초를 60으로 나눈 나머지가 초


  return (
    <Container style={containerStyle}>
        <Row margin={'0px auto;'} width={'98%'} style={{background:"#fff", flexWrap:"wrap"}} >

        {
          initialize == true ? (  <>
            {
              items.map((data, index)=>(
                <RotateBasicExample image={data.image} open={data.open} index = {index}/>
              ))
            }
            </>) :(  <>
            {
              items.map((data, index)=>(
                <RotateExample image={data.image} open={data.open} item = {data} index={index}/>
              ))
          
            }
            </>)
        }
        
        
        {
          initialize == true && <ButtonSt onClick={_handleStart}>도전하기</ButtonSt>
        }

        <BetweenRow style={{width:"100%"}}>
          <div style={{width:"50%"}}> 
            <ButtonEx text={'게임멈추기'} width={'85'}  
              onPress={_handleStop} bgcolor={'#F9F9F9'} color={'#131313'} 
              containerStyle={{fontFamily:"Pretendard-Light", boxShadow:"none", border:"1px solid #131313"}} />
          </div>
          <BetweenRow style={{width:"50%", fontSize:20, padding:"0px 20px"}}> <Time>도전시간</Time>
          {minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</BetweenRow>
        </BetweenRow>

                 
     
     
        </Row>
    </Container>
  );

}

export default MobileGame;

