

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/User";
import MobileFooter from "../Footer/MobileFooter";
import MobileChatContentheader from "../Header/MobileChatContentheader";
import MobileCommunityheader from "../Header/MobileCommunityheader";


const MobileChatContentLayout = (props) => {
  const { user, dispatch } = useContext(UserContext);
  const navigation = useNavigate();


  return (
    <div style={{ovderflow:"hidden"}}> 
      <MobileChatContentheader  name ={props.name} image={props.image}/>
      <main>
        {props.children}
      </main>

    </div>
  );
};

export default MobileChatContentLayout;
