import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";

import { GrPrevious } from "react-icons/gr";


const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left:15px;
`
const style = {
  display: "flex"
};

const Name = styled.div`
  font-size:22px;
  padding-left:10px;
`




const PCConfigHeader =({containerStyle, name = '설정'}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

  }, [refresh])

  useEffect(()=>{
      async function FetchData(){
       
      }
      FetchData();
  }, [])
  
  const _handleprev= () =>{
    navigate(-1);
  }
  
 
  return (

    <Container style={containerStyle}>
      <GrPrevious onClick={_handleprev} size={22} />
      <Name>{name}</Name>
    </Container>
  );

}

export default PCConfigHeader;

