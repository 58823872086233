import { Table } from "@mui/material";
import { setLogLevel } from "firebase/firestore";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import { Column } from "../common/Column";
import LottieAnimation from "../common/LottieAnimation";
import { DataContext } from "../context/Data";
import { UserContext } from "../context/User";
import { LoadingCommunityStyle, LoadingLifeStyle } from "../screen/css/common";
import { ReadTourRegion } from "../service/LifeService";
import { sleep, useSleep } from "../utility/common";
import { imageDB } from "../utility/imageData";
import "./mobile.css";



const Container = styled.div`
  width:100%;
  margin: 0 auto;
  max-height:700px;

`
const style = {
  display: "flex"
};

const DetailLevel = 4;
const DetailMeter =300;
const BasicLevel =9;



const mapstyle = {
    position: "absolute",
    overflow: "hidden",
    top: '10%',
    width:'100%',
  };


const PopupWorkEx = styled.div`

  position: fixed;
  top:50px;
  left:30px;
  width: 30%;
  height:600px;
  background: #fff;
  z-index: 5;
  overflow-y: auto;
  border : 1px solid;
`

const TableLayout = styled.div`
  overflow-y: scroll;
  scroll-behavior: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
`
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}

const MapExbtn = styled.div`
  position: relative;
  top: 10px;
  left: 88%;
  z-index: 3;
  background: #f9f9f9;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Info = styled.div`
  background: #403e3e;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  color: #fff;


`
  
/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const PCLifeTourRegion =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [overlays, setOverlays] = useState([]);
  const [item, setItem] = useState(null);
  const [currentloading, setCurrentloading] = useState(true);

  const [show, setShow] = useState(true);

  const [expand, setExpand] = useState(false);

  const [curmap, setCurMap] = useState({});

  const [markerex, setMarkerex] = useState(false);

  const [level, setLevel] = useState(0);


    // useLayoutEffect(() => {
    // }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     return () => {};
    // }, []);

    useEffect(()=>{
        setOverlays(overlays);
        setCurrentloading(currentloading);
        setItem(item);
        setExpand(expand);
        setCurMap(curmap);
        setMarkerex(markerex);
        setLevel(level);
    }, [refresh])

    useEffect(()=>{
        async function FetchData(){

            if(data.tourregionitem.length == 0){
              const tourregionitem = await ReadTourRegion();
              const dataToSaveregion = JSON.parse(tourregionitem);
              data.tourregionitem = dataToSaveregion.response.body.items;
  
              datadispatch(data);

              console.log("TCL: FetchData -> data.tourregionitem 1", data.tourregionitem);
              ListmapDraw(data.tourregionitem);
      
            }else{
              await sleep(2000);
              console.log("TCL: FetchData -> data.tourregionitem 2", data.tourregionitem);
              ListmapDraw(data.tourregionitem);
            }
    
         
  
        }
        FetchData();
    }, [])

 
    function findMapIndex(id, items){
        console.log("TCL: findWorkIndex -> workitems", items)
    
       const FindIndex =  items.findIndex(x=>x.lnmadr  === id)
    
       return FindIndex;
      }

    const _handleControlFromMap = (id, items) =>{
        let FindIndex = findMapIndex(id, items);

        setItem(items[FindIndex]);


        setRefresh((refresh) => refresh+1);
    }


    const _handleClose = ()=>{
        setItem(null);
        setRefresh((refresh) => refresh +1);
    } 

    const _handleExmap = () =>{
      navigate("/Mobilemap" ,{state :{WORK_ID :"", TYPE : ""}});
    }




    function ListmapDraw(datas){

        var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
        mapOption = { 
                center: new kakao.maps.LatLng(37.625660993622, 127.14833958893), // 지도의 중심좌표
                level: BasicLevel // 지도의 확대 레벨
        };
        var map = new kakao.maps.Map(mapContainer, mapOption);
        var overlaysTmp = [];
        var overlays = [];
  
        for (var i = 0; i < datas.length; i ++){
            let overlay = {
            POSITION : {},
            NAME : "",
            ITEMS :datas,
            id :"",
            }
  
      
            let latitude = "";
            let longitude = "";
  
  
      
  
            latitude = datas[i].latitude;
            longitude =  datas[i].longitude;
  
  
            overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
            overlay.id = datas[i].lnmadr;
            overlay.NAME = datas[i].trrsrtNm;
            overlay.ITEMS = datas
            overlaysTmp.push(overlay);
  
        }
        overlaysTmp.forEach(function(overlayData, index) {
  
          var content = document.createElement('div');
          var customOverlay ={};
  
          kakao.maps.event.addListener(map, 'zoom_changed', function() {
            // 현재 지도 레벨 가져오기
            var level = map.getLevel();
  
            console.log('현재 지도 레벨: ' + level);
  
            if (level > 9) {
                // 레벨이 7보다 크면 오버레이 숨기기
                content.className = 'maptourregionlay';
  
                content.innerHTML =
                '  <a>' +
                '    <div>' +
                '    <img src="'+ imageDB.gps+'"style="width:32px;"/>' +
                '    </div>' +
                '  </a>' +
                '</div>';
                
  
                // Custom Overlay 생성
                customOverlay = new kakao.maps.CustomOverlay({
                    position: overlayData.POSITION,
                    content: content,
                    clickable: true // 클릭 가능하도록 설정
                });
  
                var customData = {
                    id: overlayData.id,
                    items : overlayData.ITEMS
                };
                customOverlay.customData = customData;
                // Custom Overlay 지도에 추가
  
                customOverlay.setMap(map);
      
                overlays.push(customOverlay);
  
  
            } else {
                  // Custom Overlay 내용 생성
  
                  content.className = 'maptourregionlay';
  
                  content.innerHTML =
                  '  <a>' +
                  '    <div>' +
                  '    <img src="'+ imageDB.tour+'"style="width:32px;"/>' +
                  '    </div>' +
                  '    <div class="title">'+overlayData.NAME +'</div>' +
                  '  </a>' +
                  '</div>';
                  
  
                  // Custom Overlay 생성
                  customOverlay = new kakao.maps.CustomOverlay({
                      position: overlayData.POSITION,
                      content: content,
                      clickable: true // 클릭 가능하도록 설정
                  });
  
                  var customData = {
                      id: overlayData.id,
                      items : overlayData.ITEMS
                  };
                  customOverlay.customData = customData;
                  // Custom Overlay 지도에 추가
  
                  customOverlay.setMap(map);
                  overlays.push(customOverlay);
            }
          });
  
      
          // 클릭 이벤트 등록 
          // 지도에서 클릭 햇을때는 리스트에서 클릭 했을때와 달리 별도로 circle을 표시할 필요는 없다
          content.addEventListener('click', function(event) {
            _handleControlFromMap(customOverlay.customData.id, customOverlay.customData.items);
  
          });
  
  
        });
  
        setCurMap(map);
  
        map.setLevel(map.getLevel() +1);
        //오버레이를 변수에 담아둔다
        setOverlays(overlays);
  
        setCurrentloading(false);
        setRefresh((refresh) => refresh +1);
    


    
    }

  /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);

    return (

    <Container style={containerStyle}>
      {
        item != null && <PopupWorkEx>
          
            <Info>상세정보</Info>
            <TableLayout>
              <Table class="work-table" style={{marginTop:20}}>
                <tbody>
                  <tr>
                    <td className="fixed-size">관광지명</td>
                    <td>{item.trrsrtNm }</td>
                  </tr>    
                  {
                    item.trrsrtSe != ''&& 
                    <tr>
                    <td className="fixed-size">관광지 구분</td>
                    <td>{item.trrsrtSe }</td>
                  </tr> 
                  }
                  {
                    item.trrsrtSe != ''&& 
                    <tr>
                    <td className="fixed-size">관광지 구분</td>
                    <td>{item.trrsrtSe }</td>
                  </tr> 
                  }
                {
                    item.cnvnncFclty != ''&& 
                    <tr>
                    <td className="fixed-size">공공편익시설정보</td>
                    <td>{item.cnvnncFclty }</td>
                  </tr> 
                  }

                  {
                    item.stayngInfo != ''&& 
                    <tr>
                    <td className="fixed-size">숙박시설정보</td>
                    <td>{item.stayngInfo }</td>
                  </tr> 
                  }


                  {
                    item.mvmAmsmtFclty != ''&& 
                    <tr>
                    <td className="fixed-size">오락시설정보</td>
                    <td>{item.mvmAmsmtFclty }</td>
                  </tr> 
                  }
                  {
                    item.recrtClturFclty != ''&& 
                    <tr>
                    <td className="fixed-size">문화시설정보</td>
                    <td>{item.recrtClturFclty }</td>
                  </tr> 
                  }
                  {
                    item.hospitalityFclty != ''&& 
                    <tr>
                    <td className="fixed-size">접객시설정보</td>
                    <td>{item.hospitalityFclty }</td>
                  </tr> 
                  }
                  {
                    item.sportFclty != ''&& 
                    <tr>
                    <td className="fixed-size">문화시설정보</td>
                    <td>{item.sportFclty }</td>
                  </tr> 
                  }
                      {
                    item.aceptncCo != ''&& 
                    <tr>
                    <td className="fixed-size">문화시설정보</td>
                    <td>{item.aceptncCo }</td>
                  </tr> 
                  }
                      {
                    item.prkplceCo != ''&& 
                    <tr>
                    <td className="fixed-size">주차가능수</td>
                    <td>{item.prkplceCo }</td>
                  </tr> 
                  }
                      {
                    item.trrsrtIntrcn != ''&& 
                    <tr>
                    <td className="fixed-size">관광지소개</td>
                    <td>{item.trrsrtIntrcn }</td>
                  </tr> 
                  }
                      {
                    item.phoneNumber != ''&& 
                    <tr>
                    <td className="fixed-size">전화번호</td>
                    <td>{item.phoneNumber }</td>
                  </tr> 
                  }

                  {
                    item.institutionNm != ''&& 
                    <tr>
                    <td className="fixed-size">관리기관</td>
                    <td>{item.institutionNm }</td>
                  </tr> 
                  }



                </tbody>
              </Table>
              <div style={{margin:"20px auto", width:"100%"}}>
                <ButtonEx text={'닫기'} width={'85'}  
                onPress={_handleClose} bgcolor={'#FF7125'} color={'#fff'} />
              </div>
            </TableLayout>

        </PopupWorkEx>
      }

      {
        currentloading == true  && 
        <LottieAnimation containerStyle={LoadingLifeStyle} animationData={imageDB.loading}
        width={"100px"} height={'100px'} />
      }

      
      <div style={{position:"absolute", width:"100%"}}>
        <div id="map" className="Map" style={mapstyle}></div>
      </div>
      

    </Container>
    );

}

export default PCLifeTourRegion;

