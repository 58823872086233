import React, { Fragment, useContext, useEffect, useInsertionEffect, useState } from "react";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { Badge, setRef } from "@mui/material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Image from "../../../common/Image";
import { WORKNAME } from "../../../utility/work";
import { WORK } from "../../../utility/db";
import { colors } from "../../../theme/theme";
import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import Categorymenu from "../../../common/Categorymenu";
import { CENTERTYPE, CONFIGMOVE, EventItems, EVENTTYPE, GUIDETYPE, LAWTYPE, LoadingType, PCMAINMENU } from "../../../utility/screen";


import { ROOMSIZE, ROOMSIZEDISPALY } from "../../../utility/room";
import { GoPlus } from "react-icons/go";
import { DataContext } from "../../../context/Data";
import { model } from "../../../api/config";
import PcAipopup from "../../../modal/PcAiPopup/PcAiPopup";
import Loading from "../../../components/Loading";
import localforage from 'localforage';

import { useDispatch, useSelector } from "react-redux";
import { ALLWORK, HOMECLEAN,BUSINESSCLEAN,
  MOVECLEAN,FOODPREPARE,ERRAND,GOOUTSCHOOL,BABYCARE,LESSON,PATIENTCARE,CARRYLOAD,
  GOHOSPITAL,RECIPETRANSMIT,GOSCHOOLEVENT,SHOPPING,GODOGHOSPITAL,GODOGWALK,ALLROOM, SMALLROOM, MEDIUMROOM, LARGEROOM, TOURREGION, TOURCOUNTRY, TOURFESTIVAL, TOURCITY, TOURPICTURE, PERFORMANCEEVENT, PERFORMANCECINEMA, MEDICALMEDICINE, MEDICALHOSPITAL, FOODHISTORY, FOODINFOMATION, CONVENIENCECAMPING, COMMUNITY,
  ONESIZE, TWOSIZE, THREESIZE, FIVESIZE, FOURSIZE, SIXSIZE, SEVENSIZE, EIGHTSIZE, RESET} from "../../../store/menu/MenuSlice"
import { CONVENIENCEMENU, FAMILYMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU, WEATHERMENU } from "../../../utility/life";

import "./PCMainheader.css";
import { Column } from "../../../common/Column";
import { Delete_attendancebyusersid, readuserbydeviceid } from "../../../service/UserService";
import { getDateEx, getDateFullTime } from "../../../utility/date";

import moment from "moment";
import MailIcon from "@mui/icons-material/Mail";
import { ReadChat } from "../../../service/ChatService";
import { IoMdDownload } from "react-icons/io";
import PCRoompage from "../../../page/PCmain/PCRoompage";
import PCMainpage from "../../../page/PCmain/PCMainpage";

const PCHeader = styled.div`
  height: ${({height}) => height}px;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 1400px;

`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding:10px 0px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%);

`;


const OneHeaderMainMenu = styled.div`
  display: flex;
  padding-left: 24px;
  color : #ff7e19;
  font-size:27px;
  width:55%;
  align-items:center;
  justify-content: flex-start;
`;
const OneHeaderOptionMenu = styled.div`
  display: flex;
  flex-direction:row;
  font-size:14px;
  justify-content:space-around;
  width:30%;
`
const OneHeaderLoginMenu = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
  align-items: center;
`;

const EventDesc = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-left:20px;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
`

const MainMenuText = styled.div`
  font-size: 20px;
  color: ${({clickstatus}) => clickstatus == true ? ('#ff7e19') :('#131313') };

`

const EventMainText ={
  fontSize: '14px',
  color: '#131313',


}

const EventSubText ={
  fontSize: '14px',
  color: '#131313',

}

const EventBtn ={
  display:"flex",
  justifyContent:"space-between",
  width:'30%',

}


const CategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height:30px;
`;
const CategoryItem = styled.div`
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  display: inline-block;
  color: #595959;

`




const OneContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;
  background-color: #fff;
  position: fixed;
  z-index: 5;
  color: #131313;
  font-size:14px;
`;

const TwoContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  z-index: 5;
  margin-top: 34px;

  
`;




const LineControl = styled.div`

  width: 100%;
  position: absolute;
  margin-top: 45px;
`;

const Inputstyle ={
  border: '1px solid #C3C3C3',
  background: '#fff',
  width: '100%',
  borderRadius:'5px',
  fontSize:'16px',
  padding :'8px'

}



const Searchstyle={
  position: "relative",
  left: '-35px'
}
const Search2style={
  position: "relative",
  left: '0px',
  top: '5px'
}


const LoginBtn = styled.div`
  padding: 10px 30px;
  background: #ffd6ac;
  border-radius: 10px;
`;

const NavItemLayer = styled.div`

  position: absolute;
  left : ${({left}) => left};
`
const DropdownContent = styled.div`
display: block;
border: none;
height: 110px;
position: absolute;
background-color: #fff;
width: 100%;
z-index: 1;
text-align: left;
color: #636363;
font-size: 14px;
flex-direction: row;
font-weight: 700;
padding: 15px;
top: 120px;
left: 10px;
font-size: 14px;
line-height: 2;
border-bottom: 1px solid #ededed;

`
const MenuItem = styled.div`
  color :${({clickstatus}) => clickstatus == true ? ('#FE4C26') : ('fff')}

`



const WorkItems=[
  WORKNAME.ALLWORK,
  WORKNAME.HOMECLEAN,
  WORKNAME.BUSINESSCLEAN,
  WORKNAME.MOVECLEAN,
  WORKNAME.FOODPREPARE,
  WORKNAME.GOOUTSCHOOL,
  WORKNAME.BABYCARE,
  WORKNAME.LESSON,
  WORKNAME.ERRAND,
  WORKNAME.PATIENTCARE,
  WORKNAME.CARRYLOAD,
  WORKNAME.GOHOSPITAL,
  WORKNAME.RECIPETRANSMIT,
  WORKNAME.GOSCHOOLEVENT,
  WORKNAME.SHOPPING,
  WORKNAME.GODOGHOSPITAL,
  WORKNAME.GODOGWALK,

]

const RoomItems =[
  ROOMSIZE.ALLROOM,
  ROOMSIZE.ONESIZE,
  ROOMSIZE.TWOSIZE,
  ROOMSIZE.THREESIZE,
  ROOMSIZE.FOURSIZE,
  ROOMSIZE.FIVESIZE,
  ROOMSIZE.SIXSIZE,
  ROOMSIZE.SEVENSIZE,
  ROOMSIZE.EIGHTSIZE

]

const LifeItems =[
  LIFEMENU.BOARD,
  TOURISTMENU.TOURPICTURE,
  TOURISTMENU.TOURFESTIVAL,
  TOURISTMENU.TOURREGION,
  TOURISTMENU.TOURCOUNTRY,
  PERFORMANCEMENU.PERFORMANCEEVENT,
  PERFORMANCEMENU.PERFORMANCECINEMA,
  MEDICALMENU.MEDICALMEDICINE,
  MEDICALMENU.MEDICALHOSPITAL,
  MEDICALMENU.FOODINFOMATION,
  CONVENIENCEMENU.CONVENIENCECAMPING,



]
const TouristItems=[
  TOURISTMENU.TOURFESTIVAL,
  TOURISTMENU.TOURREGION,
  TOURISTMENU.TOURCOUNTRY,
  TOURISTMENU.TOURPICTURE,
  CONVENIENCEMENU.CONVENIENCECAMPING,
]
const PerformanceItems=[
  PERFORMANCEMENU.PERFORMANCEEVENT,
  PERFORMANCEMENU.PERFORMANCECINEMA,

]

const FamilyItems =[
  MEDICALMENU.FOODINFOMATION,
]

const ConvenienceItems= [
  CONVENIENCEMENU.CONVENIENCECAMPING,

]

const MedicalItems= [
  MEDICALMENU.MEDICALMEDICINE,
  MEDICALMENU.MEDICALHOSPITAL,

]

const Attendance = styled.div`

  font-family : Pretendard-SemiBold;
  background: #ff7e19;
  color: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  font-size:12px;
  width:80px;

`


const PCMainheader = ({name, registbtn,registmapbtn, height}) => {

  const reduxdispatch = useDispatch();
  const {value} = useSelector((state)=> state.menu);

  const navigation = useNavigate();
  const {user, dispatch } = useContext(UserContext);
  const {data, datadispatch } = useContext(DataContext);
  const [refresh, setRefresh] = useState(1);
  const [registbutton, setReigstbutton] = useState(false);
  const [mainmenustatus, setMainmenustatus] = useState(name);
  const [categorystatus, setCategorystatus] = useState(WORKNAME.ALLWORK);
  const [categorystatus2, setCategorystatus2] = useState(LIFEMENU.BOARD);
  const [search, setSearch] = useState('');
  const [popupstatus, setPopupstatus] = useState(false);

  const [lifemenu, setLifemenu] = useState(TOURISTMENU.TOURFESTIVAL);
  const [popupmenu, setPopupmenu] = useState(false);
  const [check, setCheck] = useState(0);
  const [eventdata, setEventdata] = useState('');

  const [unreadcount, setUnreadcount] = useState(0);


  useEffect(()=>{

    async function FetchData(){

      const USERS_ID = user.users_id;
      const items = await ReadChat({USERS_ID});
  
      let unreadcnt = 0;
      items.map((data)=>{
        unreadcnt += data.unReadcount;
      })
      setUnreadcount(unreadcnt);
      setRefresh((refresh) => refresh +1);
    }
    FetchData();
  })


  useEffect(() => {
    setMainmenustatus(mainmenustatus);
    setCategorystatus(categorystatus);
    setSearch(search);
    setPopupstatus(popupstatus);
    setLifemenu(lifemenu);
    setPopupmenu(popupmenu);
    setCheck(check);
    setEventdata(eventdata);

  }, [refresh]);

  const popupcallback = async () => {
    setPopupstatus(!popupstatus);

    setSearch("");
    setRefresh((refresh) => refresh +1);
  };


  const AiSearch = async(input) =>{
    setSearch(input);
    setRefresh((refresh) => refresh +1);
  }

  const _handleAI = async() =>{

    setPopupstatus(true);
    setRefresh((refresh) => refresh +1);

  }


  /**
   * 메인 메뉴의 클릭상태를 표시 한다
   * 메인 메뉴에서 선택된 메뉴 페이지로 이동한다
   */

  const _handleMenustatus=(status)=>{
  
    setMainmenustatus(status);
    if(status == PCMAINMENU.GATEMENU){
      reduxdispatch(ALLWORK());
      navigation("/PCGate");
    }
    else if(status == PCMAINMENU.HOMEMENU){
      reduxdispatch(ALLWORK());
      navigation("/PCmain");
    }else if(status == PCMAINMENU.ROOMMENU){
      reduxdispatch(ALLROOM());
      navigation("/PCroom");
    }else if(status == PCMAINMENU.REGIONMENU){
      navigation("/PCmap" ,{state :{WORK_ID :"", TYPE : ""}});
    }else if(status == PCMAINMENU.COMMUNITYMENU){
      navigation("/PCcommunity");
    }else if(status == PCMAINMENU.RANKINGMENU){
      navigation("/PChongguide");
    }else if(status == PCMAINMENU.EVENTMENU){
      navigation("/PCevent");
    }else if(status == PCMAINMENU.CHATMENU){
      navigation("/PCchat");
    }else if(status == PCMAINMENU.LIVEINFORMATIONMENU){
      navigation("/PClife");
    }else if(status == PCMAINMENU.REGISTERHONGMENU){
      navigation("/PChong");
    }

    setRefresh((refresh) => refresh +1);
  }

  const _handleLogout = async() =>{

    await localforage.removeItem('userconfig');
    navigation("/"); 
  }
  const _handleCommunity=() =>{
    navigation("/PCGate/PClife"); 
  }

  const _handleProfile =() =>{
    navigation("/PCconfig"); 
  }
  const _handleCenter = () =>{
    navigation("/PCcenter",{state :{CENTERTYPE :CENTERTYPE.NOTICE}});
  }

  const _handleAttendance = () =>{

    navigation("/PCGate/PCattendanceevent");
  }
  const _handleRullet = () =>{

    navigation("/PCGate/PCrulletevent");
  }
  const _handleHong = () =>{

    navigation("/PCGate/PCHong");
  }
  const _handleHongKnow =() =>{
    navigation("/PCGate/PCGuide" ,{state :{GUIDETYPE :GUIDETYPE.HONGKNOW}});
  }

  const _handlePolicy =() =>{
    navigation("/PCGate/PCPolicy" ,{state :{LAWTYPE :LAWTYPE.USELAW}});
  }

  const _handleroomprice =() =>{
    setCategorystatus("");
    setRefresh((refresh) => refresh +1);
    navigation("/PCroomprice");
  }




  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      _handleAI();
    }
  };

  const [isHovered, setIsHovered] = useState(false);





  // useEffect(()=>{

  //   async function FetchData(){

  //     const DEVICEID = user.deviceid;
  //     const userdata = await readuserbydeviceid({DEVICEID});



  //     const today = moment(); // 현재 날짜

  //     const pastDate = today.clone().subtract(1, 'days'); // 1일 전 날짜
    

  //    if(userdata.CHECKDATE.length > 0){
  //     // const diffInDays = moment(pastDate).diff(userdata.CHECKDATE[userdata.CHECKDATE.length -1], 'days');
  //     // 오늘 체크한경우
  //     if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(today)){
  //       setCheck(userdata.CHECKDATE.length);
  //     }else if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(pastDate)){
  //       setCheck(userdata.CHECKDATE.length);
  //     }else{

  //       // 업데이트 하자
  //       const USERS_ID = user.users_id;
  //       Delete_attendancebyusersid({USERS_ID}).then(()=>{
  //         setCheck(0);
  //       });
    
  //     } 
  
  //   }else{
  //     setCheck(0);
  //   }
  //   setRefresh((refresh) => refresh +1);
  //   }

  //   FetchData();
  // }, [])

  useEffect(()=>{

    async function FetchData(){
      console.log("TCL: FetchData -> user.deviceid", user)

      if(user.deviceid != '')
      {
        const DEVICEID = user.deviceid;
 
        const userdata = await readuserbydeviceid({DEVICEID});
  
        const today = moment(); // 현재 날짜
  
        const pastDate = today.clone().subtract(1, 'days'); // 1일 전 날짜
  
       if(userdata.CHECKDATE.length > 0){
        // const diffInDays = moment(pastDate).diff(userdata.CHECKDATE[userdata.CHECKDATE.length -1], 'days');
        // 오늘 체크한경우
        if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(today)){
          setCheck(userdata.CHECKDATE.length);
          setEventdata(userdata.CHECKDATE.length + "일째 도전중입니다. 15일이 완료 되면 미션이 완료 됩니다");
        }else if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(pastDate)){
          setCheck(userdata.CHECKDATE.length);
          setEventdata(userdata.CHECKDATE.length + "일째 도전중입니다. 15일이 완료 되면 미션이 완료 됩니다");
        }else{
  
          // 업데이트 하자
          const USERS_ID = user.users_id;
          Delete_attendancebyusersid({USERS_ID}).then(()=>{
            setCheck(0);
          });
        } 
    
       }else{
        setCheck(0);
       }
  
  
        setRefresh((refresh) => refresh +1);
  
      }


    }

    if(value =='WORKER'){
      reduxdispatch(RESET());
      navigation("/PCconfigcontent",{state :{NAME :CONFIGMOVE.WORKERINFO, TYPE : ""}});

    }else{
      FetchData();
    }


  }, [value])
  return (
    <>




    {popupstatus == true ? (
        <PcAipopup callback={popupcallback}
        search ={search}
        top={'0px'}  left={'18%'} height={'640px'} width={'1080px'} image={imageDB.sample11}></PcAipopup>
      ) : null}



    <PCHeader height={height}>
      <OneContainer id="eventheader">
        <BetweenRow width={'100%'} padding={'0px 24px'}>
          <FlexstartRow style={{width:'15%'}}>
            <div style={EventMainText}>구해줘 홍여사 앱 설치하기</div>
            <div style={{display:"flex", paddingLeft:10}}>
            <IoMdDownload width={22} height={22} color ={'#131313'}/>
            
            </div>     
          </FlexstartRow>
          {/* <FlexstartRow  style={{width:'50%',color:'#131313', fontSize:14, fontFamily:"Pretendard-Regular"}}>
            <div style={{color:'#131313', fontSize:14, marginRight:10, fontFamily:"Pretendard-Regular"}}>공지사항</div>
            <ReactTyped
                    strings={["홍여사 출석체크 하고 15000point 받아가세요", eventdata]}
                    typeSpeed={100}
                    loop
            />


          </FlexstartRow>

          <AroundRow style={{width:'35%'}}>
              <div style={EventSubText}  onClick={_handleHongKnow}>알아보기</div>
              <div style={EventSubText}  onClick={_handleHong}>홍여사찾기</div>
              <div style={EventSubText}  onClick={_handleCommunity}>커뮤니티</div>
              <div style={EventSubText}  onClick={_handleAttendance}>출석체크이벤트</div>
              <div style={EventSubText}  onClick={_handleRullet}>룰렛이벤트</div>
              <div style={EventSubText}  onClick={_handlePolicy}>이용 약관</div>
          </AroundRow> */}
        </BetweenRow>    
        <LineControl></LineControl>
      </OneContainer>

      <TwoContainer id="twoheader">
        <BetweenRow style={{height:60, background:"#fff"}} id="twosubheader">
          <OneHeaderMainMenu onClick={()=>{}}>
            <Row onClick={()=>{_handleMenustatus(PCMAINMENU.GATEMENU)}}>
         
                <img src={imageDB.logo} width={30} height={30} />
                <div style={{fontFamily:"Pretendard-Bold",display:"flex", justifyContent:"center", alignItems:"center"}}>구해줘 홍여사</div>
            

            </Row>
            <Row width={'300px'} style={{marginLeft:'25px', paddingTop:'paddingTop:5'}}>
        
              <input  style={Inputstyle} type="text" placeholder="홍여사 AI에 무엇이든 물어주세요"
                  value={search}
                  onChange={(e) => {   
                    AiSearch(e.target.value);
                  }}
                  onKeyDown={handleKeyDown} 
              />
              <div style={Searchstyle}>
              <img src={imageDB.searchgif} width={20} height={20} onClick={_handleAI} />
              </div>

            </Row>
   
          </OneHeaderMainMenu>
          <OneHeaderOptionMenu>




       
            <Row onClick={()=>{_handleMenustatus(PCMAINMENU.HOMEMENU)}}><MainMenuText clickstatus={PCMAINMENU.HOMEMENU == mainmenustatus}>{PCMAINMENU.HOMEMENU}</MainMenuText></Row> 
            <Row onClick={()=>{_handleMenustatus(PCMAINMENU.ROOMMENU)}}><MainMenuText clickstatus={PCMAINMENU.ROOMMENU == mainmenustatus}>{PCMAINMENU.ROOMMENU}</MainMenuText></Row> 
            <Row onClick={()=>{_handleMenustatus(PCMAINMENU.REGIONMENU)}}><MainMenuText clickstatus={PCMAINMENU.REGIONMENU == mainmenustatus}>{PCMAINMENU.REGIONMENU}</MainMenuText></Row> 
            {/* <Row onClick={()=>{_handleMenustatus(PCMAINMENU.REGISTERHONGMENU)}}><MainMenuText clickstatus={PCMAINMENU.REGISTERHONGMENU == mainmenustatus}>{PCMAINMENU.REGISTERHONGMENU}</MainMenuText>
            </Row>  */}
            <Row onClick={()=>{_handleMenustatus(PCMAINMENU.CHATMENU)}}><MainMenuText clickstatus={PCMAINMENU.CHATMENU == mainmenustatus}>{PCMAINMENU.CHATMENU}</MainMenuText>     
            <Badge badgeContent={unreadcount} color="warning" style={{paddingBottom:20}}></Badge> 
            </Row> 
            {/* <Row onClick={()=>{_handleMenustatus(PCMAINMENU.LIVEINFORMATIONMENU)}}><MainMenuText clickstatus={PCMAINMENU.LIVEINFORMATIONMENU == mainmenustatus}>{PCMAINMENU.LIVEINFORMATIONMENU}</MainMenuText></Row> 
        */}
          </OneHeaderOptionMenu>
          <OneHeaderLoginMenu id="infoheader">
         
            {
                user.userimg != '' ? ( <img onClick={_handleProfile}  src={user.userimg} width={45} height={45} 
                style={{borderRadius: "50px",width: "45px",background: "#a6a6a6"}}/>) :( <img  onClick={_handleProfile}  src={imageDB.person} width={45} height={45}
                  style={{borderRadius: "50px",width: "45px",background: "#a6a6a6"}} />)
            }

            <Column>
              <Attendance onClick={_handleAttendance}>출석{check}일 참여중</Attendance>
  
              <Row>
              <div onClick={_handleProfile}  style={{fontSize:14}}>
              {user.nickname}

              </div>
              {/* <img src={imageDB.logout} onClick={_handleLogout} style={{width:20, height:20}}/> */}
              </Row>
              
            </Column>
    
          </OneHeaderLoginMenu>
        </BetweenRow>



     
     
      </TwoContainer>


    </PCHeader>
    </>
  );
};

export default PCMainheader;
